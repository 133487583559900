import { useState } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import "../style.css"
import map from "../../images/map.png"
import Accordion from "react-bootstrap/Accordion";
import current from "../../images/current.svg";
import searchfilters from "../../images/search-filters.svg";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import amico from "../../images/amico.svg"

function Newreservation() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [shows, setShows] = useState(false);

    const handleCloses = () => setShows(false);
    const handleShows = () => setShows(true);
  return (
    <>
     <Breadcrumb>
      <Breadcrumb.Item className='breadcrumb'><Link to="/permis">Passer le permis</Link></Breadcrumb.Item>
      <Breadcrumb.Item >
      Nouvelle réservation
      </Breadcrumb.Item>
    </Breadcrumb>
    <div className="row">
          <div className="col-lg-6">
            <div className="filters">
              <div class="mb-3 inp-fil">
                <div className="search-icon">
                  <img src={current} />
                </div>
                <input
                  type="email"
                  class="form-control"
                  id="exampleFormControlInput1"
                  placeholder="name@example.com"
                />
                <div className="current-loc">
                  <img src={searchfilters} />
                </div>
              </div>
              <div className="filters-cions" onClick={handleShow}>
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="24"
                    cy="24"
                    r="24"
                    fill="url(#paint0_linear_1710_5178)"
                  />
                  <path
                    d="M26.0001 24V31.88C26.0401 32.18 25.9401 32.5 25.7101 32.71C25.6176 32.8027 25.5077 32.8762 25.3868 32.9264C25.2658 32.9766 25.1361 33.0024 25.0051 33.0024C24.8742 33.0024 24.7445 32.9766 24.6235 32.9264C24.5025 32.8762 24.3926 32.8027 24.3001 32.71L22.2901 30.7C22.1811 30.5933 22.0982 30.4629 22.0479 30.319C21.9976 30.175 21.9813 30.0213 22.0001 29.87V24H21.9701L16.2101 16.62C16.0477 16.4115 15.9745 16.1473 16.0063 15.8849C16.0382 15.6226 16.1726 15.3835 16.3801 15.22C16.5701 15.08 16.7801 15 17.0001 15H31.0001C31.2201 15 31.4301 15.08 31.6201 15.22C31.8277 15.3835 31.9621 15.6226 31.9939 15.8849C32.0258 16.1473 31.9525 16.4115 31.7901 16.62L26.0301 24H26.0001Z"
                    fill="white"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1710_5178"
                      x1="2.45669"
                      y1="14.2041"
                      x2="46.2793"
                      y2="18.0883"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#C43CAB" />
                      <stop offset="1" stop-color="#F87E4B" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
            <div className="stations">
              <Accordion>
              <Accordion.Item eventKey="0">
                  <Accordion.Header>Edmond Rostand High School</Accordion.Header>
                  <Accordion.Body>
                    <div className="distance">
                      <p>95630, Mériel</p>
                      <strong>4899 m</strong>
                    </div>
                    <div className="calc">
                      <div className="prev">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="10" cy="10" r="10" fill="#D9D9D9" />
                          <path
                            d="M12.0837 5.83325L7.91699 9.99992L12.0837 14.1666"
                            stroke="black"
                          />
                        </svg>
                      </div>
                      <div className="week">
                        <div>
                        <span>Fri</span>
                        <strong>Aug 2</strong>
                        </div>
                        <div>
                        <span>Sat</span>
                        <strong>Aug 3</strong>
                        </div>
                        <div>
                        <span>Sun</span>
                        <strong>Aug 4</strong>
                        </div>
                        <div>
                        <span>Mon</span>
                        <strong>Aug 5</strong>
                        </div>
                        <div>
                        <span>Tue</span>
                        <strong>Aug 6</strong>
                        </div>
                        <div>
                        <span>Wed</span>
                        <strong>Aug 7</strong>
                        </div>
                        <div>
                        <span>Thu</span>
                        <strong>Aug 8</strong>
                        </div>
                      </div>
                      <div className="next">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="10"
                            cy="10"
                            r="10"
                            transform="matrix(-1 0 0 1 20 0)"
                            fill="#D9D9D9"
                          />
                          <path
                            d="M7.91634 5.83325L12.083 9.99992L7.91634 14.1666"
                            stroke="black"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="slots">
                      <div className="s1 select">
                        <span>11:30</span>
                      </div>
                      <div className="s1">
                        <span>12:30</span>
                      </div>
                      <div className="s1">
                        <span>13:30</span>
                      </div>
                      <div className="s1">
                        <span>14:30</span>
                      </div>
                      <div className="s1 select">
                        <span>15:30</span>
                      </div>
                      <div className="s1">
                        <span>16:30</span>
                      </div>
                      <div className="s1">
                        <span>17:30</span>
                      </div>
                      <div className="s1">
                        <span>18:30</span>
                      </div>
                      <div className="s1 select">
                        <span>19:30</span>
                      </div>
                      <div className="s1">
                        <span>20:30</span>
                      </div>
                      <div className="s1">
                        <span>21:30</span>
                      </div>
                      <div className="s1">
                        <span>22:30</span>
                      </div>
                    </div>
                   
                    <div className="res-slots">
                    <button type="button" class="btn btn-dark" onClick={handleShows}>Reserve Slot</button>
                    </div>
                    <Modal show={shows} onHide={handleCloses} className="reservartionmodal">
        <Modal.Header closeButton>
          <Modal.Title>Your reservation</Modal.Title>
        </Modal.Header>
        <Modal.Body className='confirm-body'>
          <img src={amico} />
          <h3>Congratulations!! 
        </h3>
       <p className='mb-0'>your reservation has been booked</p>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-center py-3'>
         
          <Button variant="primary" onClick={handleCloses}>
            Back to home
          </Button>
        </Modal.Footer>
      </Modal>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Edmond Rostand High School</Accordion.Header>
                  <Accordion.Body>
                    <div className="distance">
                      <p>95630, Mériel</p>
                      <strong>4899 m</strong>
                    </div>
                    <div className="calc">
                      <div className="prev">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="10" cy="10" r="10" fill="#D9D9D9" />
                          <path
                            d="M12.0837 5.83325L7.91699 9.99992L12.0837 14.1666"
                            stroke="black"
                          />
                        </svg>
                      </div>
                      <div className="week">
                        <div>
                        <span>Fri</span>
                        <strong>Aug 2</strong>
                        </div>
                        <div>
                        <span>Sat</span>
                        <strong>Aug 3</strong>
                        </div>
                        <div>
                        <span>Sun</span>
                        <strong>Aug 4</strong>
                        </div>
                        <div>
                        <span>Mon</span>
                        <strong>Aug 5</strong>
                        </div>
                        <div>
                        <span>Tue</span>
                        <strong>Aug 6</strong>
                        </div>
                        <div>
                        <span>Wed</span>
                        <strong>Aug 7</strong>
                        </div>
                        <div>
                        <span>Thu</span>
                        <strong>Aug 8</strong>
                        </div>
                      </div>
                      <div className="next">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="10"
                            cy="10"
                            r="10"
                            transform="matrix(-1 0 0 1 20 0)"
                            fill="#D9D9D9"
                          />
                          <path
                            d="M7.91634 5.83325L12.083 9.99992L7.91634 14.1666"
                            stroke="black"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="slots">
                      <div className="s1 select">
                        <span>11:30</span>
                      </div>
                      <div className="s1">
                        <span>12:30</span>
                      </div>
                      <div className="s1">
                        <span>13:30</span>
                      </div>
                      <div className="s1">
                        <span>14:30</span>
                      </div>
                      <div className="s1 select">
                        <span>15:30</span>
                      </div>
                      <div className="s1">
                        <span>16:30</span>
                      </div>
                      <div className="s1">
                        <span>17:30</span>
                      </div>
                      <div className="s1">
                        <span>18:30</span>
                      </div>
                      <div className="s1 select">
                        <span>19:30</span>
                      </div>
                      <div className="s1">
                        <span>20:30</span>
                      </div>
                      <div className="s1">
                        <span>21:30</span>
                      </div>
                      <div className="s1">
                        <span>22:30</span>
                      </div>
                    </div>
                   
                    <div className="res-slots">
                    <button type="button" class="btn btn-dark">Reserve Slot</button>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Edmond Rostand High School</Accordion.Header>
                  <Accordion.Body>
                    <div className="distance">
                      <p>95630, Mériel</p>
                      <strong>4899 m</strong>
                    </div>
                    <div className="calc">
                      <div className="prev">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="10" cy="10" r="10" fill="#D9D9D9" />
                          <path
                            d="M12.0837 5.83325L7.91699 9.99992L12.0837 14.1666"
                            stroke="black"
                          />
                        </svg>
                      </div>
                      <div className="week">
                        <div>
                        <span>Fri</span>
                        <strong>Aug 2</strong>
                        </div>
                        <div>
                        <span>Sat</span>
                        <strong>Aug 3</strong>
                        </div>
                        <div>
                        <span>Sun</span>
                        <strong>Aug 4</strong>
                        </div>
                        <div>
                        <span>Mon</span>
                        <strong>Aug 5</strong>
                        </div>
                        <div>
                        <span>Tue</span>
                        <strong>Aug 6</strong>
                        </div>
                        <div>
                        <span>Wed</span>
                        <strong>Aug 7</strong>
                        </div>
                        <div>
                        <span>Thu</span>
                        <strong>Aug 8</strong>
                        </div>
                      </div>
                      <div className="next">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="10"
                            cy="10"
                            r="10"
                            transform="matrix(-1 0 0 1 20 0)"
                            fill="#D9D9D9"
                          />
                          <path
                            d="M7.91634 5.83325L12.083 9.99992L7.91634 14.1666"
                            stroke="black"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="slots">
                      <div className="s1 select">
                        <span>11:30</span>
                      </div>
                      <div className="s1">
                        <span>12:30</span>
                      </div>
                      <div className="s1">
                        <span>13:30</span>
                      </div>
                      <div className="s1">
                        <span>14:30</span>
                      </div>
                      <div className="s1 select">
                        <span>15:30</span>
                      </div>
                      <div className="s1">
                        <span>16:30</span>
                      </div>
                      <div className="s1">
                        <span>17:30</span>
                      </div>
                      <div className="s1">
                        <span>18:30</span>
                      </div>
                      <div className="s1 select">
                        <span>19:30</span>
                      </div>
                      <div className="s1">
                        <span>20:30</span>
                      </div>
                      <div className="s1">
                        <span>21:30</span>
                      </div>
                      <div className="s1">
                        <span>22:30</span>
                      </div>
                    </div>
                   
                    <div className="res-slots">
                    <button type="button" class="btn btn-dark">Reserve Slot</button>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="col-lg-6">
          <div className="my-4">
          <img src={map} />
          </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Filtres</Modal.Title>
        </Modal.Header>
        <Modal.Body className='select-filters'>
          <div className='options1'>
            <label>Évaluation des enseignants</label>
            <div className='d-flex mb-3'>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
              <label class="form-check-label" for="flexRadioDefault1">
              3 <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.49984 11.672L10.7853 13.6591C11.3869 14.0232 12.1232 13.4849 11.9648 12.8041L11.094 9.0674L13.9994 6.5499C14.5298 6.09073 14.2448 5.2199 13.5482 5.16448L9.72443 4.8399L8.22818 1.30906C7.95901 0.667812 7.04068 0.667812 6.77151 1.30906L5.27526 4.83198L1.45151 5.15656C0.754844 5.21198 0.469844 6.08281 1.00026 6.54198L3.90568 9.05948L3.03484 12.7961C2.87651 13.477 3.61276 14.0153 4.21443 13.6511L7.49984 11.672Z" fill="#FFA800"/>
            </svg>
              </label>
                      </div>
                      <div class="form-check ms-2">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"  />
                        <label class="form-check-label" for="flexRadioDefault2">
                        4 <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.49984 11.672L10.7853 13.6591C11.3869 14.0232 12.1232 13.4849 11.9648 12.8041L11.094 9.0674L13.9994 6.5499C14.5298 6.09073 14.2448 5.2199 13.5482 5.16448L9.72443 4.8399L8.22818 1.30906C7.95901 0.667812 7.04068 0.667812 6.77151 1.30906L5.27526 4.83198L1.45151 5.15656C0.754844 5.21198 0.469844 6.08281 1.00026 6.54198L3.90568 9.05948L3.03484 12.7961C2.87651 13.477 3.61276 14.0153 4.21443 13.6511L7.49984 11.672Z" fill="#FFA800"/>
            </svg>
                        </label>
                      </div>
                      <div class="form-check mx-2">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
              <label class="form-check-label" for="flexRadioDefault1">
                5 <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.49984 11.672L10.7853 13.6591C11.3869 14.0232 12.1232 13.4849 11.9648 12.8041L11.094 9.0674L13.9994 6.5499C14.5298 6.09073 14.2448 5.2199 13.5482 5.16448L9.72443 4.8399L8.22818 1.30906C7.95901 0.667812 7.04068 0.667812 6.77151 1.30906L5.27526 4.83198L1.45151 5.15656C0.754844 5.21198 0.469844 6.08281 1.00026 6.54198L3.90568 9.05948L3.03484 12.7961C2.87651 13.477 3.61276 14.0153 4.21443 13.6511L7.49984 11.672Z" fill="#FFA800"/>
            </svg>
              </label>
          </div>
            </div>
         
          </div>
          <label>Distance de votre emplacement</label>
          <Form.Select aria-label="Default select example">
      <option>Sélectionnez la distance</option>
      <option value="1">One</option>
      <option value="2">Two</option>
      <option value="3">Three</option>
    </Form.Select>
    <label>Disponibilité des plages horaires</label>
          <Form.Select aria-label="Default select example">
      <option>Time slots</option>
      <option value="1">One</option>
      <option value="2">Two</option>
      <option value="3">Three</option>
    </Form.Select>
    <label>Langues parlées par le moniteur</label>
          <Form.Select aria-label="Default select example">
      <option>Select Language</option>
      <option value="1">One</option>
      <option value="2">Two</option>
      <option value="3">Three</option>
    </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Filters
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Newreservation