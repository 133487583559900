import React, { useEffect, useState } from "react";
import "../style.css";
import { Link, useNavigate } from "react-router-dom";
import lesbg from "../../images/girl_drive.png";
import ps from "../../images/ps.png";
import cheq from "../../images/cheq.png";
import veriin from "../../images/veriin.png";
import axiosInstance from "../../utils/http/config/axios";
import { toast } from "react-toastify";
import CourseCard from "./partials/CourseCard";
import Loading from "../../components/Loading";

function Livret() {
  const [coursesProgress, setCoursesProgress] = useState([]);
  const [skillsWithProgress, setSkillsWithProgress] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasSubscription, setHasSubscription] = useState(false);
  const navigate = useNavigate();

  const getCourses = async (studentId) => {
    setLoading(true);
    setError("");

    try {
      const response = await axiosInstance.post(
        "/api/student/skills/get-student-progress",
        { studentId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setCoursesProgress(response.data.coursesProgress);
    } catch (error) {
      setError(
        error.response?.data?.error ||
          "Une erreur inattendue s'est produite. Veuillez réessayer."
      );
      toast.error(
        error.response?.data?.error || "Erreur inattendue. Veuillez réessayer.",
        {
          position: toast.TOP_RIGHT,
          autoClose: 3000,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  const getSkillsWithProgress = async (studentId) => {
    setLoading(true);
    setError("");

    try {
      const response = await axiosInstance.post(
        "/api/student/skills/get-skills-with-progress",
        { studentId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setSkillsWithProgress(response.data.skills);
    } catch (error) {
      setError(
        error.response?.data?.error ||
          "Une erreur inattendue s'est produite. Veuillez réessayer."
      );
      toast.error(
        error.response?.data?.error || "Erreur inattendue. Veuillez réessayer.",
        {
          position: toast.TOP_RIGHT,
          autoClose: 3000,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token found");
        }

        const response = await axiosInstance.get("/api/students/profile", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const { student } = response.data;
        const { hasSubscription } = student;
        setHasSubscription(hasSubscription);

        if (hasSubscription) {
          getCourses(student.id);
          getSkillsWithProgress(student.id);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError("Session expirée. Veuillez vous reconnecter.");
          localStorage.removeItem("token");
          navigate("/");
        } else {
          setError(
            "Une erreur s'est produite lors de la récupération des données."
          );
        }
      }
    };

    fetchUserData();
  }, []);

  if (!hasSubscription) {
    return (
      <section className="lesson h-100">
        <div className="row">
          <div className="col-lg-7 d-flex flex-column align-items-center justify-content-center">
            <div className="wraping dont-lseon">
              <h2>
                Pour continuer vos cours de conduite, veuillez acheter des
                heures supplémentaires.
              </h2>
              <button
                type="button"
                className="btn btn-secondary btn-main"
                onClick={() => navigate("/boutique")}
              >
                Commencez à apprendre aujourd'hui
              </button>
            </div>
          </div>
          <div className="col-lg-5">
            <img src={lesbg} alt="Leçons" />
          </div>
        </div>
      </section>
    );
  }

  return (
    <>
      {/* for unsubscribed user */}
      {/* <section className='livret'>
       <div className='container'>
         <div className='wrapping'>
            <img src={nolivret} />
            <p>Vous devez acheter un plan pour accéder au livret</p>
            <Link to="/boutique" ><button type="button" class="btn btn-secondary btn-main">Acheter maintenant</button></Link>
         </div>
       </div>
   </section> */}
      <section className="booklet">
        <div className="">
          <h4>Préparez-vous aux questions de l'examen</h4>
          <div className="row mt-4 frow-b">
            <div className="col-lg-4 rblock">
              <Link to="/premierssecours">
                <div className="premier d-flex align-items-center">
                  <img src={ps} />
                  <div className="content-top">
                    <h5>Premiers Secours</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 rblock">
              <Link to="/check-external">
                <div className="premier d-flex align-items-center">
                  <img src={cheq} />
                  <div className="content-top">
                    <h5>Vérifications externes</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 rblock">
              <Link to="/check-internal">
                <div className="premier d-flex align-items-center">
                  <img src={veriin} />
                  <div className="content-top">
                    <h5>Vérifications internes</h5>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="row">
            {loading ? (
              <div className="col-12 text-center">
                <Loading/>
              </div>
            ) : skillsWithProgress?.length > 0 ? (
              skillsWithProgress?.map((skill, index) => (
                <CourseCard key={skill.id} skill={skill} index={index} />
              ))
            ) : (
              <div className="col-12">
                {" "}
                {/* Fallback message when no courses are available */}
                <p>Aucun cours disponible.</p>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Livret;
