import { Accordion, Breadcrumb } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import visa from "../images/visa-ico.png";
import card_american_express from "../images/card_american_express.png";
import master_card from "../images/master_card.png";
import checktick from "../images/check-tick.svg";
import geartick from "../images/hugeicons_steering.svg";
import detailtick from "../images/tabler_manual-gearbox.svg";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import P_Spinner from "../components/P_Spinner";
import { useState } from "react";
import moment from "moment-timezone";
import "moment/locale/fr";
import axiosInstance from "../utils/http/config/axios";
moment.locale("fr");

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function Addtocart() {
  const location = useLocation();
  const { state } = location;
  const planDetails = state?.planDetails || {};
  const isMultiplePayment = planDetails.is_multiple_payment;

  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const handleAlert = (message, type) => {
    setAlert({ show: true, message, type });
    setTimeout(() => setAlert({ show: false, message: "", type: "" }), 5000);
  };

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const [paymentTime, setPaymentTime] = useState(1);
  const [numberOfPeriods, setNumberOfPeriods] = useState(1);
  const [paymentTimeData, setPaymentTimeData] = useState({
    period_1: {
      amount: planDetails.price,
      hours: planDetails.hoursIncluded,
      date: moment().tz("Europe/Paris").format("D MMMM YYYY")
    }
  });

  const calculatePaymentData = (time) => {
    if (!planDetails || typeof planDetails.hoursIncluded !== "number") {
      return;
    }

    const weGotHours = planDetails.hoursIncluded;

    if (!weGotHours) {
      return;
    }

    // For single payment, don't divide the price
    if (time === 1) {
      const amount = discountInfo.isSponsorCodeValid ? discountedPrice : planDetails.price;
      setPaymentTimeData({
        period_1: {
          amount,
          hours: weGotHours,
          date: moment().tz("Europe/Paris").format("D MMMM YYYY")
        }
      });
      setLoading(false);
      return;
    }

    // For multiple payments, calculate periods
    const totalPrice = discountInfo.isSponsorCodeValid ? discountedPrice : planDetails.price;
    const hoursPerPeriod = Math.floor(weGotHours / time);
    const remainderHours = weGotHours % time;

    const pricePerPeriod = Math.floor(totalPrice / time);
    const remainderPrice = totalPrice % time;

    const rounded = (value) => Math.round(value);
    const periods = [];

    for (let i = 0; i < time; i++) {
      let hours = hoursPerPeriod;
      let amount = pricePerPeriod;

      if (i === time - 1) {
        hours += remainderHours;
        amount += remainderPrice;
      }

      const nextBillingDate = moment()
        .tz("Europe/Paris")
        .add(i, "months")
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ssZ");

      const formattedDate = moment(nextBillingDate).format("D MMMM YYYY");

      periods.push({
        periodNumber: i + 1,
        hours,
        amount,
        nextBillingDate,
        formattedDate,
      });
    }

    setPaymentTimeData({
      ...periods.reduce((acc, period) => {
        acc[`period_${period.periodNumber}`] = {
          amount: rounded(period.amount),
          hours: rounded(period.hours),
          date: period.formattedDate,
        };
        return acc;
      }, {}),
    });

    setLoading(false);
  };

  const handlePaymentTimes = (time) => {
    setLoading(true);
    setPaymentTime(time);
    setNumberOfPeriods(time);
    calculatePaymentData(time);
  };

  const [loading, setLoading] = useState(true);

  const [processToPayment, setProcessToPayment] = useState(true);
  const [backToPayment, setbackToPayment] = useState(false);

  const [promoCode, setPromoCode] = useState(null);
  const [promoInput, setPromoInput] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [promoError, setPromoError] = useState("");
  const [discountInfo, setDiscountInfo] = useState({
    discountPercentage: 0,
    isSponsorCodeValid: false,
    isSponsorCodeAlreadyUsed: false,
    isSponsorCodeOwn: false,
  });

  const handlePromoInputChange = (e) => {
    setPromoInput(e.target.value.toUpperCase());
    // Reset states when input changes
    setPromoError("");
    setDiscountedPrice(null);
    setDiscountInfo({
      discountPercentage: 0,
      isSponsorCodeValid: false,
      isSponsorCodeAlreadyUsed: false,
      isSponsorCodeOwn: false,
    });
  };

  const handleResetPromo = () => {
    setPromoInput("");
    setPromoCode("");
    setDiscountedPrice(null);
    setPromoError("");
    setDiscountInfo({
      discountPercentage: 0,
      isSponsorCodeValid: false,
      isSponsorCodeAlreadyUsed: false,
      isSponsorCodeOwn: false,
    });
    // Recalculate periods with original price
    if (paymentTime > 1) {
      calculatePaymentData(paymentTime);
    }
  };

  const handlePromoValidation = async () => {
    const code = promoInput;
    setPromoCode(code);

    if (!code || code.trim() === "") {
      setDiscountedPrice(null);
      setPromoError("");
      setDiscountInfo({
        discountPercentage: 0,
        isSponsorCodeValid: false,
        isSponsorCodeAlreadyUsed: false,
        isSponsorCodeOwn: false
      });
      // Reset to original price
      setPaymentTimeData({
        period_1: {
          amount: planDetails.price,
          hours: planDetails.hoursIncluded,
          date: moment().tz("Europe/Paris").format("D MMMM YYYY")
        }
      });
      return;
    }

    try {
      const response = await axiosInstance.post('/api/students/discounted', {
        subscriptionTypeId: planDetails.id,
        sponsorCode: code
      });

      const { totalPrice, discountData } = response.data;
      setDiscountInfo(discountData);

      if (discountData.isSponsorCodeValid) {
        setDiscountedPrice(totalPrice);
        setPromoError("");
        // Update payment data with discounted price
        if (paymentTime === 1) {
          setPaymentTimeData({
            period_1: {
              amount: totalPrice,
              hours: planDetails.hoursIncluded,
              date: moment().tz("Europe/Paris").format("D MMMM YYYY")
            }
          });
        } else {
          calculatePaymentData(paymentTime);
        }
      } else {
        setDiscountedPrice(null);
        setPromoError("Code parrain invalide");
        // Reset to original price
        setPaymentTimeData({
          period_1: {
            amount: planDetails.price,
            hours: planDetails.hoursIncluded,
            date: moment().tz("Europe/Paris").format("D MMMM YYYY")
          }
        });
      }
    } catch (error) {
      setDiscountedPrice(null);
      let errorMessage = error.response?.data?.error || "Une erreur s'est produite lors de la validation du code";
      setPromoError(errorMessage);
      setDiscountInfo({
        discountPercentage: 0,
        isSponsorCodeValid: false,
        isSponsorCodeAlreadyUsed: false,
        isSponsorCodeOwn: false
      });
      // Reset to original price
      setPaymentTimeData({
        period_1: {
          amount: planDetails.price,
          hours: planDetails.hoursIncluded,
          date: moment().tz("Europe/Paris").format("D MMMM YYYY")
        }
      });
    }
  };

  const renderDiscountInfo = () => {
    if (promoInput && !promoError && discountInfo.isSponsorCodeValid) {
      return (
        <div
          className="discount-info"
          style={{ marginTop: "10px", color: "#28a745" }}
        >
          <p>Réduction appliquée : {discountInfo.discountPercentage}%</p>
        </div>
      );
    }
    return null;
  };

  const renderPromoError = () => {
    if (promoError) {
      return (
        <div
          className="promo-error"
          style={{ color: "#dc3545", marginTop: "5px" }}
        >
          {promoError}
        </div>
      );
    }
    return null;
  };

  const handleSubmit = async () => {
    setProcessToPayment(true);
    setbackToPayment(true);
  };

  return (
    <section className="addcart">
      <Breadcrumb>
        <Breadcrumb.Item className="breadcrumb">
          <Link to="/boutique">Boutique</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Ajouter au panier</Breadcrumb.Item>
      </Breadcrumb>
      <div className="container-fluid">
        <div className="row">
          <div className="back-btn mb-3">
            <button
              type="button"
              onClick={goBack}
              class="btn btn-secondary btn-main"
            >
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.8822 9.06814C16.8852 9.29314 16.7987 9.51012 16.6418 9.67135C16.4848 9.83258 16.2702 9.92484 16.0452 9.92786L4.5188 10.0821L7.6367 13.1177C7.78871 13.2765 7.87314 13.4881 7.8722 13.708C7.87127 13.9278 7.78504 14.1387 7.63169 14.2962C7.47833 14.4537 7.26983 14.5455 7.0501 14.5524C6.83036 14.5592 6.61656 14.4805 6.45374 14.3328L1.86858 9.86872C1.70758 9.71178 1.61545 9.49735 1.61244 9.27253C1.60944 9.04772 1.69579 8.8309 1.85253 8.66971L6.31656 4.08455C6.47537 3.93254 6.68699 3.84811 6.90682 3.84904C7.12666 3.84998 7.33755 3.93621 7.49507 4.08956C7.65258 4.24292 7.74442 4.45142 7.75124 4.67116C7.75806 4.89089 7.67933 5.10469 7.53163 5.26751L4.49608 8.38542L16.0225 8.23113C16.2475 8.22812 16.4645 8.31461 16.6257 8.47158C16.7869 8.62855 16.8792 8.84314 16.8822 9.06814Z"
                  fill="white"
                />
              </svg>
              Retour
            </button>
          </div>
          <div className="col-lg-8">
            <div className="cart-item">
              <div className="d-flex justify-content-between align-items-start mb-4 produt-dest gap-3">
                <div>
                  <h3>{planDetails.name || "Nom du produit"}</h3>
                  <span>
                    {planDetails.description ||
                      "Description du plan sélectionné"}
                  </span>
                </div>
                <strong>
                  {planDetails.price ? `€ ${planDetails.price} ` : "Prix"}
                </strong>
              </div>
              {/* Display Descriptions */}
              <div className="descriptions">
                <h4>Descriptions :</h4>
                <ul>
                  {planDetails.descriptions?.map((desc, index) => (
                    <li key={index} className="d-flex gap-2">
                      {" "}
                      <span>
                        <img src={checktick} alt="tick" />
                      </span>
                      {desc}
                    </li>
                  ))}
                </ul>
              </div>
              {planDetails.is_multiple_payment && (
                <div
                  className="d-flex gap-2 align-items-center"
                  style={{
                    backgroundColor: "#D091FF",
                    padding: "8px 2vw",
                    borderRadius: "2em",
                    color: "white",
                    marginTop: "-10px",
                  }}
                >
                  <span>€</span>
                  <span className="fs-6">paiement en plusieurs fois</span>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="cart-total">
              <h4>Récapitulatif de la commande</h4>
              <div className="sub-total">
                <div className="d-flex justify-content-between">
                  <span>Sous-total</span>
                  <strong>
                    {planDetails.price ? `€ ${planDetails.price}` : "Prix"}
                  </strong>
                </div>
                <div className="d-flex justify-content-between">
                  <span>Impôt</span>
                  <strong>€ 0.00</strong>
                </div>
                <hr />
                <div className="d-flex justify-content-between">
                  <span>Total</span>
                  <strong>
                    {planDetails.price ? `€ ${planDetails.price} ` : "Prix"}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row cards">
          {processToPayment ? (
            <>
              {backToPayment && (
                <div className="back-btn mb-2">
                  <button
                    type="button"
                    onClick={() => setProcessToPayment(false)}
                    class="btn btn-secondary btn-main"
                  >
                    <svg
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16.8822 9.06814C16.8852 9.29314 16.7987 9.51012 16.6418 9.67135C16.4848 9.83258 16.2702 9.92484 16.0452 9.92786L4.5188 10.0821L7.6367 13.1177C7.78871 13.2765 7.87314 13.4881 7.8722 13.708C7.87127 13.9278 7.78504 14.1387 7.63169 14.2962C7.47833 14.4537 7.26983 14.5455 7.0501 14.5524C6.83036 14.5592 6.61656 14.4805 6.45374 14.3328L1.86858 9.86872C1.70758 9.71178 1.61545 9.49735 1.61244 9.27253C1.60944 9.04772 1.69579 8.8309 1.85253 8.66971L6.31656 4.08455C6.47537 3.93254 6.68699 3.84811 6.90682 3.84904C7.12666 3.84998 7.33755 3.93621 7.49507 4.08956C7.65258 4.24292 7.74442 4.45142 7.75124 4.67116C7.75806 4.89089 7.67933 5.10469 7.53163 5.26751L4.49608 8.38542L16.0225 8.23113C16.2475 8.22812 16.4645 8.31461 16.6257 8.47158C16.7869 8.62855 16.8792 8.84314 16.8822 9.06814Z"
                        fill="white"
                      />
                    </svg>
                    Retour
                  </button>
                </div>
              )}
              {!backToPayment && isMultiplePayment && (
                <div className="back-btn mb-2">
                  <button
                    type="button"
                    onClick={() => {
                      setProcessToPayment(false);
                      calculatePaymentData(paymentTime);
                      handlePaymentTimes(2);
                    }}
                    className="btn btn-secondary btn-main"
                  >
                    Payer en plusieurs fois
                  </button>
                </div>
              )}
              <div className="card-title">
                <h4>Paiement 100% sécurisé</h4>
                <div className="payment-secure">
                  <img src={visa} alt="Visa" />
                  <img src={card_american_express} alt="American Express" />
                  <img src={master_card} alt="MasterCard" />
                </div>
              </div>
              <div className="form">
                {alert.show && (
                  <div className={`alert alert-${alert.type}`} role="alert">
                    {alert.message}
                  </div>
                )}
                <Accordion
                  defaultActiveKey="0"
                  className="code-promo-accordion "
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Vous avez un code promotionnel ?
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="code-promo">
                        <input
                          name="promoCode"
                          placeholder="Code Promo"
                          onChange={handlePromoInputChange}
                          value={promoInput}
                        />
                        {renderPromoError()}
                        {renderDiscountInfo()}
                        <div className="promo-buttons">
                          <button
                            type="button"
                            className="btn btn-main btn-secondary"
                            onClick={handlePromoValidation}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="17px"
                              width="17px"
                              viewBox="0 0 448 512"
                            >
                              <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            <span className="ms-2">Valider</span>
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-danger"
                            onClick={handleResetPromo}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="17px"
                              width="17px"
                              viewBox="0 0 448 512"
                            >
                              <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                            </svg>
                            <span className="ms-2">Supprimer</span>
                          </button>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Elements stripe={stripePromise}>
                  <CheckoutForm
                    paymentAmount={
                      paymentTime === 1 
                        ? (discountInfo.isSponsorCodeValid ? discountedPrice : planDetails.price)
                        : paymentTimeData?.period_1?.amount || planDetails.price
                    }
                    sponsorCode={promoCode}
                    planDetails={planDetails}
                    numberOfPeriods={numberOfPeriods}
                    onSuccess={() => handleAlert("Paiement réussi!", "success")}
                    onError={() =>
                      handleAlert(
                        "Le paiement a échoué. Veuillez réessayer.",
                        "danger"
                      )
                    }
                  />
                </Elements>
              </div>
            </>
          ) : loading ? (
            <div className="d-flex align-items-center gap-2">
              <P_Spinner />
              <span>Veuillez patientez un moment...</span>
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-between gap-3 mb-3 flex-wrap ">
                <div className="many-time-paid d-flex gap-3">
                  <span
                    onClick={() => handlePaymentTimes(2)}
                    className={`${paymentTime === 2 && "active"}`}
                  >
                    {" "}
                    2 fois
                  </span>
                  <span
                    onClick={() => handlePaymentTimes(3)}
                    className={`${paymentTime === 3 && "active"}`}
                  >
                    {" "}
                    3 fois
                  </span>
                </div>
                <div className="back-btn">
                  <button
                    type="button"
                    onClick={() => {
                      setProcessToPayment(true);
                      setbackToPayment(false);
                      setPaymentTimeData(null);
                      handlePaymentTimes(1);
                    }}
                    class="btn btn-secondary btn-main"
                  >
                    Payer en une seule fois
                  </button>
                </div>
              </div>
              <div className="d-flex gap-2 align-items-center mb-3 warning">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  height={"24px"}
                  width={"24px"}
                >
                  <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336l24 0 0-64-24 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 88 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                </svg>
                <span>
                  Les heures de conduite seront créditées selon l’échéancier de
                  paiement selectionné
                </span>
              </div>
              <ManyTimePayment time={paymentTime} data={paymentTimeData} />

              <div className="d-flex justify-content-end">
                <button
                  onClick={handleSubmit}
                  className="btn-main"
                  style={{ color: "#fff" }}
                >
                  Proceder au paiement
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default Addtocart;

function ManyTimePayment({ time, data }) {
  if (!data) {
    return <div>Aucune donnée de paiement disponible</div>;
  }

  if (time === 2) {
    return (
      <div className="mb-3 manyTimePayment facture-suivi">
        <div className="today-payment mb-3">
          <div className="d-flex align-items-center gap-2 justify-content-between fs-4 mb-2">
            <div className="d-flex align-items-center gap-2">
              <div className="check-today">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="22px"
                  width="22px"
                  viewBox="0 0 448 512"
                >
                  <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                </svg>
              </div>
              <span>{data.period_1?.date || "Date not available"}</span>
            </div>
            <span className="price">
              € {data.period_1?.amount || "Amount not available"}
            </span>
          </div>
          <div className="details-offer">
            <div className="d-flex gap-2">
              <div
                className="check-next-days mb-2"
                style={{ width: "30px", height: "30px" }}
              >
                <img src={geartick} alt="tick" />
              </div>
              <span>
                {data.period_1?.hours !== undefined
                  ? `${data.period_1.hours} heures de conduite`
                  : "Illimitées"}
              </span>
            </div>
            <div className="d-flex gap-2">
              <div
                className="check-next-days mb-2"
                style={{ width: "30px", height: "30px" }}
              >
                <img src={detailtick} alt="detail" />
              </div>
              <span>1 formation au code de la route</span>
            </div>

            <div className="d-flex gap-2">
              <div
                className="check-next-days mb-2"
                style={{ width: "30px", height: "30px" }}
              >
                <img src={detailtick} alt="detail" />
              </div>
              <span>1 examen théorique du code de la route</span>
            </div>
          </div>
        </div>

        <div className="one-month-plus-ten-days-payment mb-3">
          <div className="d-flex align-items-center gap-2 justify-content-between fs-4 mb-2">
            <div className="d-flex align-items-center gap-2">
              <div className="check-next-days">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="22px"
                  width="22px"
                  viewBox="0 0 448 512"
                >
                  <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                </svg>
              </div>
              <span>{data.period_2?.date || "Date not available"}</span>
            </div>
            <span className="price">
              €{data.period_2?.amount || "Amount not available"}
            </span>
          </div>
          <div className="details-offer">
            <div className="d-flex gap-2">
              <div
                className="check-next-days"
                style={{ width: "30px", height: "30px" }}
              >
                <img src={geartick} alt="tick" />
              </div>
              <span>
                {data.period_2?.hours !== undefined
                  ? `${data.period_2.hours} heures de conduite`
                  : "Illimitées"}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (time === 3) {
    return (
      <div className="mb-3 manyTimePayment facture-suivi">
        <div className="today-payment mb-3">
          <div className="d-flex align-items-center gap-2 justify-content-between fs-4 mb-2">
            <div className="d-flex align-items-center gap-2">
              <div className="check-today">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="22px"
                  width="22px"
                  viewBox="0 0 448 512"
                >
                  <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                </svg>
              </div>
              <span>{data.period_1?.date || "Date not available"}</span>
            </div>
            <span className="price">
              €{data.period_1?.amount || "Amount not available"}
            </span>
          </div>
          <div className="details-offer">
            <div className="d-flex gap-2 ">
              <div
                className="check-next-days mb-2"
                style={{ width: "30px", height: "30px" }}
              >
                <img src={geartick} alt="tick" />
              </div>
              <span>
                {typeof data.period_1?.hours === "number"
                  ? `${data.period_1.hours} heures de conduite`
                  : "Illimitées"}
              </span>
            </div>
            <div className="d-flex gap-2">
              <div
                className="check-next-days mb-2"
                style={{ width: "30px", height: "30px" }}
              >
                <img src={detailtick} alt="detail" />
              </div>
              <span>1 formation au code de la route</span>
            </div>

            <div className="d-flex gap-2">
              <div
                className="check-next-days mb-2"
                style={{ width: "30px", height: "30px" }}
              >
                <img src={detailtick} alt="detail" />
              </div>
              <span>1 examen théorique du code de la route</span>
            </div>
          </div>
        </div>

        <div className="one-month-plus-ten-days-payment mb-3">
          <div className="d-flex align-items-center gap-2 justify-content-between fs-4 mb-2">
            <div className="d-flex align-items-center gap-2">
              <div className="check-next-days">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="22px"
                  width="22px"
                  viewBox="0 0 448 512"
                >
                  <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                </svg>
              </div>
              <span>{data.period_2?.date || "Date not available"}</span>
            </div>
            <span className="price">
              €{data.period_2?.amount || "Amount not available"}
            </span>
          </div>
          <div className="details-offer">
            <div className="d-flex gap-2 ">
              <div
                className="check-next-days"
                style={{ width: "30px", height: "30px" }}
              >
                <img src={geartick} alt="tick" />
              </div>
              <span>
                {typeof data.period_2?.hours === "number"
                  ? `${data.period_2.hours} heures de conduite`
                  : "Illimitées"}
              </span>
            </div>
          </div>
        </div>

        <div className="one-month-plus-twenty-days-payment mb-3">
          <div className="d-flex align-items-center gap-2 justify-content-between fs-4 mb-2">
            <div className="d-flex align-items-center gap-2">
              <div className="check-next-days">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="22px"
                  width="22px"
                  viewBox="0 0 448 512"
                >
                  <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                </svg>
              </div>
              <span>{data.period_3?.date || "Date not available"}</span>
            </div>
            <span className="price">
              €{data.period_3?.amount || "Amount not available"}
            </span>
          </div>
          <div className="details-offer">
            <div className="d-flex gap-2 ">
              <div
                className="check-next-days"
                style={{ width: "30px", height: "30px" }}
              >
                <img src={geartick} alt="tick" />
              </div>
              <span>
                {typeof data.period_3?.hours === "number"
                  ? `${data.period_3.hours} heures de conduite`
                  : "Illimitées"}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
}
