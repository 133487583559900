import React, { useEffect, useState } from "react";
import "./style.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import datecalc from "../images/date-calc.svg";
import axiosInstance from "../utils/http/config/axios";
import { ToastContainer, toast } from "react-toastify";
import geartick from "../images/hugeicons_steering.svg";
import detailtick from "../images/tabler_manual-gearbox.svg";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";
import CheckoutForm from "../Boutique/Checkoutremain";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Loading from "../components/Loading";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function Achats() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [description, setDescription] = useState(null);

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/students/transaction/${id}`
        );

        if (response.headers["content-type"]?.includes("application/json")) {
          setTransaction(response.data);
          extractDescription(response.data);
        } else {
          throw new Error("La réponse n'est pas au format JSON attendu.");
        }

        setLoading(false);
      } catch (err) {
        toast.error(
          "Erreur lors de la récupération des détails de la transaction:",
          err
        );
        if (err.response?.status === 401) {
          setError("Authentification échouée. Veuillez vous reconnecter.");
          localStorage.removeItem("token");
          navigate("/");
        } else {
          setError(
            err.response?.data?.message ||
              "Erreur lors de la récupération des détails de la transaction"
          );
        }
        setLoading(false);
      }
    };

    const extractDescription = (data) => {
      setDescription(data.description.split(","));
    };

    fetchTransactionDetails();
  }, [id, navigate]);

  const goBack = () => {
    navigate(-1);
  };

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  const paymentTime = 3;

  return (
    <section className="acharts">
      <ToastContainer />
      <div className="">
        <div className="wrapping">
          <div className="back-btn">
            <button
              type="button"
              onClick={goBack}
              className="btn btn-secondary btn-main"
            >
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.8055 9.06832C16.8086 9.29332 16.7221 9.5103 16.5651 9.67153C16.4081 9.83276 16.1935 9.92503 15.9685 9.92804L4.44214 10.0823L7.56004 13.1179C7.71205 13.2767 7.79648 13.4883 7.79554 13.7081C7.79461 13.928 7.70838 14.1389 7.55503 14.2964C7.40167 14.4539 7.19317 14.5457 6.97343 14.5526C6.7537 14.5594 6.5399 14.4806 6.37708 14.3329L1.79192 9.86891C1.63092 9.71196 1.53879 9.49753 1.53578 9.27272C1.53278 9.0479 1.61913 8.83108 1.77587 8.66989L6.2399 4.08473C6.39871 3.93272 6.61033 3.84829 6.83016 3.84923C7.05 3.85016 7.26089 3.93639 7.41841 4.08975C7.57592 4.2431 7.66776 4.45161 7.67458 4.67134C7.6814 4.89107 7.60267 5.10487 7.45497 5.2677L4.41942 8.38561L15.9458 8.23132C16.1708 8.2283 16.3878 8.3148 16.549 8.47177C16.7103 8.62874 16.8025 8.84332 16.8055 9.06832Z"
                  fill="white"
                />
              </svg>
              Retour
            </button>
          </div>

          <div className="vote-neph">
            <h4>Retrouvez tous vos achats ici</h4>
          </div>

          {transaction && (
            <div className="facture-cp-notes">
              <div className="calc-date">
                <div className="dte">
                  <img src={datecalc} alt="datecalc" />
                  <span>{new Date(transaction.date).toLocaleDateString()}</span>
                </div>
                <strong>{transaction.prix} €</strong>
              </div>
              <div className="d-flex justify-content-between gap-2 cdf-no">
                <div className="">
                  <p className="mb-0 fs-5">
                    <strong>
                      {transaction.achat
                        ? transaction.achat
                        : "Formation Licence CPF B"}
                    </strong>
                  </p>
                  {description.map((desc) => (
                    <div>{desc.trim()}</div>
                  ))}
                </div>
                <div className="CPF">
                  <div className="text-end">
                    <strong>{transaction.id} </strong>
                  </div>
                  <Link to={`/invoice/${transaction.id}`}>
                    <button
                      type="button"
                      className="btn btn-secondary btn-main"
                    >
                      Détails
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          )}

          {paymentTime > 1 && (
            <PaymentRecap
              transaction={transaction}
              totalAmountToPay={transaction?.totalAmountToPay}
            />
          )}
        </div>
      </div>
    </section>
  );
}

export default Achats;

const PaymentPeriod = ({ period, periodIndex }) => {
  const statusColor =
    period.status === "remboursé" || period.status === "annule"
      ? "#E81B26"
      : period.status === "en attente"
      ? "#CF8D0E"
      : period.status === "active"
      ? "rgba(0, 0, 0, 0.1)"
      : "#ad3697";

  return (
    <div className="payment-period">
      <div className="d-flex align-items-center gap-2 justify-content-between fs-4 mb-2">
        <div className="d-flex align-items-center gap-2">
          <div
            style={{ backgroundColor: statusColor }}
            className={`${
              period.status === "réussi" ? "check-today" : "check-next-days"
            }`}
          >
            <svg
              style={{
                fill: statusColor === "rgba(0, 0, 0, 0.1)" && "#ad3697",
              }}
              xmlns="http://www.w3.org/2000/svg"
              height="22px"
              width="22px"
              viewBox="0 0 448 512"
            >
              <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
            </svg>
          </div>

          <span>{period?.date || ""}</span>
        </div>
        <span className="price">{period?.amount || ""} €</span>
      </div>

      <div className="details-offer">
        <div className="d-flex gap-2 align-items-center mb-2">
          <div className="check-next-days">
            <img src={geartick} alt="tick" />{" "}
          </div>
          <span>
            {period?.hours !== undefined
              ? `${period.hours} heures de conduite`
              : "Illimitées"}
          </span>
        </div>

        {periodIndex === 1 && (
          <>
            <div className="d-flex gap-2 align-items-center mb-2">
              <div className="check-next-days">
                <img src={detailtick} alt="detail" />{" "}
              </div>
              <span>1 formation au code de la route</span>
            </div>
            <div className="d-flex gap-2 align-items-center mb-2">
              <div className="check-next-days">
                <img src={detailtick} alt="detail" />{" "}
              </div>
              <span>1 examen théorique du code de la route</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const PaymentRecap = ({ transaction }) => {
  const [paymentData, setPaymentData] = useState(null);
  const [openPayment, setOpenPayment] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [totalAmount, setTotalAmount] = useState(0);
  const [paymentIds, setPaymentIds] = useState([]);
  const [paidChoice, setPaidChoice] = useState(null);

  const handleAlert = (message, type) => {
    setAlert({ show: true, message, type });
    setTimeout(() => setAlert({ show: false, message: "", type: "" }), 5000);
  };

  const getFirstUnpaidPayment = () => {
    if (!paymentData) return null;

    const periods = Object.values(paymentData);
    const firstUnpaid = periods.find((period) => period.status !== "réussi");
    return firstUnpaid;
  };

  const getUnpaidPaymentIds = () => {
    if (!paymentData) return [];

    const periods = Object.values(paymentData);
    return periods
      .filter((period) => period.status !== "réussi")
      .map((period) => period.id);
  };

  const refreshPaymentData = () => {
    if (transaction.is_period) {
      const paymentId = transaction.id;
      axiosInstance
        .get(`/api/students/activate`, { params: { paymentId } })
        .then((response) => {
          const payments = response.data[0]?.payments || [];
          const periodsData = payments.reduce((acc, payment, index) => {
            acc[`period_${index + 1}`] = {
              id: payment.id,
              date: payment.nextBillingDate,
              amount: payment.amount,
              hours: payment.hours,
              status: payment.status,
            };
            return acc;
          }, {});
          setPaymentData(periodsData);
          setTotalAmount(response.data[0]?.totalAmount || 0);
          setPaymentIds(payments.map((payment) => payment.id));
        })
        .catch((error) => {
          toast.error("Erreur de récupération des données de paiement.");
        });
    }
  };

  useEffect(() => {
    refreshPaymentData();
  }, [transaction]);

  if (!paymentData) {
    return "";
  }

  const periodsCount = Object.keys(paymentData).length;

  // user choice between paid the remaining amount or just next month
  const handlePaidChoice = (e) => {
    setPaidChoice(e.target.name);
  };

  return (
    <div className="facture-suivi mt-4">
      <ToastContainer />
      <div className="mb-3">
        <h5>
          <strong>Suivi de paiement</strong>: Abonnement au forfait{" "}
          <span>
            <strong>{transaction.achat}</strong>
          </span>
        </h5>
      </div>
      {[...Array(periodsCount)].map((_, index) => {
        const periodKey = `period_${index + 1}`;
        return (
          <PaymentPeriod
            key={index}
            period={paymentData[periodKey]}
            periodIndex={index + 1}
          />
        );
      })}

      {totalAmount !== 0 && (
        <div className="d-flex justify-content-end">
          <button
            onClick={() => {
              setPaidChoice("nextPaid");
              setOpenPayment(true);
            }}
            type="button"
            className="btn btn-primary paid-btn"
          >
            Anticiper le prochain paiement
          </button>
        </div>
      )}
      {openPayment && (
        <Modal
          show={openPayment}
          onHide={() => setOpenPayment(false)}
          className="purchase-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center">
              Paiement Abonnement
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {periodsCount !== transaction.periodNumber && (
              <div className="d-flex gap-3 mb-3 flex-wrap">
                <div className="d-flex align-items-start gap-2">
                  <div className="checkbox-card">
                    <div
                      className={`check ${
                        paidChoice === "nextPaid" && "checked"
                      } `}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                      </svg>
                    </div>
                    <input
                      type="checkbox"
                      name="nextPaid"
                      id="nextPaid"
                      checked={paidChoice === "nextPaid"}
                      onChange={handlePaidChoice}
                    />
                  </div>
                  <label
                    style={{
                      color: paidChoice === "nextPaid" ? "#ad3697" : "#000",
                    }}
                    htmlFor="nextPaid"
                  >
                    Payer le prochain mois
                  </label>
                </div>

                <div className="d-flex align-items-center gap-2">
                  <div className="checkbox-card">
                    <div
                      className={`check ${
                        paidChoice === "remainingPaid" && "checked"
                      } `}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                      </svg>
                    </div>
                    <input
                      type="checkbox"
                      name="remainingPaid"
                      id="remainingPaid"
                      checked={paidChoice === "remainingPaid"}
                      onChange={handlePaidChoice}
                    />
                  </div>
                  <label
                    style={{
                      color:
                        paidChoice === "remainingPaid" ? "#ad3697" : "#000",
                    }}
                    htmlFor="remainingPaid"
                  >
                    Payer le reste de l'abonnement
                  </label>
                </div>
              </div>
            )}

            <Elements stripe={stripePromise}>
              <CheckoutForm
                paymentAmount={
                  paidChoice === "nextPaid"
                    ? getFirstUnpaidPayment()?.amount || 0
                    : totalAmount
                }
                paymentIds={
                  paidChoice === "nextPaid"
                    ? [getFirstUnpaidPayment()?.id]
                    : getUnpaidPaymentIds()
                }
                onSuccess={() => {
                  handleAlert("Paiement réussi!", "success");
                  toast.success("Paiement réussi !", {
                    position: toast.TOP_RIGHT,
                    autoClose: 3000,
                  });
                  refreshPaymentData();
                  setOpenPayment(false);
                }}
                onError={() =>
                  handleAlert(
                    "Le paiement a échoué. Veuillez réessayer.",
                    "danger"
                  )
                }
              />
            </Elements>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};
