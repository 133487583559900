import React, { useEffect, useState } from "react";
import "./style.css";
import welcome from "../images/welcome.svg";
import { useNavigate } from "react-router-dom";

function Welcome() {
  const navigate = useNavigate();
  const [showVisitButton, setShowVisitButton] = useState(false);

  useEffect(() => {
    setShowVisitButton(true);

    // Hide the button after 3 seconds
    const timer = setTimeout(() => {
      setShowVisitButton(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const handleLoginRedirect = () => {
    navigate("/");
  };

  const handleHomeRedirect = () => {
    window.location.href = "https://lcr-auto-ecole.com/";
  };

  return (
    <section className="welcome verify_email">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5 mx-auto">
            <div className="wrapping d-flex flex-column justify-content-center align-items-center">
              <img src={welcome} alt="Welcome" />
              <h3 className="text-center">
                Bienvenue chez LCR by Grand Moniteur de Paris !
              </h3>
              <p className="text-center">Votre compte a été créé</p>
              <div className="d-flex justify-content-center">
                {showVisitButton && (
                  <button
                    type="button"
                    className="btn btn-secondary btn-main email-recover"
                    onClick={handleHomeRedirect}
                  >
                    Visiter notre site
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-outline-primary btn-outline-main"
                  onClick={handleLoginRedirect}
                >
                  Se connecter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Welcome;
