import React from "react";
import { IconButton } from "./AudioCall/AudioCall";
import { MdDone } from "react-icons/md";
import { TbXboxXFilled } from "react-icons/tb";

export default function GetCallPopUp({
  popupText,
  handleAcceptCall,
  handleDenyCall,
}) {
  return (
    <div className="getCallPopUp">
      <main className="getCallPopUpContent">
        <span>{popupText}</span>
        <div>
          <IconButton
            onClick={handleDenyCall}
            icon={<TbXboxXFilled />}
            label={"Refuser"}
            style={{ background: "linear-gradient(to right, #C43CAB, #F87E4B)" }}
          />
          <IconButton
            onClick={handleAcceptCall}
            icon={<MdDone />}
            label={"Accepter"}
            style={{ background: "#C43CAB" }}
          />
        </div>
      </main>
    </div>
  );
}
