import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utils/http/config/axios";
import "./style.css";
import { toast, ToastContainer } from "react-toastify";

function Sponser() {
  const navigate = useNavigate();
  const [sponsorInfo, setSponsorInfo] = useState({
    sponsorCode: "",
    sponsorBalance: 0,
    sponsoredStudents: [],
    sponsorUrl: "",
    sponsorMoney: 0,
    godChildrenCount: 0,
    urlVisitCount: 0,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [newSponsorCode, setNewSponsorCode] = useState("");

  useEffect(() => {
    const fetchSponsorInfo = async () => {
      try {
        const response = await axiosInstance.get("/api/students/sponsor-info");
        setSponsorInfo(response.data);
      } catch (error) {
        toast.error(
          "Erreur lors de la récupération des informations de parrainage",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            pauseOnFocusLoss: false,
            draggable: false,
            theme: "light",
          }
        );
      }
    };

    fetchSponsorInfo();
  }, []);

  const copyToClipboard = () => {
    const registrationUrl = `https://app.lcr-auto-ecole.com/student/register/${sponsorInfo.sponsorCode}`;
    navigator.clipboard.writeText(registrationUrl);
    toast.success("Lien copié dans le presse-papiers", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      pauseOnFocusLoss: false,
      draggable: false,
      theme: "light",
    });
  };

  const goBack = () => {
    navigate(-1);
  };

  const updateSponsorCode = async () => {
    try {
      await axiosInstance.put("/api/students/sponsor-code", {
        sponsorCode: newSponsorCode,
      });
      setSponsorInfo((prev) => ({
        ...prev,
        sponsorCode: newSponsorCode,
      }));
      setIsEditing(false);
    } catch (error) {
      toast.error("Erreur lors de la mise à jour du code de parrainage", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
        draggable: false,
        theme: "light",
      });
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="light"
      />
      <section className="sponser">
        <div className="">
          <div className="wrapping">
            <div className="row">
              <div className="back-btn">
                <button
                  type="button"
                  onClick={goBack}
                  class="btn btn-secondary btn-main"
                >
                  {" "}
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.8055 9.06832C16.8086 9.29332 16.7221 9.5103 16.5651 9.67153C16.4081 9.83276 16.1935 9.92503 15.9685 9.92804L4.44214 10.0823L7.56004 13.1179C7.71205 13.2767 7.79648 13.4883 7.79554 13.7081C7.79461 13.928 7.70838 14.1389 7.55503 14.2964C7.40167 14.4539 7.19317 14.5457 6.97343 14.5526C6.7537 14.5594 6.5399 14.4806 6.37708 14.3329L1.79192 9.86891C1.63092 9.71196 1.53879 9.49753 1.53578 9.27272C1.53278 9.0479 1.61913 8.83108 1.77587 8.66989L6.2399 4.08473C6.39871 3.93272 6.61033 3.84829 6.83016 3.84923C7.05 3.85016 7.26089 3.93639 7.41841 4.08975C7.57592 4.2431 7.66776 4.45161 7.67458 4.67134C7.6814 4.89107 7.60267 5.10487 7.45497 5.2677L4.41942 8.38561L15.9458 8.23132C16.1708 8.2283 16.3878 8.3148 16.549 8.47177C16.7103 8.62874 16.8025 8.84332 16.8055 9.06832Z"
                      fill="white"
                    />
                  </svg>
                  Retour
                </button>
              </div>
              <div className="top-heading-sponser">
                <h2>
                  Parrainez vos amis pour réduire le coût de votre licence
                </h2>
                <span>
                  Gagnez jusqu'à <strong>50 EUR</strong> de conduite sur votre
                  prochaine commande, et bénéficiez d'une réduction jusqu'à{" "}
                  <strong>20%</strong> pour votre filleul !
                </span>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-3">
                <div className="code">
                  <h5>Mon code de parrainage</h5>
                  <div className="code-wrap">
                    {isEditing ? (
                      <div className="edit-sponsor-code">
                        <input
                          type="text"
                          value={newSponsorCode}
                          onChange={(e) => setNewSponsorCode(e.target.value)}
                          className="form-control"
                          placeholder="Entrez votre nouveau code"
                        />
                        <div className="edit-actions">
                          <button
                            className="btn btn-primary btn-sm me-2"
                            onClick={updateSponsorCode}
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20 6L9 17L4 12"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                          <button
                            className="btn btn-secondary btn-sm"
                            onClick={() => setIsEditing(false)}
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18 6L6 18M6 6L18 18"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="sponsor-code-display">
                        <strong>{sponsorInfo.sponsorCode}</strong>
                        <button
                          className="btn btn-link"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              sponsorInfo.sponsorCode
                            );
                            toast.success("Code copié dans le presse-papiers", {
                              position: "top-right",
                              autoClose: 3000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: false,
                              pauseOnFocusLoss: false,
                              draggable: false,
                              theme: "light",
                            });
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <rect
                              x="9"
                              y="9"
                              width="13"
                              height="13"
                              rx="2"
                              ry="2"
                            ></rect>
                            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                          </svg>
                        </button>
                        <button
                          className="btn btn-link"
                          onClick={() => {
                            setNewSponsorCode(sponsorInfo.sponsorCode);
                            setIsEditing(true);
                          }}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M18.5 2.50023C18.8978 2.1024 19.4374 1.87891 20 1.87891C20.5626 1.87891 21.1022 2.1024 21.5 2.50023C21.8978 2.89805 22.1213 3.43762 22.1213 4.00023C22.1213 4.56284 21.8978 5.1024 21.5 5.50023L12 15.0002L8 16.0002L9 12.0002L18.5 2.50023Z"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="kitty">
                  <h5>Cagnotte</h5>
                  <strong>€{sponsorInfo.sponsorMoney}</strong>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="children">
                  <h5>Mes parrainés</h5>
                  <strong>{sponsorInfo.sponsoredStudentsCount || "--"}</strong>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="children">
                  <h5>Visiteurs du lien</h5>
                  <strong>{sponsorInfo.sponsorUrlVisits || 0}</strong>
                </div>
              </div>
            </div>
            <div className="invitelink">
              <h3>Je partage mon lien d'invitation</h3>
              <div className="invite-code">
                <span className="invite-url">
                  https://app.lcr-auto-ecole.com/student/register/
                  {sponsorInfo.sponsorCode}
                </span>
                <svg
                  onClick={copyToClipboard}
                  style={{ cursor: "pointer" }}
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25.3125 3.75H10.3125C10.0639 3.75 9.8254 3.84877 9.64959 4.02459C9.47377 4.2004 9.375 4.43886 9.375 4.6875V9.375H4.6875C4.43886 9.375 4.2004 9.47377 4.02459 9.64959C3.84877 9.8254 3.75 10.0639 3.75 10.3125V25.3125C3.75 25.5611 3.84877 25.7996 4.02459 25.9754C4.2004 26.1512 4.43886 26.25 4.6875 26.25H19.6875C19.9361 26.25 20.1746 26.1512 20.3504 25.9754C20.5262 25.7996 20.625 25.5611 20.625 25.3125V20.625H25.3125C25.5611 20.625 25.7996 20.5262 25.9754 20.3504C26.1512 20.1746 26.25 19.9361 26.25 19.6875V4.6875C26.25 4.43886 26.1512 4.2004 25.9754 4.02459C25.7996 3.84877 25.5611 3.75 25.3125 3.75ZM18.75 24.375H5.625V11.25H18.75V24.375ZM24.375 18.75H20.625V10.3125C20.625 10.0639 20.5262 9.8254 20.3504 9.64959C20.1746 9.47377 19.9361 9.375 19.6875 9.375H11.25V5.625H24.375V18.75Z"
                    fill="#464646"
                  />
                </svg>
              </div>
            </div>
            <div className="how-works">
              <h3>Comment ça marche ?</h3>
              <div className="row">
                <div className="col-lg-4">
                  <div className="step1">
                    <div>
                      <svg
                        width="45"
                        height="45"
                        viewBox="0 0 45 45"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="22.5"
                          cy="22.5"
                          r="22.5"
                          fill="url(#paint0_linear_2040_5178)"
                        />
                        <path
                          d="M18.7544 20.35V17.575H25.5294V30H22.2794V20.35H18.7544Z"
                          fill="white"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_2040_5178"
                            x1="2.30315"
                            y1="13.3163"
                            x2="43.3868"
                            y2="16.9578"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#C43CAB" />
                            <stop offset="1" stop-color="#F87E4B" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <div>
                      <h5>Partagez votre code de parrainage</h5>
                      <p>
                        Profitez d'une réduction de 20% pour vos amis non
                        inscrits !
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="step1">
                    <div>
                      <svg
                        width="45"
                        height="45"
                        viewBox="0 0 45 45"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="22.5"
                          cy="22.5"
                          r="22.5"
                          fill="url(#paint0_linear_2040_5179)"
                        />
                        <path
                          d="M24.6497 26.225L20.5997 28.125L20.4747 27.5H29.7997V30H16.4747V27.5C17.9747 26.8167 19.3247 26.2333 20.5247 25.75C21.7247 25.2667 22.7414 24.8333 23.5747 24.45C24.408 24.05 25.0414 23.65 25.4747 23.25C25.9247 22.8333 26.1497 22.375 26.1497 21.875C26.1497 21.475 26.033 21.1333 25.7997 20.85C25.5664 20.55 25.1997 20.3167 24.6997 20.15C24.1997 19.9833 23.5497 19.9 22.7497 19.9C21.6997 19.9 20.8497 20.1083 20.1997 20.525C19.5664 20.925 19.233 21.475 19.1997 22.175H16.0497C15.9997 21.2083 16.233 20.3583 16.7497 19.625C17.283 18.8917 18.058 18.3167 19.0747 17.9C20.0914 17.4833 21.2914 17.275 22.6747 17.275C23.6914 17.275 24.6164 17.375 25.4497 17.575C26.283 17.7583 26.9997 18.0333 27.5997 18.4C28.2164 18.7667 28.683 19.2083 28.9997 19.725C29.333 20.2417 29.4997 20.825 29.4997 21.475C29.4997 21.975 29.408 22.4333 29.2247 22.85C29.0414 23.25 28.7497 23.6333 28.3497 24C27.9664 24.3667 27.4664 24.7333 26.8497 25.1C26.233 25.45 25.4997 25.825 24.6497 26.225Z"
                          fill="white"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_2040_5179"
                            x1="2.30315"
                            y1="13.3163"
                            x2="43.3868"
                            y2="16.9578"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#C43CAB" />
                            <stop offset="1" stop-color="#F87E4B" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>

                    <div>
                      <h5>Gagnez 50 EUR de conduite dans votre cagnotte</h5>
                      <p>
                        à chaque produit (hors code revue) acheté par vos amis.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="step1">
                    <div>
                      <svg
                        width="45"
                        height="45"
                        viewBox="0 0 45 45"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="22.5"
                          cy="22.5"
                          r="22.5"
                          fill="url(#paint0_linear_2040_5180)"
                        />
                        <path
                          d="M23.8672 24.4V24.275C24.8505 24.3083 25.7505 24.3917 26.5672 24.525C27.4005 24.6417 28.1172 24.85 28.7172 25.15C29.3172 25.4333 29.7839 25.8333 30.1172 26.35C30.4505 26.8667 30.6172 27.525 30.6172 28.325C30.6172 29.2583 30.3172 30.0833 29.7172 30.8C29.1339 31.5167 28.3005 32.075 27.2172 32.475C26.1339 32.8917 24.8589 33.1 23.3922 33.1C21.9589 33.1 20.7005 32.875 19.6172 32.425C18.5505 31.9917 17.7339 31.3833 17.1672 30.6C16.6005 29.8333 16.3422 28.9417 16.3922 27.925H19.5422C19.5589 28.4083 19.7172 28.825 20.0172 29.175C20.3339 29.5417 20.7755 29.825 21.3422 30.025C21.9255 30.225 22.6089 30.325 23.3922 30.325C24.6089 30.325 25.5422 30.1167 26.1922 29.7C26.8422 29.2833 27.1672 28.6667 27.1672 27.85C27.1672 27.0667 26.7922 26.5167 26.0422 26.2C25.2922 25.8667 24.2339 25.7 22.8672 25.7H20.7422V23.2H22.8672C23.8172 23.2 24.5839 23.1417 25.1672 23.025C25.7672 22.8917 26.2005 22.6833 26.4672 22.4C26.7505 22.1 26.8922 21.7083 26.8922 21.225C26.8922 20.5417 26.6172 20.0083 26.0672 19.625C25.5172 19.225 24.6255 19.025 23.3922 19.025C22.2255 19.025 21.3255 19.2333 20.6922 19.65C20.0755 20.0667 19.7505 20.6167 19.7172 21.3H16.5672C16.5172 20.5167 16.6589 19.8167 16.9922 19.2C17.3422 18.5833 17.8339 18.0583 18.4672 17.625C19.1172 17.175 19.8589 16.8333 20.6922 16.6C21.5422 16.3667 22.4422 16.25 23.3922 16.25C24.8255 16.25 26.0589 16.4333 27.0922 16.8C28.1255 17.1667 28.9172 17.6833 29.4672 18.35C30.0172 19.0167 30.2922 19.7917 30.2922 20.675C30.2922 21.4083 30.1339 22.0167 29.8172 22.5C29.5005 22.9667 29.0505 23.3417 28.4672 23.625C27.8839 23.8917 27.2005 24.0833 26.4172 24.2C25.6505 24.3167 24.8005 24.3833 23.8672 24.4Z"
                          fill="white"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_2040_5180"
                            x1="2.30315"
                            y1="13.3163"
                            x2="43.3868"
                            y2="16.9578"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#C43CAB" />
                            <stop offset="1" stop-color="#F87E4B" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <div>
                      <h5>Votre cagnotte est automatiquement utilisée</h5>
                      <p>pour financer votre prochain achat.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Sponser;
