import React, { useEffect, useState } from "react";
import "./style.css";
import trans from "../images/trans.png";
import Pagination from "react-bootstrap/Pagination";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utils/http/config/axios";
import { ToastContainer, toast } from "react-toastify";
import detailIcon from "../images/view-row.png";
import refundIcon from "../images/del-row.png";
import "react-toastify/dist/ReactToastify.css";
import { Accordion, Modal } from "react-bootstrap";
import Loading from "../components/Loading";
import P_Spinner from "../components/P_Spinner";

function Transaction() {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [pagination, setPagination] = useState({
    totalItems: 0,
    totalPages: 1,
    currentPage: 1,
    limit: 10,
  });
  const [refreshTransactions, setRefreshTransactions] = useState(false);
  const [comment, setComment] = useState("");
  const [reason, setReason] = useState("");
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);
  const navigate = useNavigate();

  const getPaginationItems = (currentPage, totalPages) => {
    const range = 2;
    let startPage = Math.max(currentPage - range, 1);
    let endPage = Math.min(currentPage + range, totalPages);

    const paginationItems = [];

    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(i);
    }

    return paginationItems;
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axiosInstance.get("/api/students/transactions", {
          params: {
            page: currentPage,
            limit: itemsPerPage,
          },
        });

        setTransactions(response.data.transactions);
        setPagination({
          totalItems: response.data.pagination.totalItems,
          totalPages: response.data.pagination.totalPages,
          currentPage: response.data.pagination.currentPage,
          limit: response.data.pagination.limit,
        });
        setLoading(false);
      } catch (err) {
        setError("Échec de la récupération des transactions");
        toast.error("Échec de la récupération des transactions");
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [currentPage, itemsPerPage, refreshTransactions]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowClick = (transactionId) => {
    navigate(`/achats/${transactionId}`);
  };

  const handleRefund = (transactionId) => {
    setSelectedTransactionId(transactionId);
    setOpenModal(true);
  };

  const handleChooseReason = (reason) => {
    setReason(reason);
  };

  const handleSubmitRefund = async (paymentId) => {
    if (!reason) {
      toast.error(
        "Veuillez sélectionner un motif pour la demande de remboursement."
      );
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await axiosInstance.post(
        `/api/students/remboursement/${paymentId}`,
        {
          reason,
          comment: reason === "Autre" ? comment : "",
        }
      );

      if (response.data.success) {
        toast.success(
          "Votre demande de remboursement a été envoyée avec succès !"
        );
        setOpenModal(false);
        setRefreshTransactions(!refreshTransactions);
      } else {
        toast.error(
          response.data.message ||
            "Une erreur est survenue, veuillez réessayer."
        );
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error(
          "Une erreur est survenue lors de la demande de remboursement. Veuillez réessayer plus tard."
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTransactions = transactions.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginationItems = getPaginationItems(
    currentPage,
    pagination.totalPages
  );

  if (loading) {
    return <div><Loading/></div>;
  }

  if (error) {
    return <p>{error}</p>;
  }
  const goBack = () => {
    navigate(-1);
  };

  return (
    <section className="transaction">
      <ToastContainer />
      <div className="container-fluid">
        <div className="wrapping">
        <div className="course-header">
        <h3 className="m-0">Mes Transactions</h3>

        <div className="back-btn">
            <button
              type="button"
              onClick={goBack}
              className="btn btn-secondary btn-main"
            >
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.8055 9.06832C16.8086 9.29332 16.7221 9.5103 16.5651 9.67153C16.4081 9.83276 16.1935 9.92503 15.9685 9.92804L4.44214 10.0823L7.56004 13.1179C7.71205 13.2767 7.79648 13.4883 7.79554 13.7081C7.79461 13.928 7.70838 14.1389 7.55503 14.2964C7.40167 14.4539 7.19317 14.5457 6.97343 14.5526C6.7537 14.5594 6.5399 14.4806 6.37708 14.3329L1.79192 9.86891C1.63092 9.71196 1.53879 9.49753 1.53578 9.27272C1.53278 9.0479 1.61913 8.83108 1.77587 8.66989L6.2399 4.08473C6.39871 3.93272 6.61033 3.84829 6.83016 3.84923C7.05 3.85016 7.26089 3.93639 7.41841 4.08975C7.57592 4.2431 7.66776 4.45161 7.67458 4.67134C7.6814 4.89107 7.60267 5.10487 7.45497 5.2677L4.41942 8.38561L15.9458 8.23132C16.1708 8.2283 16.3878 8.3148 16.549 8.47177C16.7103 8.62874 16.8025 8.84332 16.8055 9.06832Z"
                  fill="white"
                />
              </svg>
              Retour
            </button>
          </div>
        </div>

          {transactions.length === 0 ? (
            <div className="text-center">
              <img src={trans} alt="No Transactions" />
              <p>Aucune transaction pour le moment !</p>
            </div>
          ) : (
            <div className="eva-time table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>S.no</th>
                    <th>Date</th>
                    <th>Méthode</th>
                    <th>Achat</th>
                    <th>Prix</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.map((transaction, index) => (
                    <tr key={transaction.id} style={{ cursor: "pointer" }}>
                      <td onClick={() => handleRowClick(transaction.id)}>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                      <td onClick={() => handleRowClick(transaction.id)}>{new Date(transaction.date).toLocaleDateString()}</td>
                      <td onClick={() => handleRowClick(transaction.id)}>{transaction.method}</td>
                      <td onClick={() => handleRowClick(transaction.id)}>{transaction.achat}</td>
                      <td onClick={() => handleRowClick(transaction.id)}>{transaction.prix} €</td>
                      <td onClick={() => handleRowClick(transaction.id)}>{transaction.status}</td>
                      <td className="d-flex align-items-center gap-2">
                        <img
                          src={refundIcon}
                          onClick={() => handleRefund(transaction.id)}
                          style={{ margin: "0px" }}
                        />
                        <img
                          src={detailIcon}
                          onClick={() => handleRowClick(transaction.id)}
                          style={{ margin: "0px" }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Pagination */}
          <Pagination className="mb-0">
            <Pagination.First
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            />
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {paginationItems.map((page) => (
              <Pagination.Item
                key={page}
                active={currentPage === page}
                onClick={() => handlePageChange(page)}
              >
                {page}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === pagination.totalPages}
            />
            <Pagination.Last
              onClick={() => handlePageChange(pagination.totalPages)}
              disabled={currentPage === pagination.totalPages}
            />
          </Pagination>
        </div>
      </div>

      {openModal && (
        <Modal
          onHide={() => setOpenModal(false)}
          show={openModal}
          className="refund-modal"
        >
          <Modal.Header>
            <h2 className="refund-modal-title">Demande de remboursement</h2>
          </Modal.Header>
          <Modal.Body>
            <Accordion className="refund-reasons" defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Quel est le motif de votre demande ?
                </Accordion.Header>
                <Accordion.Body>
                  <div
                    className={`refund-reasons-choice ${
                      reason === "Facturation incorrecte" &&
                      "active-refund-reason"
                    }`}
                    onClick={() => handleChooseReason("Facturation incorrecte")}
                  >
                    Facturation incorrecte
                  </div>
                  <div
                    className={`refund-reasons-choice ${
                      reason === "Problème avec le service" &&
                      "active-refund-reason"
                    }`}
                    onClick={() =>
                      handleChooseReason("Problème avec le service")
                    }
                  >
                    Problème avec le service
                  </div>
                  <div
                    className={`refund-reasons-choice ${
                      reason === "Erreur dans la commande" &&
                      "active-refund-reason"
                    }`}
                    onClick={() =>
                      handleChooseReason("Erreur dans la commande")
                    }
                  >
                    Erreur dans la commande
                  </div>
                  <div
                    className={`refund-reasons-choice ${
                      reason === "Non-satisfaction" && "active-refund-reason"
                    }`}
                    onClick={() => handleChooseReason("Non-satisfaction")}
                  >
                    Non-satisfaction
                  </div>
                  <div
                    className={`refund-reasons-choice ${
                      reason === "Duplicata" && "active-refund-reason"
                    }`}
                    onClick={() => handleChooseReason("Duplicata")}
                  >
                    Duplicata
                  </div>
                  <div
                    className={`refund-reasons-choice ${
                      reason === "Autre" && "active-refund-reason"
                    }`}
                    onClick={() => handleChooseReason("Autre")}
                  >
                    Autre
                  </div>

                  {/* Conditionally render the input field if 'Autre' is selected */}
                  {reason === "Autre" && (
                    <div className="refund-comment">
                      <label
                        htmlFor="refund-comment-input"
                        className="form-label"
                      >
                        Votre commentaire:
                      </label>
                      <textarea
                        id="refund-comment-input"
                        className="form-control"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        placeholder="Entrez votre commentaire ici..."
                      />
                    </div>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="refund-vld-choice"
              disabled={isSubmitting}
              onClick={() => handleSubmitRefund(selectedTransactionId)}
            >
              {isSubmitting ? <P_Spinner/> : "Valider"}
            </button>

            <button
              className="close-modal-btn"
              onClick={() => {
                setReason("");
                setOpenModal(false);
              }}
            >
              Fermer
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </section>
  );
}

export default Transaction;