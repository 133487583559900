// import axiosInstance from "./http/config/axios";

// export const createPeerConnection = async (
//   socket,
//   localUserId,
//   remoteUserId,
//   onTrack,
//   onIceCandidate
// ) => {
//   // Validate browser environment
//   if (typeof window === "undefined") {
//     throw new Error(
//       "[CLIENT] createPeerConnection can only be used in a browser environment."
//     );
//   }

//   // Validate required parameters
//   if (!socket || !localUserId || !remoteUserId || !onTrack || !onIceCandidate) {
//     throw new Error("[CLIENT] Missing parameters for creating peerConnection.");
//   }

//   console.log(
//     "[CLIENT] createPeerConnection => localUserId:",
//     localUserId,
//     "remoteUserId:",
//     remoteUserId
//   );

//   // Default ICE server configuration with multiple TURN servers for redundancy
//   const fallbackIceServers = [
//     { urls: "stun:stun1.l.google.com:19302" },
//     { urls: "stun:stun2.l.google.com:19302" },
//     { urls: "stun:stun3.l.google.com:19302" },
//     { urls: "stun:stun4.l.google.com:19302" },
//     {
//       urls: "turn:relay1.expressturn.com:3478",
//       username: "efDB0JZE6MAB4VDZLD",
//       credential: "CCdUoJopv0hGNIpC",
//     },
   
//   ];

//   // Enhanced ICE configuration setup
//   let configuration = {
//     iceServers: [
//       { urls: "stun:stun1.l.google.com:19302" },
//     { urls: "stun:stun2.l.google.com:19302" },
//     { urls: "stun:stun3.l.google.com:19302" },
//     { urls: "stun:stun4.l.google.com:19302" },
//     {
//       urls: 'turn:192.162.71.107:3478?transport=udp',
//             username: 'username',
//             credential: 'password'
//     },
//     ],
//   };

  

//   // Fetch ICE servers from API with timeout
//   // try {
//   //   const controller = new AbortController();
//   //   const timeoutId = setTimeout(() => controller.abort(), configuration.iceServersTimeout);
    
//   //   const response = await axiosInstance.get("/api/servers/ice", {
//   //     signal: controller.signal
//   //   });
//   //   clearTimeout(timeoutId);
    
//   //   const customIceServers = response;
//   //   configuration.iceServers = [...customIceServers, ...fallbackIceServers];
//   //   console.log("[CLIENT] ICE Configuration:", configuration);
//   // } catch (error) {
//   //   console.error("[CLIENT] Failed to fetch ICE servers:", error);
//   //   configuration.iceServers = fallbackIceServers;
//   //   console.warn("[CLIENT] Using fallback ICE servers.");
//   // }

//   // Helper: Handle ICE connection failures with advanced recovery
//   const handleICEFailure = async (pc) => {
//     console.error("[CLIENT] ICE Connection Failed");
//     console.log({
//       iceConnectionState: pc.iceConnectionState,
//       connectionState: pc.connectionState,
//       signalingState: pc.signalingState,
//       iceGatheringState: pc.iceGatheringState,
//     });

//     // Force TURN-only mode on failure
//     configuration.iceTransportPolicy = "relay";
    
//     // Attempt ICE restart with exponential backoff
//     let retryAttempt = 0;
//     const maxRetries = 3;
    
//     const attemptRestart = async () => {
//       if (retryAttempt >= maxRetries) {
//         console.error("[CLIENT] Max ICE restart attempts reached");
//         return;
//       }
      
//       try {
//         if (pc.restartIce) {
//           console.log("[CLIENT] Attempting ICE restart...");
//           await pc.restartIce();
          
//           // Create and send new offer after ICE restart
//           if (pc.signalingState === "stable") {
//             const offer = await pc.createOffer({ iceRestart: true });
//             await pc.setLocalDescription(offer);
//             socket.emit("webrtc-signal", {
//               type: "offer",
//               offer: offer,
//               to: remoteUserId,
//               fromUserId: localUserId,
//             });
//           }
//         }
//       } catch (error) {
//         console.error("[CLIENT] ICE restart failed:", error);
//         retryAttempt++;
//         // Exponential backoff for retry
//         setTimeout(attemptRestart, Math.pow(2, retryAttempt) * 1000);
//       }
//     };
    
//     attemptRestart();
//   };

//   // Create RTCPeerConnection with enhanced error handling
//   try {
//     const pc = new RTCPeerConnection(configuration);

//     console.log("[CLIENT] RTCPeerConnection created with methods:", 
//       Object.keys(Object.getPrototypeOf(pc))
//     );

//     // Event: Handle remote track with quality monitoring
//     pc.ontrack = (event) => {
//       if (event.streams && event.streams[0]) {
//         console.log("[CLIENT] Remote track received:", event.streams[0]);
        
//         // Monitor track stats for quality
//         const statsInterval = setInterval(async () => {
//           try {
//             const stats = await pc.getStats(event.track);
//             stats.forEach(stat => {
//               if (stat.type === "inbound-rtp") {
//                 console.log("[CLIENT] Track Stats:", {
//                   packetsLost: stat.packetsLost,
//                   jitter: stat.jitter,
//                   bytesReceived: stat.bytesReceived
//                 });
//               }
//             });
//           } catch (error) {
//             console.error("[CLIENT] Failed to get track stats:", error);
//           }
//         }, 5000);

//         // Cleanup interval when track ends
//         event.track.onended = () => clearInterval(statsInterval);
        
//         onTrack(event.streams[0]);
//       }
//     };

//     // Event: Enhanced ICE candidate handling with filtering
//     pc.onicecandidate = (event) => {
//       console.log("Generated candidate:", event.candidate);
//       if (event.candidate) {
//         const candidate = event.candidate;

//         socket.emit("ice-candidate", {
//           candidate: candidate,
//           to: remoteUserId,
//           fromUserId: localUserId,
//         });
//         onIceCandidate(candidate);
        
//         // Log detailed candidate information
//         console.log("[CLIENT] New ICE candidate:", {
//           type: candidate.type,
//           protocol: candidate.protocol,
//           address: candidate.address,
//           port: candidate.port,
//           relayProtocol: candidate.relayProtocol,
//           priority: candidate.priority,
//           foundation: candidate.foundation
//         });
//       } else {
//         console.log("[CLIENT] All ICE candidates have been gathered");
//       }
//     };

//     // Event: Enhanced ICE connection state monitoring
//     pc.oniceconnectionstatechange = () => {
//       console.log("[CLIENT] ICE connection state:", pc.iceConnectionState);
//       switch (pc.iceConnectionState) {
//         case "checking":
//           console.log("[CLIENT] ICE connection checking...");
//           break;
//         case "connected":
//         case "completed":
//           console.log("[CLIENT] ICE connection established successfully!");
//           // Monitor connection quality
//           startConnectionQualityMonitoring(pc);
//           break;
//         case "disconnected":
//           console.warn("[CLIENT] ICE connection disconnected. Attempting recovery...");
//           if (pc.restartIce) {
//             // Gradual recovery with increasing delays
//             setTimeout(() => pc.restartIce(), 1000);
//             setTimeout(() => {
//               if (pc.iceConnectionState === "disconnected") {
//                 pc.restartIce();
//               }
//             }, 3000);
//           }
//           break;
//         case "failed":
//           console.error("[CLIENT] ICE connection failed.");
//           handleICEFailure(pc);
//           break;
//       }
//     };

//     // Enhanced ICE gathering state monitoring
//     pc.onicegatheringstatechange = () => {
//       console.log("[CLIENT] ICE Gathering State:", pc.iceGatheringState);
//       if (pc.iceGatheringState === "complete") {
//         socket.emit("final-ice-candidate", {
//           sdp: pc.localDescription.sdp,
//           to: remoteUserId,
//           fromUserId: localUserId,
//         });
//         const candidates = pc.localDescription.sdp
//           .split("\n")
//           .filter((line) => line.startsWith("a=candidate:"));
          
//         console.log(
//           "[CLIENT] ICE Gathering complete. Total candidates:",
//           candidates.length,
//           "Candidates:",
//           candidates
//         );
        
//         // Check if we have TURN candidates
//         const hasTurnCandidates = candidates.some(c => c.includes("relay"));
//         if (!hasTurnCandidates) {
//           console.warn("[CLIENT] No TURN candidates found. Connection may be unreliable.");
//         }
//       }
//     };

//     // Connection quality monitoring
//     const startConnectionQualityMonitoring = (pc) => {
//       const monitorInterval = setInterval(async () => {
//         try {
//           const stats = await pc.getStats();
//           let totalPacketsLost = 0;
//           let totalPackets = 0;
          
//           stats.forEach(stat => {
//             if (stat.type === "inbound-rtp" || stat.type === "outbound-rtp") {
//               if (stat.packetsLost) totalPacketsLost += stat.packetsLost;
//               if (stat.packetsSent) totalPackets += stat.packetsSent;
//               if (stat.packetsReceived) totalPackets += stat.packetsReceived;
//             }
//           });
          
//           const lossRate = (totalPacketsLost / totalPackets) * 100;
//           if (lossRate > 5) {
//             console.warn("[CLIENT] High packet loss detected:", lossRate.toFixed(2) + "%");
//             // Consider switching to lower quality or implementing recovery
//           }
//         } catch (error) {
//           console.error("[CLIENT] Failed to monitor connection quality:", error);
//         }
//       }, 5000);

//       // Cleanup interval when connection ends
//       pc.oniceconnectionstatechange = () => {
//         if (pc.iceConnectionState === "closed") {
//           clearInterval(monitorInterval);
//         }
//       };
//     };

//     // Timeout: Enhanced connection establishment monitoring
//     const CONNECTION_TIMEOUT = 30000; // 30 seconds
//     const connectionTimeoutHandler = setTimeout(() => {
//       if (
//         pc.iceConnectionState !== "connected" &&
//         pc.iceConnectionState !== "completed"
//       ) {
//         console.warn("[CLIENT] Connection timeout. Forcing TURN-only mode...");
//         configuration.iceTransportPolicy = "relay";
//         handleICEFailure(pc);
//       }
//     }, CONNECTION_TIMEOUT);

//     // Clear timeout on successful connection
//     const originalStateHandler = pc.oniceconnectionstatechange;
//     pc.oniceconnectionstatechange = () => {
//       console.log("[CLIENT] Connection state:", pc.connectionState);
//       if (
//         pc.iceConnectionState === "connected" ||
//         pc.iceConnectionState === "completed"
//       ) {
//         clearTimeout(connectionTimeoutHandler);
//       }
//       if (originalStateHandler) originalStateHandler();
//     };

//     return pc;
//   } catch (error) {
//     console.error("[CLIENT] Failed to create RTCPeerConnection:", error);
//     throw error;
//   }
// };







export const createPeerConnection = async (
  socket,
  localUserId,
  remoteUserId,
  onTrack,
  onIceCandidate
) => {
  // Validate browser environment
  if (typeof window === "undefined") {
    throw new Error(
      "[CLIENT] createPeerConnection can only be used in a browser environment."
    );
  }

  // Validate required parameters
  if (!socket || !localUserId || !remoteUserId || !onTrack || !onIceCandidate) {
    throw new Error("[CLIENT] Missing parameters for creating peerConnection.");
  }

  console.log(
    "[CLIENT] createPeerConnection => localUserId:",
    localUserId,
    "remoteUserId:",
    remoteUserId
  );

  const configuration = {
    iceServers: [
      { urls: "stun:stun1.l.google.com:19302" },
      { urls: "stun:stun2.l.google.com:19302" },
      { urls: "stun:stun3.l.google.com:19302" },
      { urls: "stun:stun4.l.google.com:19302" },
      {
        urls: 'turn:192.162.71.107:3478?transport=udp',
        username: 'username',
        credential: 'password'
      },
    ],
  };

  // Create candidate queues
  const localCandidateQueue = [];
  const remoteCandidateQueue = [];
  let isRemoteDescriptionSet = false;

  // Helper function to process queued remote candidates
  const processRemoteCandidateQueue = async (pc) => {
    while (remoteCandidateQueue.length > 0) {
      const candidate = remoteCandidateQueue.shift();
      try {
        await pc.addIceCandidate(candidate);
        console.log("[CLIENT] Added queued remote ICE candidate:", candidate);
      } catch (error) {
        console.error("[CLIENT] Error adding queued remote candidate:", error);
        remoteCandidateQueue.push(candidate);
        break;
      }
    }
  };

  // Helper function to process queued local candidates
  const processLocalCandidateQueue = () => {
    while (localCandidateQueue.length > 0) {
      const candidate = localCandidateQueue.shift();
      socket.emit("ice-candidate", {
        candidate: candidate,
        to: remoteUserId,
        fromUserId: localUserId,
      });
      onIceCandidate(candidate);
      console.log("[CLIENT] Sent queued local ICE candidate:", candidate);
    }
  };

  const handleICEFailure = async (pc) => {
    console.error("[CLIENT] ICE Connection Failed");
    console.log({
      iceConnectionState: pc.iceConnectionState,
      connectionState: pc.connectionState,
      signalingState: pc.signalingState,
      iceGatheringState: pc.iceGatheringState,
    });

    configuration.iceTransportPolicy = "relay";
    
    let retryAttempt = 0;
    const maxRetries = 3;
    
    const attemptRestart = async () => {
      if (retryAttempt >= maxRetries) {
        console.error("[CLIENT] Max ICE restart attempts reached");
        return;
      }
      
      try {
        if (pc.restartIce) {
          console.log("[CLIENT] Attempting ICE restart...");
          await pc.restartIce();
          
          if (pc.signalingState === "stable") {
            const offer = await pc.createOffer({ iceRestart: true });
            await pc.setLocalDescription(offer);
            socket.emit("webrtc-signal", {
              type: "offer",
              offer: offer,
              to: remoteUserId,
              fromUserId: localUserId,
            });
          }
        }
      } catch (error) {
        console.error("[CLIENT] ICE restart failed:", error);
        retryAttempt++;
        setTimeout(attemptRestart, Math.pow(2, retryAttempt) * 1000);
      }
    };
    
    attemptRestart();
  };

  const startConnectionQualityMonitoring = (pc) => {
    const monitorInterval = setInterval(async () => {
      try {
        const stats = await pc.getStats();
        let totalPacketsLost = 0;
        let totalPackets = 0;
        
        stats.forEach(stat => {
          if (stat.type === "inbound-rtp" || stat.type === "outbound-rtp") {
            if (stat.packetsLost) totalPacketsLost += stat.packetsLost;
            if (stat.packetsSent) totalPackets += stat.packetsSent;
            if (stat.packetsReceived) totalPackets += stat.packetsReceived;
          }
        });
        
        const lossRate = (totalPacketsLost / totalPackets) * 100;
        if (lossRate > 5) {
          console.warn("[CLIENT] High packet loss detected:", lossRate.toFixed(2) + "%");
        }
      } catch (error) {
        console.error("[CLIENT] Failed to monitor connection quality:", error);
      }
    }, 5000);

    pc.oniceconnectionstatechange = () => {
      if (pc.iceConnectionState === "closed") {
        clearInterval(monitorInterval);
      }
    };
  };

  try {
    const pc = new RTCPeerConnection(configuration);

    // Track handling
    pc.ontrack = (event) => {
      if (event.streams && event.streams[0]) {
        console.log("[CLIENT] Remote track received:", event.streams[0]);
        
        const statsInterval = setInterval(async () => {
          try {
            const stats = await pc.getStats(event.track);
            stats.forEach(stat => {
              if (stat.type === "inbound-rtp") {
                console.log("[CLIENT] Track Stats:", {
                  packetsLost: stat.packetsLost,
                  jitter: stat.jitter,
                  bytesReceived: stat.bytesReceived
                });
              }
            });
          } catch (error) {
            console.error("[CLIENT] Failed to get track stats:", error);
          }
        }, 5000);

        event.track.onended = () => clearInterval(statsInterval);
        
        onTrack(event.streams[0]);
      }
    };

    // Modified onicecandidate handler with queueing
    pc.onicecandidate = (event) => {
      if (event.candidate) {
        const candidate = event.candidate;
        console.log("[CLIENT] Generated ICE candidate:", candidate);

        if (!isRemoteDescriptionSet) {
          console.log("[CLIENT] Queueing local ICE candidate");
          localCandidateQueue.push(candidate);
        } else {
          socket.emit("ice-candidate", {
            candidate: candidate,
            to: remoteUserId,
            fromUserId: localUserId,
          });
          onIceCandidate(candidate);
        }
      } else {
        console.log("[CLIENT] All ICE candidates have been gathered");
      }
    };

    pc.oniceconnectionstatechange = () => {
      console.log("[CLIENT] ICE connection state:", pc.iceConnectionState);
      switch (pc.iceConnectionState) {
        case "checking":
          console.log("[CLIENT] ICE connection checking...");
          break;
        case "connected":
        case "completed":
          console.log("[CLIENT] ICE connection established successfully!");
          startConnectionQualityMonitoring(pc);
          break;
        case "disconnected":
          console.warn("[CLIENT] ICE connection disconnected. Attempting recovery...");
          if (pc.restartIce) {
            setTimeout(() => pc.restartIce(), 1000);
            setTimeout(() => {
              if (pc.iceConnectionState === "disconnected") {
                pc.restartIce();
              }
            }, 3000);
          }
          break;
        case "failed":
          console.error("[CLIENT] ICE connection failed.");
          handleICEFailure(pc);
          break;
      }
    };

    pc.onicegatheringstatechange = () => {
      console.log("[CLIENT] ICE Gathering State:", pc.iceGatheringState);
    };

    // Enhanced setRemoteDescription method
    const originalSetRemoteDescription = pc.setRemoteDescription.bind(pc);
    pc.setRemoteDescription = async function(description) {
      await originalSetRemoteDescription(description);
      isRemoteDescriptionSet = true;
      console.log("[CLIENT] Remote description set, processing candidate queues");
      await processRemoteCandidateQueue(this);
      processLocalCandidateQueue();
    };

    // Method to add remote candidates
    pc.addRemoteCandidate = async function(candidate) {
      if (isRemoteDescriptionSet) {
        try {
          await this.addIceCandidate(candidate);
          console.log("[CLIENT] Added remote ICE candidate:", candidate);
        } catch (error) {
          console.error("[CLIENT] Error adding remote candidate:", error);
          remoteCandidateQueue.push(candidate);
        }
      } else {
        console.log("[CLIENT] Queueing remote ICE candidate");
        remoteCandidateQueue.push(candidate);
      }
    };

    // Add method to add local media stream
    pc.addLocalStream = async function(stream) {
      try {
        stream.getTracks().forEach(track => {
          this.addTrack(track, stream);
          console.log("[CLIENT] Added local track:", track.kind);
        });
      } catch (error) {
        console.error("[CLIENT] Error adding local stream:", error);
        throw error;
      }
    };

    return pc;
  } catch (error) {
    console.error("[CLIENT] Failed to create RTCPeerConnection:", error);
    throw error;
  }
};




