// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-window .btn-primary {
  background-image: linear-gradient(to right, #c43cab, #f87e4b);
  border: 0;
  border-radius: 26px;
  font-size: 12px;
}

.map-window {
  padding: 0 0.5rem;
}
.lesson-count {
  background-image: linear-gradient(to right, #D091FF, #FFD99F);
  padding: 4px;
  border-radius: 3px;
  font-weight: 600;
}
button.gm-ui-hover-effect {
  display: none !important;
}
div#\\39 64D6C5A-1BF1-4822-98E2-1C23D9A6B59D {
  display: none;
}


  .p-spinner {
    --uib-size: 30px;
    --uib-color: #c43cab;
    --uib-speed: 1.4s;
    --uib-bg-opacity: .5;
    height: var(--uib-size);
    width: var(--uib-size);
    transform-origin: center;
    overflow: visible;
  }

  .p-spinner-car {
    fill: none;
    stroke: var(--uib-color);
    stroke-dasharray: 15, 85;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: travel var(--uib-speed) linear infinite;
    will-change: stroke-dasharray, stroke-dashoffset;
    transition: stroke 0.5s ease;
  }

  .p-spinner-track {
    stroke: var(--uib-color);
    opacity: var(--uib-bg-opacity);
    transition: stroke 0.5s ease;
  }

  @keyframes travel {
    0% {
      stroke-dashoffset: 0;
    }

    100% {
      stroke-dashoffset: -100;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/style.css"],"names":[],"mappings":"AAAA;EACE,6DAA6D;EAC7D,SAAS;EACT,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,6DAA6D;EAC7D,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,aAAa;AACf;;;EAGE;IACE,gBAAgB;IAChB,oBAAoB;IACpB,iBAAiB;IACjB,oBAAoB;IACpB,uBAAuB;IACvB,sBAAsB;IACtB,wBAAwB;IACxB,iBAAiB;EACnB;;EAEA;IACE,UAAU;IACV,wBAAwB;IACxB,wBAAwB;IACxB,oBAAoB;IACpB,qBAAqB;IACrB,kDAAkD;IAClD,gDAAgD;IAChD,4BAA4B;EAC9B;;EAEA;IACE,wBAAwB;IACxB,8BAA8B;IAC9B,4BAA4B;EAC9B;;EAEA;IACE;MACE,oBAAoB;IACtB;;IAEA;MACE,uBAAuB;IACzB;EACF","sourcesContent":[".map-window .btn-primary {\n  background-image: linear-gradient(to right, #c43cab, #f87e4b);\n  border: 0;\n  border-radius: 26px;\n  font-size: 12px;\n}\n\n.map-window {\n  padding: 0 0.5rem;\n}\n.lesson-count {\n  background-image: linear-gradient(to right, #D091FF, #FFD99F);\n  padding: 4px;\n  border-radius: 3px;\n  font-weight: 600;\n}\nbutton.gm-ui-hover-effect {\n  display: none !important;\n}\ndiv#\\39 64D6C5A-1BF1-4822-98E2-1C23D9A6B59D {\n  display: none;\n}\n\n\n  .p-spinner {\n    --uib-size: 30px;\n    --uib-color: #c43cab;\n    --uib-speed: 1.4s;\n    --uib-bg-opacity: .5;\n    height: var(--uib-size);\n    width: var(--uib-size);\n    transform-origin: center;\n    overflow: visible;\n  }\n\n  .p-spinner-car {\n    fill: none;\n    stroke: var(--uib-color);\n    stroke-dasharray: 15, 85;\n    stroke-dashoffset: 0;\n    stroke-linecap: round;\n    animation: travel var(--uib-speed) linear infinite;\n    will-change: stroke-dasharray, stroke-dashoffset;\n    transition: stroke 0.5s ease;\n  }\n\n  .p-spinner-track {\n    stroke: var(--uib-color);\n    opacity: var(--uib-bg-opacity);\n    transition: stroke 0.5s ease;\n  }\n\n  @keyframes travel {\n    0% {\n      stroke-dashoffset: 0;\n    }\n\n    100% {\n      stroke-dashoffset: -100;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
