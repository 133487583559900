import React from 'react'
import P_Spinner from './P_Spinner'

export default function Loading() {
  return (
    <div  className='d-flex align-items-center gap-2'>
        <P_Spinner/>
        <span>Chargement en cours... </span>

    </div>
  )
}
