import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import io from "socket.io-client";
import axiosInstance from "../utils/http/config/axios";
import { formatDistanceToNow } from "date-fns";
import { fr } from "date-fns/locale";

import { getLocalStream } from "../utils/getUserMedia";
import { createPeerConnection } from "../utils/peerConnection";

import "./style.css";
import avatar from "../images/avatar.png";
import message from "../images/message.png";

import MessageList from "./MessageList";
import MessageInput from "./MessageInput";
import AudioCall, { IconButton } from "./AudioCall/AudioCall";
import VideoCall from "./VideoCall/VideoCall";
import GetCallPopUp from "./GetCallPopUp";

import { IoIosCall } from "react-icons/io";
import { AiFillMessage } from "react-icons/ai";
import { MdCallMade, MdCallReceived, MdOutlineVideocam } from "react-icons/md";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

/**
 * Un composant pour l'historique d'appels (juste un exemple).
 */
function CallListItem({ profil, name, date, status, flux, nature }) {
  return (
    <div className="callListItem">
      <img src={profil} alt="profil" className="callListItemProfil" />
      <div className="callItemInfos">
        <div className="callItemDateName">
          <h6
            className="callItemName"
            style={{ color: status === "missed" ? "red" : "black" }}
          >
            {name}
          </h6>
          <p className="callItemDate">
            <span style={{ color: status === "missed" ? "red" : "green" }}>
              {flux === "entrant" && <MdCallMade />}
              {flux === "sortant" && <MdCallReceived />}
            </span>
            <span style={{ color: status === "missed" ? "red" : "black" }}>
              {date}
            </span>
          </p>
        </div>
        <div className="callItemNature">
          {nature === "video" ? <MdOutlineVideocam /> : <IoIosCall />}
        </div>
      </div>
    </div>
  );
}

function Message() {
  // Rechercher des contacts
  const [searchQuery, setSearchQuery] = useState("");
  const [combinedList, setCombinedList] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [messages, setMessages] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);

  const [showMessageArea, setShowMessageArea] = useState(false);
  const [showMediaMenu, setShowMediaMenu] = useState(false);
  const [showInformation, setShowInformations] = useState(false);
  const [showMessageList, setShowMessageList] = useState(true);

  // Pour gérer la liste de statuts en ligne
  const [userStatuses, setUserStatuses] = useState({});

  // URL du backend socket
  const backendUrlsocket = process.env.REACT_APP_BACKEND_URL;
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const baseUrl = process.env.REACT_APP_BACKEND_URL;

  // Gérer la popup d'appel
  const [callState, setCallState] = useState({
    status: undefined,
    text: "En train de sonner...",
    popup: {
      show: false,
      text: "",
    },
    caller: null,
    callerSignal: null,
    receivingCall: false,
    isInCall: false,
    isInitiator: false,
    callAccepted: false,
    time: { hours: 0, minutes: 0, seconds: 0 },
    receiverTime: { hours: 0, minutes: 0, seconds: 0 },
    showMediaMenu: false,
    showInformation: false,
    isMuted: false,
    isVideoOff: false,
    peerMuted: false,
    callEnded: false,
    currentUserInfo: null,
    signal: null // pour stocker la signal data
  });

  // Autres états pour l'appel
  const [callStatus, setCallStatus] = useState(undefined);
  const [callText, setCallText] = useState("En train de sonner...");
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState("");
  const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 });

  // Pour stocker l'offre entrante
  const [incomingOffer, setIncomingOffer] = useState(null);
  const [callerUserId, setCallerUserId] = useState(null);
  const [isVideoOffer, setIsVideoOffer] = useState(true);

  // Gestion des flux médias
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const peerConnectionRef = useRef(null);

  // Socket
  const socketRef = useRef(null);
  const userId = localStorage.getItem("userId");

  // -----------------------------------------------------------------------------
  // 1) Créer la connexion socket
  useEffect(() => {
    console.log("[CLIENT] Création de la connexion Socket.io...");
    socketRef.current = io("wss://app.gmp-auto-ecole.com", {
      path: "/socket.io",
      transports: ["websocket"],
      timeout: 10000,
      secure: true,
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      autoConnect: true,
      forceNew: true,
      withCredentials: true,
      rejectUnauthorized: false,
    });

    console.log("[CLIENT] Emitting register => userId:", userId);
    socketRef.current.emit("register", userId);

    socketRef.current.on("registered", () => {
      console.log("[CLIENT] On est enregistré sur le serveur, userId:", userId);
    });

    // Recevoir l'offre
    socketRef.current.on("offer", ({ offer, fromUserId, isVideo }) => {
      console.log("[CLIENT] Reçu offer => fromUserId:", fromUserId, ", isVideo:", isVideo);
      setIncomingOffer(offer);
      setCallerUserId(fromUserId);
      setIsVideoOffer(isVideo);

      setPopupText("Appel entrant ...");
      setShowPopup(true);
    });

    // Recevoir l'answer
    socketRef.current.on("answer", async ({ answer, fromUserId }) => {
      console.log("[CLIENT] Reçu answer => from userId:", fromUserId);
      const pc = peerConnectionRef.current;
      if (!pc) {
        console.error("[CLIENT] Pas de peerConnection lors de la réception de l'answer");
        return;
      }
      try {
        await pc.setRemoteDescription(new RTCSessionDescription(answer));
        console.log("[CLIENT] setRemoteDescription(answer) réussi");
        console.log("--------[WebRTC]--------- Remote SDP:", pc.remoteDescription.sdp);
      } catch (err) {
        console.error("[CLIENT] Erreur setRemoteDescription:", err);
      }
    });

    // Recevoir un ice-candidate
    socketRef.current.on("ice-candidate", async ({ candidate, fromUserId }) => {
      console.log("[CLIENT] Reçu ICE candidate =>", candidate);
      const pc = peerConnectionRef.current;
      if (pc) {
        try {
          await pc.addIceCandidate(new RTCIceCandidate(candidate));
          console.log("[CLIENT] Successfully added ICE candidate from:", fromUserId);
        } catch (err) {
          console.error("[CLIENT] Erreur addIceCandidate =>", err);
        }
      }
    });

    // Recevoir un nouveau message
    socketRef.current.on("newMessage", (msg) => {
      console.log("[CLIENT] newMessage =>", msg);
      if (
        msg.senderId === selectedContact?.id ||
        msg.receiverId === selectedContact?.id
      ) {
        setMessages((prev) => [...prev, msg]);
      }
    });

    // Gérer les erreurs de connexion
    let reconnectAttempts = 0;
    const maxReconnectAttempts = 5;
    socketRef.current.on("connect_error", (err) => {
      console.error("[CLIENT] connect_error =>", err);
      if (reconnectAttempts < maxReconnectAttempts) {
        reconnectAttempts++;
        setTimeout(() => {
          socketRef.current.connect();
        }, 2000);
      }
    });

    return () => {
      console.log("[CLIENT] Déconnexion socket...");
      socketRef.current.disconnect();
    };
  }, [userId, selectedContact]);

  // -----------------------------------------------------------------------------
  // 2) Récupérer la liste des contacts
  const fetchContacts = async () => {
    if (!userId) {
      console.error("[CLIENT] userId indisponible => impossible de fetchContacts");
      return;
    }
    try {
      const response = await axiosInstance.get(`/api/messages/fetchContacts/${userId}`);
      console.log("[CLIENT] fetchContacts =>", response.data);
      setContacts(response.data);
      setCombinedList(response.data);
    } catch (error) {
      console.error("[CLIENT] Erreur fetchContacts =>", error);
    }
  };

  useEffect(() => {
    fetchContacts();
  }, [userId]);

  // -----------------------------------------------------------------------------
  // 3) Filtrer PDF, Images, Liens
  const filterPdfFiles = (messages) =>
    messages.filter((message) => message.fileUrl && message.fileUrl.endsWith(".pdf"));

  function isValidUrl(string) {
    const pattern = new RegExp(
      "^(https?:\\/\\/(www\\.)?)?[a-zA-Z0-9-]+(\\.[a-zA-Z]{2,})+(\\/\\S*)?$",
      "i"
    );
    return pattern.test(string);
  }
  const validLinks = messages.filter((message) => isValidUrl(message.content));

  const filterMediaFiles = (messages) => {
    return messages.filter((message) =>
      message.fileUrl &&
      (message.fileUrl.endsWith(".jpg") ||
        message.fileUrl.endsWith(".png") ||
        message.fileUrl.endsWith(".jpeg"))
    );
  };

  // -----------------------------------------------------------------------------
  // 4) Recherche
  useEffect(() => {
    const fetchInstructors = async () => {
      try {
        const response = await axiosInstance.get("/api/instructors/search", {
          params: { query: searchQuery },
        });
        console.log("[CLIENT] fetchInstructors =>", response.data);

        // Transforme la réponse pour la liste
        const transformedInstructors = response.data.map((inst) => ({
          id: inst.id,
          senderId: inst.user.id,
          receiverId: parseInt(userId),
          content: "",
          createdAt: inst.createdAt,
          sender: {
            id: inst.user.id,
            firstName: inst.user.firstName,
            lastName: inst.user.lastName,
            profilePicture: inst.user.profilePicture || "default.png",
          },
          receiver: {
            id: parseInt(userId),
            firstName: "",
            lastName: "",
            profilePicture: "",
          },
        }));

        const localFiltered = contacts?.filter((c) =>
          `${c.sender.firstName} ${c.sender.lastName}`
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        );

        const merged = [
          ...localFiltered,
          ...transformedInstructors.filter(
            (inst) => !contacts.some((c) => c.senderId === inst.senderId)
          ),
        ];

        setCombinedList(merged);
      } catch (err) {
        console.error("[CLIENT] Erreur fetchInstructors =>", err);
      }
    };

    if (searchQuery.trim()) {
      fetchInstructors();
    } else {
      setCombinedList(contacts);
    }
  }, [searchQuery, contacts, userId]);

  // -----------------------------------------------------------------------------
  // 5) Rejoindre la room
  useEffect(() => {
    if (!socketRef.current || !selectedContact) return;

    console.log("[CLIENT] Emitting (joinRoom) =>", {
      senderId: userId,
      receiverId: selectedContact.id
    });
    socketRef.current.emit("joinRoom", {
      senderId: userId,
      receiverId: selectedContact.id,
    });

    fetchMessages(selectedContact.id);
  }, [userId, selectedContact]);

  // -----------------------------------------------------------------------------
  // 6) Récupérer les messages
  const fetchMessages = async (contactId) => {
    try {
      const response = await axiosInstance.get(`/api/messages/fetch/${contactId}`);
      console.log("[CLIENT] fetchMessages => contactId:", contactId, "=>", response.data);
      setMessages(response.data);
    } catch (error) {
      console.error("[CLIENT] Erreur fetchMessages =>", error);
    }
  };

  const handleContactClick = (contact) => {
    if (!contact || !contact.id) {
      console.error("[CLIENT] handleContactClick => contact invalide =>", contact);
      return;
    }
    setSelectedContact(contact);
    fetchMessages(contact.id);
    setShowMessageArea(true);
  };

  // -----------------------------------------------------------------------------
  // 7) Apparition / Disparition de menus
  const handleToggle = () => setShowMediaMenu(!showMediaMenu);
  const handleToggle2 = () => setShowInformations(!showInformation);
  const toggleMenu = () => setShowInformations(!showInformation);

  // -----------------------------------------------------------------------------
  // 8) Timer d'appel
  useEffect(() => {
    let interval;
    if (callStatus && callStatus !== "incomingCall") {
      interval = setInterval(() => {
        setTime((prevTime) => ({
          hours: prevTime.hours,
          minutes: prevTime.minutes,
          seconds: prevTime.seconds + 1
        }));
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [callStatus]);

  // -----------------------------------------------------------------------------
  // 9) initier un appel (Caller)
  const initiateCall = async (toUserId, isVideo = true) => {
    console.log("[CLIENT] initiateCall => from userId:", userId, ", to userId:", toUserId, ", isVideo:", isVideo);
    setCallerUserId(userId);

    try {
      const stream = await getLocalStream(isVideo, true);
      setLocalStream(stream);
      console.log("[CLIENT] localStream =>", stream);

      // Créer le PC via createPeerConnection (asynchrone)
      const peerConnection = await createPeerConnection(
        socketRef.current,
        userId,
        toUserId,
        (rStream) => {
          console.log("[CLIENT] onTrack => flux distant =>", rStream);
          setRemoteStream(rStream);
        },
        (candidate) => {
          console.log("[CLIENT] Local ICE Candidate =>", candidate);
        }
      );
      peerConnectionRef.current = peerConnection;

      // Ajouter les tracks
      stream.getTracks().forEach((t) => peerConnection.addTrack(t, stream));

      // Créer l'offer
      const offer = await peerConnection.createOffer();
      console.log("[CLIENT] createOffer =>", offer);
      await peerConnection.setLocalDescription(offer);

      // Envoyer l'offer à l'autre
      socketRef.current.emit("offer", {
        offer,
        to: toUserId,
        fromUserId: userId,
        isVideo
      });
      console.log("[CLIENT] Emitted offer => userId:", toUserId);

      setCallStatus(isVideo ? "videoCall" : "voiceCall");
      setCallText("Appel en cours...");
    } catch (err) {
      console.error("[CLIENT] Erreur initiateCall =>", err);
    }
  };

  // 10) Accepter / Refuser l'appel entrant
  const handleAcceptCall = async () => {
    console.log("[CLIENT] handleAcceptCall => fromUserId:", callerUserId);

    const newStatus = isVideoOffer ? "videoCall" : "voiceCall";
    setCallStatus(newStatus);
    setCallText("Appel en cours...");
    setShowPopup(false);

    if (!incomingOffer || !callerUserId) {
      console.error("[CLIENT] Pas d'incomingOffer ou callerUserId => impossible d'accepter");
      return;
    }

    try {
      const stream = await getLocalStream(isVideoOffer, true);
      setLocalStream(stream);

      const pc = await createPeerConnection(
        socketRef.current,
        userId,
        callerUserId,
        (rStream) => setRemoteStream(rStream),
        (candidate) => console.log("[CLIENT] Local ICE Candidate =>", candidate)
      );
      peerConnectionRef.current = pc;

      stream.getTracks().forEach((track) => pc.addTrack(track, stream));
      await pc.setRemoteDescription(new RTCSessionDescription(incomingOffer));
      console.log("-------[WebRTC]-------- Remote SDP:", pc.remoteDescription.sdp);
      const answer = await pc.createAnswer();
      await pc.setLocalDescription(answer);
      console.log('-------[WebRTC]-------- Local SDP:', pc.localDescription.sdp);

      socketRef.current.emit("answer", {
        answer,
        to: callerUserId,
        fromUserId: userId,
      });

      // Nettoyage
      setIncomingOffer(null);
      setCallerUserId(null);
    } catch (err) {
      console.error("[CLIENT] Erreur handleAcceptCall =>", err);
    }
  };

  const handleDenyCall = () => {
    console.log("[CLIENT] handleDenyCall => refusé l'appel de =>", callerUserId);

    setCallStatus(undefined);
    setCallText("Appel refusé !");
    setShowPopup(false);
    setTime({ hours: 0, minutes: 0, seconds: 0 });

    setIncomingOffer(null);
    setCallerUserId(null);
  };

  // -----------------------------------------------------------------------------
  // 11) Raccrocher
  const endCall = () => {
    console.log("[CLIENT] endCall => cleaning up call resources");

    // 1. Reset all call-related states
    setCallStatus(undefined);
    setCallText("Appel terminé !");
    setTime({ hours: 0, minutes: 0, seconds: 0 });
    setIncomingOffer(null);
    setCallerUserId(null);
    setIsVideoOffer(true);

    // 2. Clean up peer connection
    if (peerConnectionRef.current) {
      try {
        // Remove all event listeners
        peerConnectionRef.current.ontrack = null;
        peerConnectionRef.current.onicecandidate = null;
        peerConnectionRef.current.oniceconnectionstatechange = null;
        peerConnectionRef.current.onconnectionstatechange = null;
        
        // Close and remove all transceivers
        peerConnectionRef.current.getTransceivers().forEach(transceiver => {
          try {
            transceiver.stop();
          } catch (err) {
            console.error("[CLIENT] Error stopping transceiver:", err);
          }
        });

        // Close the connection
        peerConnectionRef.current.close();
      } catch (err) {
        console.error("[CLIENT] Error cleaning up peer connection:", err);
      } finally {
        peerConnectionRef.current = null;
      }
    }

    // 3. Clean up media streams
    if (localStream) {
      try {
        localStream.getTracks().forEach(track => {
          track.stop();
          localStream.removeTrack(track);
        });
        setLocalStream(null);
      } catch (err) {
        console.error("[CLIENT] Error cleaning up local stream:", err);
      }
    }

    if (remoteStream) {
      try {
        remoteStream.getTracks().forEach(track => {
          track.stop();
          remoteStream.removeTrack(track);
        });
        setRemoteStream(null);
      } catch (err) {
        console.error("[CLIENT] Error cleaning up remote stream:", err);
      }
    }

    // 4. Reset call text after delay
    setTimeout(() => {
      setCallText("En train de sonner...");
    }, 1500);
  };

  // -----------------------------------------------------------------------------
  // 12) Suivi du statut des utilisateurs (online/offline)
  useEffect(() => {
    const socket = io(backendUrlsocket, {
      path: "/socket.io",
      transports: ["websocket"],
      timeout: 10000
    });

    socket.emit("user-online", userId);

    socket.on("update-user-status", (data) => {
      setUserStatuses((prevStatuses) => ({
        ...prevStatuses,
        [data.userId]: data.status
      }));
    });

    return () => {
      socket.off("update-user-status");
      socket.disconnect();
    };
  }, [userId]);

  const updateContactStatus = (contact) => {
    const status = userStatuses[contact.senderId] || contact.sender?.status;
    return (
      <div
        className="status-indicator"
        style={{ backgroundColor: status === "online" ? "green" : "gray" }}
      />
    );
  };

  // -----------------------------------------------------------------------------
  // Rendu du composant
  return (
    <>
      {/* Popup pour appel entrant */}
      {showPopup && (
        <GetCallPopUp
          popupText={popupText}
          handleAcceptCall={handleAcceptCall}
          handleDenyCall={handleDenyCall}
        />
      )}

      {(callStatus === undefined ||
        callStatus === "inSmallView" ||
        callStatus === "inMessageMode" ||
        callStatus === "inSmallViewVideo" ||
        callStatus === "inMessageModeVideo") && (
        <section className="Message-chat">
          <div className="container">
            <div className="row">
              {/* Sidebar contacts */}
              <div className="col-lg-3">
                <div className="users_list">
                  <div className="search_bar">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path d="M16.3131 15.5172L12.0651 11.2692C13.0859 10.0437 13.595 8.47182 13.4863 6.88054C13.3777 5.28927 12.6598 3.80113 11.4819 2.7257C10.304 1.65027 8.75688 1.07035 7.16231 1.10658C5.56774 1.14281 4.04853 1.79241 2.92071 2.92022C1.79289 4.04804 1.1433 5.56726 1.10707 7.16182C1.07084 8.75639 1.65076 10.3035 2.72619 11.4814C3.80162 12.6593 5.28976 13.3772 6.88103 13.4858C8.4723 13.5945 10.0442 13.0854 11.2697 12.0646L15.5177 16.3126L16.3131 15.5172ZM2.25058 7.3126C2.25058 6.31133 2.54749 5.33255 3.10377 4.50002C3.66004 3.6675 4.4507 3.01862 5.37575 2.63546C6.3008 2.25229 7.3187 2.15203 8.30073 2.34737C9.28276 2.54271 10.1848 3.02486 10.8928 3.73287C11.6008 4.44087 12.083 5.34292 12.2783 6.32495C12.4736 7.30698 12.3734 8.32488 11.9902 9.24993C11.6071 10.175 10.9582 10.9656 10.1257 11.5219C9.29313 12.0782 8.31435 12.3751 7.31308 12.3751C5.97088 12.3736 4.68408 11.8398 3.735 10.8907C2.78592 9.9416 2.25207 8.6548 2.25058 7.3126Z" />
                    </svg>
                    <Form.Control
                      type="text"
                      placeholder="Rechercher..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    {showMessageList ? (
                      <IconButton
                        icon={<IoIosCall />}
                        label={"Historique des appels"}
                        onClick={() => setShowMessageList(false)}
                        style={{
                          background: "linear-gradient(to right, #C43CAB, #F87E4B)",
                        }}
                      />
                    ) : (
                      <IconButton
                        icon={<AiFillMessage />}
                        label={"Historique des messages"}
                        onClick={() => setShowMessageList(true)}
                        style={{
                          background: "linear-gradient(to right, #C43CAB, #F87E4B)",
                        }}
                      />
                    )}
                  </div>
                  <div>
                    {showMessageList ? (
                      combinedList
                        .filter((c) => c.senderId !== parseInt(userId))
                        .map((c) => (
                          <div
                            className="message_list"
                            key={c.id}
                            onClick={() => handleContactClick(c.sender)}
                          >
                            <div className="cover_msg">
                              <img
                                src={c.sender?.profilePicture || avatar}
                                alt="user"
                                onError={(e) => {
                                  e.target.src = avatar;
                                }}
                              />
                              <div className="name_user ms-2">
                                <h5>
                                  {`${c.sender?.firstName} ${c.sender?.lastName}`}
                                </h5>
                                <p className="mb-0">{c.content}</p>
                              </div>
                            </div>
                            <span className="time-when">
                              {formatDistanceToNow(new Date(c.createdAt), {
                                addSuffix: true,
                                locale: fr,
                              })}
                            </span>
                            {updateContactStatus(c)}
                          </div>
                        ))
                    ) : (
                      <div className="callListItems">
                        <CallListItem
                          profil={avatar}
                          name={"Marcus Green"}
                          status={"missed"}
                          flux={"entrant"}
                          nature={"video"}
                          date={"Hier, 22:07"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Main Chat */}
              <div className="col-lg-9">
                <div className="row top-msghead">
                  <div className={showInformation ? "col-lg-7" : "col-lg-12"}>
                    {showMessageArea ? (
                      <div>
                        <div className="d-flex justify-content-between">
                          <div className="user-details">
                            <div className="user_pic">
                              <img
                                src={selectedContact?.profilePicture || avatar}
                                alt="contact"
                                onError={(e) => {
                                  e.target.src = avatar;
                                }}
                              />
                            </div>
                            <div className="ms-3">
                              <h5 className="mb-0 mt-2">
                                {selectedContact?.firstName} {selectedContact?.lastName}
                              </h5>
                            </div>
                          </div>

                          <div>
                            <div
                              className="student-menu-vertical"
                              onClick={handleToggle}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 -960 960 960"
                                width="24px"
                                fill="#5f6368"
                              >
                                <path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z" />
                              </svg>
                              {showMediaMenu && (
                                <div className="infos-menu">
                                  <p onClick={handleToggle2}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height={"24px"}
                                      viewBox="0 0 512 512"
                                    >
                                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336l24 0 0-64-24 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 88 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                                    </svg>
                                    Infos sur Contact
                                  </p>
                                  <p onClick={() => initiateCall(selectedContact.id, false)}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height={"24px"}
                                      viewBox="0 0 576 512"
                                    >
                                      <path d="M559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2l0 256c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1l0-17.1 0-128 0-17.1 14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z" />
                                    </svg>
                                    Appel vocal
                                  </p>
                                  <p onClick={() => initiateCall(selectedContact.id, true)}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height={"24px"}
                                      viewBox="0 0 512 512"
                                    >
                                      <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                                    </svg>
                                    Appel vidéo
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <hr />
                        <div className="chat-area">
                          <MessageList messages={messages} userId={userId} />
                        </div>
                        <MessageInput
                          socket={socketRef.current}
                          userId={userId}
                          receiver={selectedContact.id}
                        />
                      </div>
                    ) : (
                      <div className="student-message-container-img">
                        <img src={message} alt="no-msg" />
                        <p>Commencez une conversation maintenant !</p>
                      </div>
                    )}
                  </div>

                  {showInformation && (
                    <div className={showInformation ? "col-lg-5" : ""}>
                      <button onClick={toggleMenu} className="close-button">
                        ×
                      </button>
                      <div className="user_details">
                        <img
                          src={selectedContact.profilePicture || avatar}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = avatar;
                          }}
                        />
                        <h4>
                          {selectedContact?.firstName} {selectedContact?.lastName}
                        </h4>
                        <p className="mb-0">Moniteur d'auto-école</p>
                        <strong className="phn_nomb">
                          {selectedContact?.countryCode} {selectedContact?.phoneNumber}
                        </strong>
                      </div>

                      <div className="aboutmedia_shared">
                        <Tabs
                          defaultActiveKey="home"
                          id="uncontrolled-tab-example"
                          className="mb-3"
                        >
                          <Tab eventKey="home" title="Déposer">
                            {filterPdfFiles(messages).length > 0 ? (
                              filterPdfFiles(messages).map((msg, index) => (
                                <div key={index} className="file px-3">
                                  <div className="d-flex">
                                    <svg
                                      width="21"
                                      height="25"
                                      viewBox="0 0 21 25"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M16.3131 0.416504L10.642 0.424962C10.9117 0.45677 11.1629 0.578516 11.3549 0.770559C11.547 0.962602 11.6687 1.21374 11.7005 1.48346L11.709 1.62484V6.45817L11.715 6.63942C11.7583 7.2148 12.0059 7.75571 12.4133 8.16437C12.8206 8.57304 13.3607 8.82247 13.9359 8.86759L14.1256 8.87484H18.959L19.1004 8.8833C19.3701 8.9151 19.6212 9.03685 19.8133 9.22889C20.0053 9.42094 20.127 9.67208 20.1589 9.9418L20.1673 10.0832V20.9582C20.1674 21.8828 19.8141 22.7725 19.1798 23.4452C18.5454 24.118 17.678 24.5229 16.755 24.5771L16.5423 24.5832H4.45898C3.53435 24.5832 2.64465 24.2299 1.97192 23.5956C1.29918 22.9613 0.894271 22.0939 0.840026 21.1708L0.833984 20.9582V4.0415C0.833933 3.11687 1.18721 2.22717 1.82154 1.55444C2.45586 0.881704 3.32328 0.476791 4.24632 0.422546L4.45898 0.416504H10.5006Z"
                                        fill="#CACACA"
                                      />
                                      <path
                                        d="M18.9595 6.45808H14.1262L14.125 1.62354L18.9595 6.45808Z"
                                        fill="#CACACA"
                                      />
                                    </svg>
                                    <div className="ms-2">
                                      <a
                                        href={`/api/messages/download/${msg.fileUrl
                                          .split("/")
                                          .pop()}`}
                                        download
                                        style={{ textDecoration: "none", color: "inherit" }}
                                      >
                                        <strong>
                                          {msg.fileUrl
                                            .split("/")
                                            .pop()
                                            .split("-")
                                            .slice(-1)
                                            .join("-")}
                                        </strong>
                                      </a>
                                      <p className="mb-0">
                                        {new Date("2025-01-09T12:34:56Z").toLocaleDateString()}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <p>Aucun fichier PDF disponible.</p>
                            )}
                          </Tab>
                          <Tab eventKey="media" title="Médias">
                            {filterMediaFiles(messages).length > 0 ? (
                              filterMediaFiles(messages).map((msg, index) => (
                                <div key={index} className="media-file">
                                  <img
                                    src={`${msg.fileUrl}`}
                                    alt={`Media ${index}`}
                                    className="media-image"
                                  />
                                  <p>{new Date(msg.createdAt).toLocaleDateString()}</p>
                                </div>
                              ))
                            ) : (
                              <p>Aucun média disponible.</p>
                            )}
                          </Tab>
                          <Tab eventKey="liens" title="Liens">
                            {validLinks.length > 0 ? (
                              validLinks.map((msg, index) => (
                                <div key={index}>
                                  <a
                                    href={msg.content}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="link-message"
                                  >
                                    {msg.content}
                                  </a>
                                </div>
                              ))
                            ) : (
                              <p>Aucun lien disponible.</p>
                            )}
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {(callStatus === "voiceCall" ||
        callStatus === "inSmallView" ||
        callStatus === "inMessageMode") && (
        <AudioCall
          setPopupText={(text) => setPopupText(text)}
          setShowPopup={(show) => setShowPopup(show)}
          callText={callText}
          setCallText={(text) => setCallText(text)}
          setCallStatus={(status) => setCallStatus(status)}
          callStatus={callStatus}
          time={time}
          setTime={(time) => setTime(time)}
          localStream={localStream}
          remoteStream={remoteStream}
          endCall={endCall}
        />
      )}

      {(callStatus === "videoCall" ||
        callStatus === "inSmallViewVideo" ||
        callStatus === "inMessageModeVideo") && (
        <VideoCall
          setPopupText={(text) => setPopupText(text)}
          setShowPopup={(show) => setShowPopup(show)}
          callText={callText}
          setCallText={(text) => setCallText(text)}
          setCallStatus={(status) => setCallStatus(status)}
          callStatus={callStatus}
          time={time}
          setTime={(time) => setTime(time)}
          localStream={localStream}
          remoteStream={remoteStream}
          endCall={endCall}
        />
      )}
    </>
  );
}

export default Message;
