import React from 'react'
import  "../style.css"
import check_external from "../../images/check-external.png"
import { useNavigate } from 'react-router-dom';

function Check_external() {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1); // Navigate back to the previous page
    };
  return (
   <section className='check-external'>
       <div className=''>
       <div className='course-header'>
        <h4>Préparez-vous aux questions de l'examen</h4>
        <div className='back-btn'>
          <button type="button" onClick={goBack} class="btn btn-secondary btn-main"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8822 9.06814C16.8852 9.29314 16.7987 9.51012 16.6418 9.67135C16.4848 9.83258 16.2702 9.92484 16.0452 9.92786L4.5188 10.0821L7.6367 13.1177C7.78871 13.2765 7.87314 13.4881 7.8722 13.708C7.87127 13.9278 7.78504 14.1387 7.63169 14.2962C7.47833 14.4537 7.26983 14.5455 7.0501 14.5524C6.83036 14.5592 6.61656 14.4805 6.45374 14.3328L1.86858 9.86872C1.70758 9.71178 1.61545 9.49735 1.61244 9.27253C1.60944 9.04772 1.69579 8.8309 1.85253 8.66971L6.31656 4.08455C6.47537 3.93254 6.68699 3.84811 6.90682 3.84904C7.12666 3.84998 7.33755 3.93621 7.49507 4.08956C7.65258 4.24292 7.74442 4.45142 7.75124 4.67116C7.75806 4.89089 7.67933 5.10469 7.53163 5.26751L4.49608 8.38542L16.0225 8.23113C16.2475 8.22812 16.4645 8.31461 16.6257 8.47158C16.7869 8.62855 16.8792 8.84314 16.8822 9.06814Z" fill="white"/>
          </svg>
          Retour</button>
        </div>
        </div>
        <div className='text-wraps'>
        <div className='top-title'>
                    <img src={check_external} />
                    <h4>Contrôles externes</h4>
                </div>
                <p>Depuis le 1er janvier 2018, les deux questions de vérifications prévues à l’examen pratique ont été complétées par une troisième question concernant « Les premiers secours ». Ainsi, ces 3 nouvelles questions seront liées et complémentaires : Une question de vérification intérieure ou extérieure, une question de sécurité routière et une question de premiers secours. Les trois thèmes spécifiques aux questions sont les suivants :

</p>
<ul>
    <li>les vérifications intérieures</li>
    <li>les vérifications extérieures</li>
    <li>les premiers secours</li>
</ul>
<p>A chaque question de vérification est liée une question de sécurité routière. Chaque question rapportera 1 point au candidat. L’objectif principal de ces vérifications est de sensibiliser le candidat sur les dangers de la route, lui donner les bons comportements et réflexes à adopter pour réduire l’accidentalité sur les routes.</p>
<hr className='divide-line'></hr>
<div className='questions'>
    <div>
    <h5>1. Contrôlez l'état et la propreté de tous les rétroviseurs extérieurs du véhicule. Qu'appelle-t-on un "angle mort" ?</h5>
    <p>Regardez les 2 rétroviseurs extérieurs et constatez leur état en l'expliquant à l'inspecteur. Normalement, surtout le jour de l'examen, les rétroviseurs doivent être en bon état et propres. L'angle mort est une zone sans visibilité depuis le poste de conduite.

</p>              
    </div>
    <div>
    <h5>2. Montrez l'orifice de remplissage du produit lave-glace. Pourquoi est-il préférable d'utiliser un liquide spécial en hiver ?</h5>
    <p>Coupez le moteur et pensez à mettre le frein à main. Ouvrez votre portière, laissez là ouverte pour tirez la commande d'ouverture du capot, vous pourrez ensuite fermer la portière. Passez votre main dans l'ouverture pour décrocher la sécurité du capot, sinon baissez-vous pour regarder où elle se trouve. Bloquez le capot avec la tirette. Pour refermer, bloquez la tirette de nouveau dans le capot. Baissez le capot à 5 cm environ puis lâchez d'un coup. Ouvrez le capot et montrez l'orifice qui a comme symbole le lave-glace avant. Il est préférable d'utiliser un liquide spécial en hiver pour que le liquide ne gèle pas.

</p>              
    </div>
    <div>
    <h5>3. Contrôlez le flanc extérieur sur l'un des pneumatiques arrière. A l'aide de la notice d'utilisation ou de la plaque indicative située sur le véhicule, indiquez les pressions préconisées pour ce véhicule.</h5>
    <p>Vérifiez que le flanc ne comporte ni hernie (gonflement), ni déchirure. Voir la plaque collée à l'intérieur de la portière conducteur.

</p>              
    </div>
    <div>
    <h5>4. Contrôlez l'état, la propreté, et le fonctionnement des clignotants droit, et des répétiteurs latéraux. Quelle est la signification de l'augmentation de la fréquence de clignotement au niveau du feu et du voyant au tableau de bord ?</h5>
    <p>Mettez le contact, actionnez les clignotants droits. Ensuite allez vérifier les feux correspondants, c'est à dire devant, derrière et au milieu pour les répétiteurs latéraux s'il y en a, du côté droit de la voiture. Indiquez s'ils sont propres, en bon état et s'ils fonctionnent pour chaque feu. La signification de l'augmentation de la fréquence de clignotement indique que l'un des indicateurs de changement de direction ou l'un des clignotants est défectueux.
</p>              
    </div>
    <div>
    <h5>5. Montrez l'endroit où doit s'effectuer le contrôle du niveau du liquide d'assistance de direction.</h5>
    <p>Ouvrez le capot et montrez l'emplacement de contrôle du niveau du liquide d'assistance de direction. La principale conséquence d'un manque de liquide d'assistance de direction est que le volant devient difficile à tourner.
</p>              
    </div>
    <div>
    <h5>6. Contrôlez l'état, la propreté, et le fonctionnement des clignotants droit, et des répétiteurs latéraux. Quand les utilise-t-on ?</h5>
    <p>Mettez le contact, actionnez les clignotants droits. Ensuite allez vérifier les feux correspondants, c'est à dire devant, derrière et au milieu pour les répétiteurs latéraux s'il y en a, du côté droit de la voiture. Indiquez s'ils sont propres, en bon état et s'ils fonctionnent, pour chaque feu. On utilise les clignotants pour avertir lors d'un changement de direction, d'un arrêt ou d'un départ.
</p>              
    </div>
    <div>
    <h5>7. Montrez où se trouve la batterie du véhicule. Qu'est-ce qui peut provoquer la décharge d'une batterie ?</h5>
    <p>Ouvrez le capot et montrez l'emplacement de la batterie. Ce qui peut provoquer la décharge de la batterie est : le fonctionnement des feux, les accessoires électriques ou de l'autoradio.
</p>              
    </div>
    <div>
    <h5>8. Contrôlez l'état, la propreté, et le fonctionnement des feux de stop (avec l'assistance de l'accompagnateur). Quelle est la conséquence en cas de panne des feux de stop?</h5>
    <p>Mettez le contact. Demandez à votre accompagnateur de venir appuyer sur la pédale des feux de stop (frein). Ensuite allez vérifier les feux correspondants, c'est à dire à l'arrière de la voiture. N'oubliez pas celui qui est sur le coffre. Indiquez s'ils sont propres, en bon état et s'ils fonctionnent pour chaque feu. Les conséquences en cas de panne de feu de stop est l'absence d'information pour le véhicule suiveur avec risque de collision par l'arrière.
</p>              
    </div>
    <div>
    <h5>9. Montrez l'orifice de remplissage de l'huile moteur. Avec quel fluide complétez-vous le niveau si nécessaire ?</h5>
    <p>Ouvrez le capot et montrez l'emplacement de remplissage de l'huile moteur. On doit compléter avec de l'huile pour moteur de même type (essence ou diesel), ayant des caractéristiques identiques.
</p>              
    </div>
    <div>
    <h5>10. Contrôlez l'état de tous les balais d'essuis-glace du véhicule. Comment détecte-t-on leur usure en circulation ?</h5>
    <p>Regardez les 2 balais d'essuie-glace à l'avant et celui de derrière : soulevez les pour vérifier leur état et indiquez leur état : "bon état" ! En cas de pluie, lorsqu'ils laissent des traces sur le pare-brise, c'est l'indice qui prouve leur usure. Il faut vérifier plus fréquemment les balais d'essuie-glace en hiver parce-qu'ils sont plus sollicités que durant les autres saisons et parce-que le gel use rapidement le caoutchouc des balais d'essuie-glace. Les changer une fois par an à l'approche de la mauvaise saison.
</p>              
    </div>
    </div>
         </div>   
       </div>
   </section>
  )
}

export default Check_external