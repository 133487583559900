import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";

function Legal() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // Navigate back to the previous page
  };
  return (
    <section className="legal">
      <div className="">
        <div className="wrapping">
          <div className="course-header">
            <h4>Légale</h4>

            <div className="back-btn">
              <button
                type="button"
                onClick={goBack}
                class="btn btn-secondary btn-main"
              >
                {" "}
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.8055 9.06832C16.8086 9.29332 16.7221 9.5103 16.5651 9.67153C16.4081 9.83276 16.1935 9.92503 15.9685 9.92804L4.44214 10.0823L7.56004 13.1179C7.71205 13.2767 7.79648 13.4883 7.79554 13.7081C7.79461 13.928 7.70838 14.1389 7.55503 14.2964C7.40167 14.4539 7.19317 14.5457 6.97343 14.5526C6.7537 14.5594 6.5399 14.4806 6.37708 14.3329L1.79192 9.86891C1.63092 9.71196 1.53879 9.49753 1.53578 9.27272C1.53278 9.0479 1.61913 8.83108 1.77587 8.66989L6.2399 4.08473C6.39871 3.93272 6.61033 3.84829 6.83016 3.84923C7.05 3.85016 7.26089 3.93639 7.41841 4.08975C7.57592 4.2431 7.66776 4.45161 7.67458 4.67134C7.6814 4.89107 7.60267 5.10487 7.45497 5.2677L4.41942 8.38561L15.9458 8.23132C16.1708 8.2283 16.3878 8.3148 16.549 8.47177C16.7103 8.62874 16.8025 8.84332 16.8055 9.06832Z"
                    fill="white"
                  />
                </svg>
                Retour
              </button>
            </div>
          </div>
          <div className="legal-head">
            <div className="row">
              <div className="col-lg-3">
                <Link to="https://lcr-auto-ecole.com/legale/">
                  <div className="wraping-lg">
                    <h3>Mentions légales</h3>
                    <svg
                      width="35"
                      height="35"
                      viewBox="0 0 35 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="17.5" cy="17.5" r="17.5" fill="white" />
                      <g clip-path="url(#clip0_1346_10597)">
                        <path
                          d="M11.4907 11C11.3607 11 11.2361 11.0516 11.1442 11.1435C11.0523 11.2354 11.0007 11.36 11.0007 11.49V17.2846C11.001 18.5281 11.4952 19.7206 12.3746 20.5998C13.2539 21.479 14.4465 21.973 15.69 21.9732L23.3872 21.9725L21.1955 24.1635C21.15 24.209 21.1139 24.263 21.0892 24.3225C21.0646 24.3819 21.0519 24.4456 21.0519 24.51C21.0519 24.5744 21.0646 24.6381 21.0892 24.6975C21.1139 24.757 21.15 24.811 21.1955 24.8565C21.241 24.902 21.295 24.9381 21.3544 24.9627C21.4139 24.9874 21.4776 25 21.542 25C21.6063 25 21.67 24.9874 21.7295 24.9627C21.7889 24.9381 21.843 24.902 21.8885 24.8565L24.8565 21.8878C24.902 21.8423 24.9381 21.7883 24.9627 21.7288C24.9873 21.6694 25 21.6057 25 21.5413C25 21.4769 24.9873 21.4132 24.9627 21.3538C24.9381 21.2943 24.902 21.2403 24.8565 21.1948L21.8878 18.2261C21.8423 18.1806 21.7882 18.1445 21.7288 18.1199C21.6693 18.0953 21.6056 18.0826 21.5413 18.0826C21.4769 18.0826 21.4132 18.0953 21.3537 18.1199C21.2943 18.1445 21.2403 18.1806 21.1948 18.2261C21.1493 18.2716 21.1132 18.3256 21.0885 18.3851C21.0639 18.4445 21.0512 18.5082 21.0512 18.5726C21.0512 18.637 21.0639 18.7007 21.0885 18.7601C21.1132 18.8196 21.1493 18.8736 21.1948 18.9191L23.2703 20.9932H15.6893C14.7057 20.993 13.7626 20.6022 13.0671 19.9068C12.3716 19.2113 11.9809 18.2681 11.9807 17.2846V11.49C11.9807 11.36 11.929 11.2354 11.8371 11.1435C11.7453 11.0516 11.6206 11 11.4907 11Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1346_10597">
                          <rect
                            width="14"
                            height="14"
                            fill="white"
                            transform="matrix(-1 0 0 1 25 11)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </Link>
              </div>
              <div className="col-lg-3">
                <Link to="https://lcr-auto-ecole.com/conditions-generales-dutilisation-cgu/">
                  <div className="wraping-lg service">
                    <h3>Conditions d'utilisation</h3>
                    <svg
                      width="35"
                      height="35"
                      viewBox="0 0 35 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="17.5" cy="17.5" r="17.5" fill="white" />
                      <g clip-path="url(#clip0_1346_10597)">
                        <path
                          d="M11.4907 11C11.3607 11 11.2361 11.0516 11.1442 11.1435C11.0523 11.2354 11.0007 11.36 11.0007 11.49V17.2846C11.001 18.5281 11.4952 19.7206 12.3746 20.5998C13.2539 21.479 14.4465 21.973 15.69 21.9732L23.3872 21.9725L21.1955 24.1635C21.15 24.209 21.1139 24.263 21.0892 24.3225C21.0646 24.3819 21.0519 24.4456 21.0519 24.51C21.0519 24.5744 21.0646 24.6381 21.0892 24.6975C21.1139 24.757 21.15 24.811 21.1955 24.8565C21.241 24.902 21.295 24.9381 21.3544 24.9627C21.4139 24.9874 21.4776 25 21.542 25C21.6063 25 21.67 24.9874 21.7295 24.9627C21.7889 24.9381 21.843 24.902 21.8885 24.8565L24.8565 21.8878C24.902 21.8423 24.9381 21.7883 24.9627 21.7288C24.9873 21.6694 25 21.6057 25 21.5413C25 21.4769 24.9873 21.4132 24.9627 21.3538C24.9381 21.2943 24.902 21.2403 24.8565 21.1948L21.8878 18.2261C21.8423 18.1806 21.7882 18.1445 21.7288 18.1199C21.6693 18.0953 21.6056 18.0826 21.5413 18.0826C21.4769 18.0826 21.4132 18.0953 21.3537 18.1199C21.2943 18.1445 21.2403 18.1806 21.1948 18.2261C21.1493 18.2716 21.1132 18.3256 21.0885 18.3851C21.0639 18.4445 21.0512 18.5082 21.0512 18.5726C21.0512 18.637 21.0639 18.7007 21.0885 18.7601C21.1132 18.8196 21.1493 18.8736 21.1948 18.9191L23.2703 20.9932H15.6893C14.7057 20.993 13.7626 20.6022 13.0671 19.9068C12.3716 19.2113 11.9809 18.2681 11.9807 17.2846V11.49C11.9807 11.36 11.929 11.2354 11.8371 11.1435C11.7453 11.0516 11.6206 11 11.4907 11Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1346_10597">
                          <rect
                            width="14"
                            height="14"
                            fill="white"
                            transform="matrix(-1 0 0 1 25 11)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </Link>
              </div>
              <div className="col-lg-3">
                <Link to="https://lcr-auto-ecole.com/politique-de-confidentialite/">
                  <div className="wraping-lg privacy">
                    <h3>politique de confidentialité</h3>
                    <svg
                      width="35"
                      height="35"
                      viewBox="0 0 35 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="17.5" cy="17.5" r="17.5" fill="white" />
                      <g clip-path="url(#clip0_1346_10597)">
                        <path
                          d="M11.4907 11C11.3607 11 11.2361 11.0516 11.1442 11.1435C11.0523 11.2354 11.0007 11.36 11.0007 11.49V17.2846C11.001 18.5281 11.4952 19.7206 12.3746 20.5998C13.2539 21.479 14.4465 21.973 15.69 21.9732L23.3872 21.9725L21.1955 24.1635C21.15 24.209 21.1139 24.263 21.0892 24.3225C21.0646 24.3819 21.0519 24.4456 21.0519 24.51C21.0519 24.5744 21.0646 24.6381 21.0892 24.6975C21.1139 24.757 21.15 24.811 21.1955 24.8565C21.241 24.902 21.295 24.9381 21.3544 24.9627C21.4139 24.9874 21.4776 25 21.542 25C21.6063 25 21.67 24.9874 21.7295 24.9627C21.7889 24.9381 21.843 24.902 21.8885 24.8565L24.8565 21.8878C24.902 21.8423 24.9381 21.7883 24.9627 21.7288C24.9873 21.6694 25 21.6057 25 21.5413C25 21.4769 24.9873 21.4132 24.9627 21.3538C24.9381 21.2943 24.902 21.2403 24.8565 21.1948L21.8878 18.2261C21.8423 18.1806 21.7882 18.1445 21.7288 18.1199C21.6693 18.0953 21.6056 18.0826 21.5413 18.0826C21.4769 18.0826 21.4132 18.0953 21.3537 18.1199C21.2943 18.1445 21.2403 18.1806 21.1948 18.2261C21.1493 18.2716 21.1132 18.3256 21.0885 18.3851C21.0639 18.4445 21.0512 18.5082 21.0512 18.5726C21.0512 18.637 21.0639 18.7007 21.0885 18.7601C21.1132 18.8196 21.1493 18.8736 21.1948 18.9191L23.2703 20.9932H15.6893C14.7057 20.993 13.7626 20.6022 13.0671 19.9068C12.3716 19.2113 11.9809 18.2681 11.9807 17.2846V11.49C11.9807 11.36 11.929 11.2354 11.8371 11.1435C11.7453 11.0516 11.6206 11 11.4907 11Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1346_10597">
                          <rect
                            width="14"
                            height="14"
                            fill="white"
                            transform="matrix(-1 0 0 1 25 11)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </Link>
              </div>
              <div className="col-lg-3">
                <Link to="https://lcr-auto-ecole.com/politique-en-matiere-de-cookies/">
                  <div className="wraping-lg cookies">
                    <h3>Cookies</h3>
                    <svg
                      width="35"
                      height="35"
                      viewBox="0 0 35 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="17.5" cy="17.5" r="17.5" fill="white" />
                      <g clip-path="url(#clip0_1346_10597)">
                        <path
                          d="M11.4907 11C11.3607 11 11.2361 11.0516 11.1442 11.1435C11.0523 11.2354 11.0007 11.36 11.0007 11.49V17.2846C11.001 18.5281 11.4952 19.7206 12.3746 20.5998C13.2539 21.479 14.4465 21.973 15.69 21.9732L23.3872 21.9725L21.1955 24.1635C21.15 24.209 21.1139 24.263 21.0892 24.3225C21.0646 24.3819 21.0519 24.4456 21.0519 24.51C21.0519 24.5744 21.0646 24.6381 21.0892 24.6975C21.1139 24.757 21.15 24.811 21.1955 24.8565C21.241 24.902 21.295 24.9381 21.3544 24.9627C21.4139 24.9874 21.4776 25 21.542 25C21.6063 25 21.67 24.9874 21.7295 24.9627C21.7889 24.9381 21.843 24.902 21.8885 24.8565L24.8565 21.8878C24.902 21.8423 24.9381 21.7883 24.9627 21.7288C24.9873 21.6694 25 21.6057 25 21.5413C25 21.4769 24.9873 21.4132 24.9627 21.3538C24.9381 21.2943 24.902 21.2403 24.8565 21.1948L21.8878 18.2261C21.8423 18.1806 21.7882 18.1445 21.7288 18.1199C21.6693 18.0953 21.6056 18.0826 21.5413 18.0826C21.4769 18.0826 21.4132 18.0953 21.3537 18.1199C21.2943 18.1445 21.2403 18.1806 21.1948 18.2261C21.1493 18.2716 21.1132 18.3256 21.0885 18.3851C21.0639 18.4445 21.0512 18.5082 21.0512 18.5726C21.0512 18.637 21.0639 18.7007 21.0885 18.7601C21.1132 18.8196 21.1493 18.8736 21.1948 18.9191L23.2703 20.9932H15.6893C14.7057 20.993 13.7626 20.6022 13.0671 19.9068C12.3716 19.2113 11.9809 18.2681 11.9807 17.2846V11.49C11.9807 11.36 11.929 11.2354 11.8371 11.1435C11.7453 11.0516 11.6206 11 11.4907 11Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1346_10597">
                          <rect
                            width="14"
                            height="14"
                            fill="white"
                            transform="matrix(-1 0 0 1 25 11)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Legal;
