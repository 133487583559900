import React, { useEffect, useState } from "react";
import axiosInstance from "../utils/http/config/axios";
import downloadfile from "../images/downloadfile.png";
import { Button, Modal } from "react-bootstrap";
import './style.css'
function MessageList({ messages, userId, socket, setMessages }) {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    if (!socket) return;

    // Listen for new messages from the WebSocket
    socket.on("newMsessage", (newMessage) => {
      console.log("New message received:", newMessage);
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    });

    // Cleanup the socket listener on component unmount
    return () => {
      socket.off("newMessage");
    };
  }, [socket, setMessages]);

  const isPdf = (fileUrl) => fileUrl.endsWith(".pdf");
  const isImage = (fileUrl) => ["jpg", "jpeg", "png", "gif", "bmp", "webp"].some(ext => fileUrl.endsWith(ext));

  // Fonction pour télécharger le fichier 
  const downloadFile = async (fileUrl) => {
    const fileName = fileUrl.split('/').pop();
    try {
      const response = await axiosInstance.get(`/api/messages/download/${fileName}`, {
        responseType: 'blob',
      });

      const link = document.createElement('a');
      const url = window.URL.createObjectURL(new Blob([response.data]));
      link.href = url;
      link.setAttribute('download', fileName); // Nom du fichier à télécharger
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Erreur lors du téléchargement du fichier", error);
    }
  };

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImage("");
  };
   // Fonction pour vérifier si le texte est un lien
   const isLink = (text) => {
    const urlRegex = /https?:\/\/[^\s]+/g;
    return urlRegex.test(text);
  };
  const backendUrlsocket = "wss://app.gmp-auto-ecole.com/";
  return (
    <div className="message-list">
      {messages.map((message, index) => (
        <div
          key={`${message.id}-${index}`}
          className={`message ${message.senderId === parseInt(userId) ? "sent" : "received"
            }`}
        >
          <span>
            {isLink(message.content) ? (
              <a href={message.content} target="_blank" rel="noopener noreferrer" className="link-message">
                {message.content}
              </a>
            ) : (
              message.content
            )}
          </span>
          {/* Vérifier si un fichier existe */}
          {message.fileUrl && (
            <div className="file-preview-box">
              <div className="file-info">
                {isPdf(message.fileUrl) ? (
                  <button className="download-btn" onClick={() => downloadFile(message.fileUrl)}>
                    <img src={downloadfile} alt="Download" className="download-icon" />

                  </button>
                ) : isImage(message.fileUrl) ? (
                  <img
                    src={`${message.fileUrl}`}
                    alt="attachment"
                    className="file-preview-image" onClick={() => openModal(message.fileUrl)}
                  />
                ) : (
                  <span>Fichier non pris en charge</span>
                )}
                {isPdf(message.fileUrl) ? (
                  <span>
                    {message.fileUrl.split("/").pop().split("-").slice(-1).join("-")}
                  </span>) : (
                  <span></span>
                )}
              </div>
            </div>
          )}
          <div className="timestamp">
            {new Date(message.createdAt).toLocaleString()}
          </div>
        </div>
      ))}
      <Modal show={showModal} onHide={handleCloseModal} className="trans-popup">
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body>
          <div className="transation-successful p-2">

            <div className="my-4">
              <img
                src={`${selectedImage}`}
                alt="Selected attachment"
                className="modal-image"
              />
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            variant="secondary"
            className="btn-main text-white"
            onClick={() => downloadFile(selectedImage)}
          >
            Télécharger
          </Button>
          <Button
            variant="secondary"
            className="btn-main text-white"
            onClick={handleCloseModal}
          >
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MessageList;