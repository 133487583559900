import React, { useState, useRef, useEffect } from 'react';
import { BsRecordCircle } from 'react-icons/bs';
import { MdCallEnd, MdZoomOutMap } from 'react-icons/md';
import { SiAudiomack } from "react-icons/si";
import { AiFillAudio, AiFillMessage, AiOutlineAudioMuted } from 'react-icons/ai';
import { FaCameraRotate } from 'react-icons/fa6';
import { LuCamera, LuCameraOff } from "react-icons/lu";
import { IoCall } from 'react-icons/io5';
import { IoIosVideocam } from 'react-icons/io';
import PropTypes from 'prop-types';

import Profil from '../../images/floro_user.png'; 
import './VideoCall.css';
import { IconButton } from '../AudioCall/AudioCall';

export default function VideoCall({
  setPopupText,
  setShowPopup,
  callText,
  setCallText,
  setCallStatus,
  callStatus,
  time,
  setTime,
  localStream,
  remoteStream,
  endCall
}) {
  return (
    <div className="videoCallChat animParent">
      {
        (callStatus === 'videoCall' || callStatus === 'inSmallViewVideo') &&
        <VideoCallFullView
          setPopupText={setPopupText}
          setShowPopup={setShowPopup}
          callText={callText}
          setCallText={setCallText}
          callStatus={callStatus}
          setCallStatus={setCallStatus}
          time={time}
          setTime={setTime}
          localStream={localStream}
          remoteStream={remoteStream}
          endCall={endCall}
        />
      }
      {
        callStatus === 'inMessageModeVideo' &&
        <VideoCallMsgView setCallStatus={setCallStatus}/>
      }
    </div>
  );
}

VideoCall.propTypes = {
  setPopupText: PropTypes.func.isRequired,
  setShowPopup: PropTypes.func.isRequired,
  callText: PropTypes.string.isRequired,
  setCallText: PropTypes.func.isRequired,
  setCallStatus: PropTypes.func.isRequired,
  callStatus: PropTypes.string.isRequired,
  time: PropTypes.object.isRequired,
  setTime: PropTypes.func.isRequired,
  localStream: PropTypes.object,
  remoteStream: PropTypes.object,
  endCall: PropTypes.func.isRequired,
};

// ------------------------------------------------------------------------------
// composant plein écran
function VideoCallFullView ({
  setPopupText,
  setShowPopup,
  callText,
  setCallText,
  callStatus,
  setCallStatus,
  time,
  setTime,
  localStream,
  remoteStream,
  endCall
}) {
  const [isUserMicMuted, setIsUserMicMuted] = useState(false);
  const [isCameraActive, setIsCameraActive] = useState(true);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const dragRef = useRef(null);
  const isDraggingRef = useRef(false);
  const offsetRef = useRef({ x: 0, y: 0 });
  const positionRef = useRef({ x: 0, y: 0 });
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isDraggingRef.current) return;
      
      const newX = e.clientX - offsetRef.current.x;
      const newY = e.clientY - offsetRef.current.y;
      
      // Update position immediately using style transform
      if (dragRef.current) {
        dragRef.current.style.transform = `translate(${newX}px, ${newY}px)`;
      }
      
      // Store position for when drag ends
      positionRef.current = { x: newX, y: newY };
    };

    const handleMouseUp = () => {
      if (!isDraggingRef.current) return;
      
      isDraggingRef.current = false;
      // Update state only when drag ends
      setPosition(positionRef.current);
      
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    if (isDraggingRef.current) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  useEffect(() => {
    const playVideo = async () => {
      if (localVideoRef.current && localStream) {
        localVideoRef.current.srcObject = localStream;
        try {
          await localVideoRef.current.play();
        } catch (e) {
          if (e.name === 'AbortError') {
            // Wait a brief moment and try again
            setTimeout(async () => {
              try {
                await localVideoRef.current.play();
              } catch (retryError) {
                console.error('Error playing local video after retry:', retryError);
              }
            }, 100);
          } else {
            console.error('Error playing local video:', e);
          }
        }
      }
    };
    
    playVideo();
  }, [localStream]);

  useEffect(() => {
    const playVideo = async () => {
      if (remoteVideoRef.current && remoteStream) {
        remoteVideoRef.current.srcObject = remoteStream;
        try {
          await remoteVideoRef.current.play();
        } catch (e) {
          if (e.name === 'AbortError') {
            // Wait a brief moment and try again
            setTimeout(async () => {
              try {
                await remoteVideoRef.current.play();
              } catch (retryError) {
                console.error('Error playing remote video after retry:', retryError);
              }
            }, 100);
          } else {
            console.error('Error playing remote video:', e);
          }
        }
      }
    };
    
    playVideo();
  }, [remoteStream]);

  const handleMouseDown = (e) => {
    if (callStatus === 'inSmallViewVideo' && dragRef.current) {
      e.preventDefault();
      isDraggingRef.current = true;
      
      const rect = dragRef.current.getBoundingClientRect();
      offsetRef.current = {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top
      };
    }
  };

  const handleCall = (newStatus) => {
    console.log('[CLIENT] handleCall with newStatus:', newStatus);
    if (newStatus === undefined) {
      console.log('[CLIENT] handleCall => finishing call...');
      setCallText('Appel terminé !');
      setTimeout(() => {
        console.log('[CLIENT] Cleanup call...');
        setCallStatus(undefined);
        setTime({ hours:0, minutes:0, seconds:0 });
        setCallText('En train de sonner...');
        setShowPopup(false);
        endCall();
      }, 1500);
    } else {
      // Reset position when changing modes
      setPosition({ x: 0, y: 0 });
      positionRef.current = { x: 0, y: 0 };
      if (dragRef.current) {
        dragRef.current.style.transform = 'none';
      }
      setCallStatus(newStatus);
    }
  };

  return (
    <div
      ref={dragRef}
      className={`fullViewCallMode ${callStatus === 'inSmallViewVideo' && 'inSmallView inSmallViewVideo'}`}
      style={{
        transform: callStatus === 'inSmallViewVideo' && !isDraggingRef.current
          ? `translate(${position.x}px, ${position.y}px)`
          : 'none',
        cursor: callStatus === 'inSmallViewVideo' ? 'move' : 'default',
        willChange: 'transform',
        WebkitBackfaceVisibility: 'hidden',
        backfaceVisibility: 'hidden'
      }}
      onMouseDown={handleMouseDown}
    >
      <header className="callChatHeader">
        <div className="timeRecorder">
          <span className='dotRecorder'></span>
          {time.hours > 0 && <span>{String(time.hours).padStart(2, '0')} : </span>}
          <span>{String(time.minutes).padStart(2, '0')} : </span>
          <span>{String(time.seconds).padStart(2, '0')}</span>
        </div>
        <div className="headerOptions">
          <IconButton icon={<BsRecordCircle/>} label={'Enregistrer l\'appel'}/>
          {
            callStatus === 'videoCall' && 
            <IconButton
              onClick={()=> handleCall('inSmallViewVideo')}
              icon={<MdZoomOutMap/>}
              label={'Quitter le mode plein écran'}
            />
          }
          {
            callStatus === 'inSmallViewVideo' &&
            <IconButton
              onClick={()=> handleCall('videoCall')}
              icon={<MdZoomOutMap/>}
              label={'Revenir sur le mode plein écran'}
            />
          }
        </div>
      </header>

      <main className="videoChatMain">
        <div className="callState">{callText}</div>
        <div className={`${callText !== 'Appel en cours...' && 'centerOnlyImg'} callUsers`}>
          <div className="remoteVideoContainer">
            <div className='speakIconCard'>
              <SiAudiomack/>
            </div>
            {isCameraActive && (
              <video
                ref={remoteVideoRef}
                className="remoteVideo"
                playsInline
                autoPlay
              />
            )}
          </div>
          <div className="localVideoContainer">
            {isCameraActive ? (
              <video
                ref={localVideoRef}
                muted
                className="localVideo"
                playsInline
                autoPlay
              />
            ) : (
              <div className="profilCard">
                <section style={{background: `url('${Profil}')`, backgroundSize: 'cover'}}></section>
                <img src={Profil} alt="profil" />
              </div>
            )}
          </div>
        </div>
      </main>

      <footer className='videoChatFooter'>
        {
          isUserMicMuted
            ? <IconButton
                icon={<AiOutlineAudioMuted/>}
                label={'Activer le micro'}
                onClick={()=> {
                  console.log('[CLIENT] Unmute mic in videoCall');
                  setIsUserMicMuted(false);
                }}
              />
            : <IconButton
                icon={<AiFillAudio/>}
                label={'Désactiver le micro'}
                onClick={()=> {
                  console.log('[CLIENT] Mute mic in videoCall');
                  setIsUserMicMuted(true);
                }}
              />
        }
        <IconButton
          icon={<IoCall/>}
          label={'Passer en appel vocal'}
          bgColor={'#c43cab'}
          onClick={()=>{
            console.log('[CLIENT] Request to switch from videoCall to audioCall?');
            setPopupText('Peer demande à passer en appel vocal');
            setShowPopup(true);
          }}
        />
        {
          isCameraActive
            ? <IconButton
                icon={<LuCamera/>}
                label={'Désactiver caméra'}
                onClick={()=> {
                  console.log('[CLIENT] Disable camera');
                  setIsCameraActive(false);
                }}
              />
            : <IconButton
                icon={<LuCameraOff/>}
                label={'Activer caméra'}
                onClick={()=> {
                  console.log('[CLIENT] Enable camera');
                  setIsCameraActive(true);
                }}
              />
        }
        {
          isCameraActive &&
          <IconButton
            icon={<FaCameraRotate />}
            label={'Tourner caméra'}
            onClick={()=>console.log('[CLIENT] Rotate camera => TODO implement')}
          />
        }
        {
          callStatus !== 'inSmallViewVideo' &&
          <IconButton
            icon={<AiFillMessage/>}
            label={'Envoyer un message'}
            onClick={()=> {
              console.log('[CLIENT] Switch to inMessageModeVideo');
              setCallStatus('inMessageModeVideo');
            }}
          />
        }
        <IconButton
          icon={<MdCallEnd/>}
          label={'Terminer l\'appel'}
          bgColor={'#E72C79'}
          onClick={()=> handleCall(undefined)}
        />
      </footer>
    </div>
  );
}


function VideoCallMsgView ({ setCallStatus }) {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y
    });
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    setPosition({
      x: e.clientX - offset.x,
      y: e.clientY - offset.y
    });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div
      className="msgViewMode"
      style={{ transform: `translate(${position.x}px, ${position.y}px)` }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <IconButton
        icon={<IoIosVideocam />}
        label={'Revenir en plein écran'}
        onClick={() => {
          console.log('[CLIENT] Return from inMessageModeVideo to videoCall');
          setCallStatus('videoCall');
        }}
      />
    </div>
  );
}
