import { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import axiosInstance from "../utils/http/config/axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "./style.css";
import email from "../images/email.svg";
import stucall from "../images/stucall.svg";
import stuchat from "../images/stuchat.svg";
import callphn from "../images/call-phn.svg";
import emailinst from "../images/emial-inst.svg";
import loct from "../images/loct.svg";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import highschool from "../images/highschool.svg";
import meter from "../images/meter.svg";
import Ellipse from "../images/user-placeholder.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "react-bootstrap/Pagination";
import ReviewCard from "./ReviewCard";
import Loading from "../components/Loading";

function InstructorProfile() {
  const { instructorId } = useParams();
  const [instructor, setInstructor] = useState();
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [reviewsPerPage] = useState(4);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInstructorData = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/students/instructors/${instructorId}`
        );

        if (response.data && response.data.data && response.data.data.data) {
          setInstructor(response.data.data.data);
        } else {
          throw new Error("Données de l'enseignant non trouvées");
        }
      } catch (err) {
        setError(err.message);

        if (err.response?.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      }
    };

    fetchInstructorData();
  }, [instructorId, navigate]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const goBack = () => {
    navigate(-1);
  };

  const getPaginationItems = (currentPage, totalPages) => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
    const delta = Math.floor(maxVisiblePages / 2);

    let startPage = Math.max(currentPage - delta, 1);
    let endPage = Math.min(currentPage + delta, totalPages);

    // Adjust start and end page if necessary
    if (currentPage <= delta) {
      endPage = Math.min(maxVisiblePages, totalPages);
    } else if (currentPage + delta >= totalPages) {
      startPage = Math.max(totalPages - maxVisiblePages + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  if (error) {
    return <div>Erreur: {error}</div>;
  }

  if (!instructor) {
    return <div><Loading/></div>;
  }

  // Get the current reviews for the current page
  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = instructor.reviews?.slice(
    indexOfFirstReview,
    indexOfLastReview
  );

  // Calculate total number of pages
  const totalPages = Math.ceil(instructor.reviews?.length / reviewsPerPage);

  return (
    <>
      <div className="d-flex justify-content-between student-inst-profile mb-3">
        <Breadcrumb className="mb-0 mt-0">
          <Breadcrumb.Item className="breadcrumb mb-0">
            <Link to="/dashboard">Acceuil</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Profil d'enseignant</Breadcrumb.Item>
        </Breadcrumb>
        <button
          type="button"
          onClick={goBack}
          className="btn btn-secondary btn-main "
        >
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.8822 9.06814C16.8852 9.29314 16.7987 9.51012 16.6418 9.67135C16.4848 9.83258 16.2702 9.92484 16.0452 9.92786L4.5188 10.0821L7.6367 13.1177C7.78871 13.2765 7.87314 13.4881 7.8722 13.708C7.87127 13.9278 7.78504 14.1387 7.63169 14.2962C7.47833 14.4537 7.26983 14.5455 7.0501 14.5524C6.83036 14.5592 6.61656 14.4805 6.45374 14.3328L1.86858 9.86872C1.70758 9.71178 1.61545 9.49735 1.61244 9.27253C1.60944 9.04772 1.69579 8.8309 1.85253 8.66971L6.31656 4.08455C6.47537 3.93254 6.68699 3.84811 6.90682 3.84904C7.12666 3.84998 7.33755 3.93621 7.49507 4.08956C7.65258 4.24292 7.74442 4.45142 7.75124 4.67116C7.75806 4.89089 7.67933 5.10469 7.53163 5.26751L4.49608 8.38542L16.0225 8.23113C16.2475 8.22812 16.4645 8.31461 16.6257 8.47158C16.7869 8.62855 16.8792 8.84314 16.8822 9.06814Z"
              fill="white"
            />
          </svg>
          Retour
        </button>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <div className="side-pro-wrap">
            <div className="text-center mb-3">
              {" "}
              <img
                src={
                  instructor?.user.profilePicture
                    ? `/${instructor?.user.profilePicture}`
                    : Ellipse
                }
                // alt=Enseignant assigné"
                className="assigned-instructor-img"
              />
            </div>
            <div className="text-center">
              <h4>
                {instructor?.user
                  ? `${instructor.user.firstName} ${instructor.user.lastName}`
                  : "Enseignant Inconnu"}
              </h4>
              <strong>Enseignants</strong>
            </div>
            <div className="social-icons">
              <img src={email} alt="Email" />
              <img src={stucall} alt="Call" />
              <img src={stuchat} alt="Chat" />
            </div>
            <hr></hr>
            <h3>Autres informations</h3>
            <div className="phone">
              <img src={callphn} alt="Phone" />
              <div>
                <h5>Numéro de téléphone: </h5>
                <span>
                  {instructor?.user?.countryCode
                    ? `${instructor.user.countryCode} ${instructor.user.phoneNumber}`
                    : "Non spécifié"}
                </span>
              </div>
            </div>
            <div className="phone">
              <img src={emailinst} alt="Email" />
              <div>
                <h5>Adresse email: </h5>
                <span>{instructor?.user?.email || "Non spécifié"}</span>
              </div>
            </div>
            <div className="phone">
              <img src={loct} alt="Location" />
              <div>
                <h5>Emplacement: </h5>
                <span>{instructor.workAddress?.name || "Non spécifié"}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8">
          <div className="bg-white p-4 pro-document">
            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="home" title="Certifications">
                <div className="certification">
                  <h3>Qualifications</h3>
                  {/* Qualification Section */}
                  <div className="list qualification-section d-flex justify-content-between align-items-center py-3">
                    <div className="d-flex block">
                      <img
                        src={highschool}
                        alt="Diplôme d'études secondaires"
                      />
                      <strong>Diplôme d'études secondaires</strong>
                    </div>
                    <div className="com-status">
                      <span
                        className={
                          instructor.teachingDiplomaStatus === "Complété"
                            ? "completed-text"
                            : "non-completed-text"
                        }
                      >
                        {instructor.teachingDiplomaStatus}
                      </span>
                    </div>
                  </div>

                  <h3 className="mt-3">Certifications</h3>
                  {/* Certification Section */}
                  <div className="list certification-section py-3">
                    {/* Driving License */}
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <div className="d-flex block ">
                        <img src={meter} alt="Permis de conduire" />
                        <strong>Permis de conduire</strong>
                      </div>
                      <div className="com-status">
                        <span
                          className={
                            instructor.drivingLicenseStatus === "Complété"
                              ? "completed-text"
                              : "non-completed-text"
                          }
                        >
                          {instructor.drivingLicenseStatus}
                        </span>
                      </div>
                    </div>

                    {/* Authorization to Teach */}
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex block">
                        <img src={meter} alt="Autorisation d'enseigner" />
                        <strong>Autorisation d'enseigner</strong>
                      </div>
                      <div className="com-status">
                        <span
                          className={
                            instructor.authorizationToTeachStatus === "Complété"
                              ? "completed-text"
                              : "non-completed-text"
                          }
                        >
                          {instructor.authorizationToTeachStatus}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>

              <Tab eventKey="contact" title="Avis">
                <div className="reviews">
                  <h3>Avis récents</h3>
                  {currentReviews && currentReviews.length > 0 ? (
                    <div className="row">
                      <div className="review-card-container">
                        {currentReviews.map((review, index) => (
                          <ReviewCard review={review} />
                        ))}
                      </div>
                      <Pagination className="mb-0">
                        <Pagination.First
                          onClick={() => setCurrentPage(1)}
                          disabled={currentPage === 1}
                        />
                        <Pagination.Prev
                          onClick={() =>
                            setCurrentPage((prev) => Math.max(prev - 1, 1))
                          }
                          disabled={currentPage === 1}
                        />
                        {getPaginationItems(currentPage, totalPages).map(
                          (page) => (
                            <Pagination.Item
                              key={page}
                              active={currentPage === page}
                              onClick={() => setCurrentPage(page)}
                            >
                              {page}
                            </Pagination.Item>
                          )
                        )}
                        <Pagination.Next
                          onClick={() =>
                            setCurrentPage((prev) =>
                              Math.min(prev + 1, totalPages)
                            )
                          }
                          disabled={currentPage === totalPages}
                        />
                        <Pagination.Last
                          onClick={() => setCurrentPage(totalPages)}
                          disabled={currentPage === totalPages}
                        />
                      </Pagination>
                    </div>
                  ) : (
                    <p>Aucun avis trouvé.</p>
                  )}
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Réserver un emplacement</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="row">
            <div className="col">
              <input
                type="date"
                className="form-control"
                placeholder="DD/MM/YY"
              />
            </div>
            <div className="col">
              <input type="time" className="form-control" placeholder="Time" />
            </div>
          </div>
          <div className="mt-4">
            <input
              type="text"
              className="form-control"
              id="inputAddress"
              placeholder="Meeting place"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Confirmer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default InstructorProfile;
