import React from 'react'
import yellowlight from '../../images/yellowlight.svg'
import carmaster from '../../images/car-master.svg'
import green_light from '../../images/green-light.svg'
import { useNavigate } from 'react-router-dom';
import redlight from '../../images/redlight.svg'
import  "../style.css"

function Practise() {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1); // Navigate back to the previous page
    };
  return (
  <section className='practise'>
      <div className=''>
        <div className='course-header'>
        <h4>Préparez-vous aux questions de l'examen</h4>
        <div className='back-btn'>
          <button type="button" onClick={goBack} class="btn btn-secondary btn-main"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8822 9.06814C16.8852 9.29314 16.7987 9.51012 16.6418 9.67135C16.4848 9.83258 16.2702 9.92484 16.0452 9.92786L4.5188 10.0821L7.6367 13.1177C7.78871 13.2765 7.87314 13.4881 7.8722 13.708C7.87127 13.9278 7.78504 14.1387 7.63169 14.2962C7.47833 14.4537 7.26983 14.5455 7.0501 14.5524C6.83036 14.5592 6.61656 14.4805 6.45374 14.3328L1.86858 9.86872C1.70758 9.71178 1.61545 9.49735 1.61244 9.27253C1.60944 9.04772 1.69579 8.8309 1.85253 8.66971L6.31656 4.08455C6.47537 3.93254 6.68699 3.84811 6.90682 3.84904C7.12666 3.84998 7.33755 3.93621 7.49507 4.08956C7.65258 4.24292 7.74442 4.45142 7.75124 4.67116C7.75806 4.89089 7.67933 5.10469 7.53163 5.26751L4.49608 8.38542L16.0225 8.23113C16.2475 8.22812 16.4645 8.31461 16.6257 8.47158C16.7869 8.62855 16.8792 8.84314 16.8822 9.06814Z" fill="white"/>
          </svg>
          Retour</button>
        </div>
        </div>
        <div className='text-wraps'>
            <div className='master-title'>
                <img src={carmaster} />
                <h4>

                Pratiquer une conduite autonome, sûre et économique</h4>
            </div>
          <div className='main-points'>
              <div className='d-flex'>
                <img src={yellowlight} />
                <p className='mb-0 ms-3'>Suivre un itinéraire de manière autonome.</p>
              </div>
              <div className='d-flex'>
                <img src={redlight} />
                <p className='mb-0 ms-3'>Préparer et effectuer un voyage longue distance en autonomie.</p>
              </div>
              <div className='d-flex'>
                <img src={redlight} />
                <p className='mb-0 ms-3'>Connaître les principaux facteurs de risque au volant et les recommandations à appliquer.</p>
              </div>
              <div className='d-flex'>
                <img src={redlight} />
                <p className='mb-0 ms-3'>Connaître les comportements à adopter en cas d'accident : protéger, alerter, secourir.</p>
              </div>
              <div className='d-flex'>
                <img src={redlight} />
                <p className='mb-0 ms-3'>Faire l'expérience des aides à la conduite du véhicule (régulateur, limiteur de vitesse, ABS, aides à la navigation).</p>
              </div>
              <div className='d-flex'>
                <img src={redlight} />
                <p className='mb-0 ms-3'>Avoir des notions sur l'entretien, le dépannage et les situations d'urgence.</p>
              </div>
              <div className='d-flex'>
                <img src={green_light} />
                <p className='mb-0 ms-3'>Pratiquer l'écoconduite.</p>
              </div>
             
          </div>
        </div>
        </div>
  </section>
  )
}

export default Practise