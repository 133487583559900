import { useState } from 'react';
import staer from '../images/staer.png';
import newnoti from '../images/new-noti.svg';
import calcnoti from '../images/calcnoti.svg';
import './style.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import Star from './Star';
import Form from 'react-bootstrap/Form';

function Feedbackto_instructor() {
    const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
    const [rating, setRating] = useState(0); // State to store the current rating

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const handleRatingClick = (index) => {
        setRating(index);
    };

    return (
        <section className='notifications'>
            <div className='container'>
                <div className='wrapping'>
                    <h5>Nouvelles notifications</h5>
                    <hr />
                    <div className='noti-recent'>
                        <div className='d-flex'>
                            <img src={staer} alt="Star" />
                            <div className='ps-3'>
                                <p className='mb-0'>
                                    Évaluez votre enseignant <strong>James Wilson</strong>.{" "}
                                    <span className='linktoshow' onClick={openModal}>
                                        Cliquez ici
                                    </span>
                                </p>
                                <div className='date-time'>
                                    <span>10 juin 2024</span>
                                    <span>il y a 1 minute</span>
                                </div>
                            </div>
                        </div>
                        <div className='new-noti'>
                            <img src={newnoti} alt="New Notification" />
                        </div>
                    </div>
                    <hr />
                    <div className='noti-recent'>
                        <div className='d-flex'>
                            <img src={staer} alt="Star" />
                            <div className='ps-3'>
                                <p className='mb-0'>
                                    vous avez terminé la leçon et l'enseignant a terminé l'évaluation,{" "}
                                    <strong> veuillez cocher</strong>.
                                </p>
                                <div className='date-time'>
                                    <span>10 juin 2024</span>
                                    <span>il y a 1 minute</span>
                                </div>
                            </div>
                        </div>
                        <div className='new-noti'>
                            <img src={newnoti} alt="New Notification" />
                        </div>
                    </div>
                    <hr />
                    <h4 className='my-4'>30 juin 2024</h4>
                    <div className='noti-recent'>
                        <div className='d-flex'>
                            <img src={calcnoti} alt="Calendar Notification" />
                            <div className='ps-3'>
                                <p className='mb-0'>
                                    vous avez réservé le cours avec l'enseignant <strong>Julia Watt</strong>
                                </p>
                                <div className='date-time'>
                                    <span>10 juin 2024</span>
                                    <span>il y a 1 minute</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='noti-recent'>
                        <div className='d-flex'>
                            <img src={staer} alt="Star" />
                            <div className='ps-3'>
                                <p className='mb-0'>
                                    <strong>James Wilson</strong> a terminé la leçon de conduite, donnez-lui le rapport de performance
                                </p>
                                <div className='date-time'>
                                    <span>10 juin 2024</span>
                                    <span>il y a 1 minute</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={isModalOpen} onHide={closeModal} className='inst-feedback'>
                <Modal.Header closeButton>
                    <Modal.Title>Commentaires de l'enseignant</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='inst_rating'>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>1. Qualité de l'enseignement</Accordion.Header>
                                <Accordion.Body>
                                    <div>
                                        {[1, 2, 3, 4, 5].map((star, index) => (
                                            <Star
                                                key={index}
                                                selected={index < rating}
                                                onClick={() => handleRatingClick(index + 1)}
                                            />
                                        ))}
                                    </div>
                                    <Form>
                                    <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlTextarea1">
                                        <Form.Control as="textarea" rows={3} placeholder="Commentez ici..." />
                                    </Form.Group>
                                    </Form>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>2. Attitude et comportement du moniteur</Accordion.Header>
                                <Accordion.Body>
                                <div>
                                        {[1, 2, 3, 4, 5].map((star, index) => (
                                            <Star
                                                key={index}
                                                selected={index < rating}
                                                onClick={() => handleRatingClick(index + 1)}
                                            />
                                        ))}
                                    </div>
                                    <Form>
                                    <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlTextarea1">
                                        <Form.Control as="textarea" rows={3} placeholder="Commentez ici..." />
                                    </Form.Group>
                                    </Form>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>3. Satisfaction aglobale de la leçon</Accordion.Header>
                                <Accordion.Body>
                                <div>
                                        {[1, 2, 3, 4, 5].map((star, index) => (
                                            <Star
                                                key={index}
                                                selected={index < rating}
                                                onClick={() => handleRatingClick(index + 1)}
                                            />
                                        ))}
                                    </div>
                                    <Form>
                                    <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlTextarea1">
                                        <Form.Control as="textarea" rows={3} placeholder="Commentez ici..." />
                                    </Form.Group>
                                    </Form>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={closeModal}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
}

export default Feedbackto_instructor;
