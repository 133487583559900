import { useNavigate } from "react-router-dom";
import {
  useAdvancedMarkerRef,
  InfoWindow,
  AdvancedMarker,
} from "@vis.gl/react-google-maps";
import markerSvg from "../images/marker.svg";
import Ellipse from "../images/user-placeholder.svg";
import "./style.css";

// Dans votre composant MarkerWithInfoWindow
const MarkerWithInfoWindow = ({
  position,
  selectedItem,
  handleMarkerClick,
  id,
  handleWindowClose,
}) => {
  const [markerRef, marker] = useAdvancedMarkerRef();
  const navigate = useNavigate();

  const handleViewMoreClick = () => {
    console.log(`ID de l'enseignant: ${selectedItem.instructorId}`);
    if (selectedItem.instructorId) {
      navigate(`/instructorprofile/${selectedItem.instructorId}`, {
        state: { instructor: selectedItem },
      });
    } else {
      console.error("ID d'enseignant manquant");
    }
  };

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        position={position}
        onClick={handleMarkerClick}
      >
        <img src={markerSvg} width={40} height={50} alt="Marker" />
      </AdvancedMarker>

      {selectedItem && selectedItem.instructorId === id && (
        <InfoWindow anchor={marker} onClose={handleWindowClose} minWidth={280}>
          <div className="d-flex flex-column map-window justify-content-center">
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <div className="d-flex justify-content-between w-100 ">
                <div className="d-flex align-items-center">
                  <img
                    src={
                      selectedItem?.profilePicture
                        ? `/${selectedItem?.profilePicture}`
                        : Ellipse
                    }
                    alt="mapuser"
                    className="assigned-instructor-img"
                  />
                  <span className="ms-1">{`${selectedItem.firstName} ${selectedItem.lastName}`}</span>
                </div>
                <button
                  className="btn btn-primary"
                  onClick={handleViewMoreClick}
                >
                  Voir plus
                </button>
              </div>
            </div>
          </div>
        </InfoWindow>
      )}
    </>
  );
};

export default MarkerWithInfoWindow;
