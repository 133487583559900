import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import transsuccess from "../images/trans-success.svg";
import axiosInstance from "../utils/http/config/axios";
import P_Spinner from "../components/P_Spinner";

function CheckoutForm({
  planDetails,
  onSuccess,
  onError,
  paymentAmount,
  numberOfPeriods,
  sponsorCode,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);

  const handleRedirect = () => {
    navigate("/dashboard");
  };

  useEffect(() => {}, [planDetails]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      setError("Stripe n'est pas encore chargé.");
      return;
    }

    if (!planDetails?.id) {
      setError(
        "Aucun plan valide sélectionné. Veuillez retourner en arrière et sélectionner un plan."
      );
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    setLoading(true);

    try {
      const { error: stripeError, token } = await stripe.createToken(
        cardElement
      );

      if (stripeError) {
        setError(stripeError.message);
        setLoading(false);
        return;
      }

      const subscriptionTypeId = planDetails.id;

      const response = await axiosInstance.post("/api/students/achat", {
        subscriptionTypeId,
        paymentToken: token.id,
        numberOfPeriods,
        sponsorCode,
      });

      if (response.data.success) {
        localStorage.setItem("hasSubscription", "true");
        localStorage.setItem("dashboard", "/subscribed-dashboard");
        setSuccess("Paiement réussi !");
        setShowModal(true);
        onSuccess && onSuccess();
        toast.success("Paiement réussi !", {
          position: toast.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      let errorMessage =
        "Une erreur est survenue lors du traitement du paiement.";

      if (error.response?.data?.error) {
        errorMessage = error.response.data.error;
      } else if (error.response?.data?.message) {
        errorMessage = `Erreur d'achat : ${error.response.data.message}`;
      } else if (error.message) {
        errorMessage = `Erreur d'achat : ${error.message}`;
      }

      setError(errorMessage);
      onError && onError(errorMessage);
      toast.error(errorMessage, {
        position: toast.TOP_RIGHT,
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="checkout-form">
      <form onSubmit={handleSubmit}>
        <ToastContainer />
        <div className="row">
          <div className="mb-3 col">
            <label htmlFor="card-number-element" className="form-label">
              Numéro de carte*
            </label>
            <div id="card-number-element" className="form-control">
              <CardNumberElement
                options={{ style: { base: { fontSize: "16px" } } }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mb-3 col">
            <label htmlFor="expiry-element" className="form-label">
              Date d'expiration*
            </label>
            <div id="expiry-element" className="form-control">
              <CardExpiryElement
                options={{ style: { base: { fontSize: "16px" } } }}
              />
            </div>
          </div>
          <div className="mb-3 col-lg-6">
            <label htmlFor="cvc-element" className="form-label">
              CVC*
            </label>
            <div id="cvc-element" className="form-control">
              <CardCvcElement
                options={{ style: { base: { fontSize: "16px" } } }}
              />
            </div>
          </div>
        </div>
        <div className="total-section">
          <h3>Total à Payer</h3>
          <div className="price-display">
            {paymentAmount !== planDetails.price && (
              <span className="original-price">€ {planDetails.price}</span>
            )}
            <span className="final-price">€ {paymentAmount}</span>
          </div>
        </div>
        <div>
          {numberOfPeriods > 1 && (
            <div className="text-muted small my-2">
              Les paiements suivants seront automatiquement prélevés aux dates
              prévues
            </div>
          )}
        </div>
        <button
          type="submit"
          className="btn btn-secondary btn-main px-5 payment-btn"
          disabled={!stripe || loading}
        >
          {loading ? <P_Spinner /> : `Payer € ${paymentAmount}`}
        </button>

        {/* Success Modal */}
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          className="trans-popup"
        >
          <Modal.Header closeButton className="border-0"></Modal.Header>
          <Modal.Body>
            <div className="transation-successful p-2">
              <h2>Transaction réussie !</h2>
              <div className="my-4">
                <img src={transsuccess} alt="transaction" />
              </div>
              <p>Votre paiement a été traité avec succès</p>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button
              variant="secondary"
              className="btn-main text-white"
              onClick={handleRedirect}
            >
              réservé un cours de conduite
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Error Feedback */}
        <div className="messages-feedback">
          {error && <p className="text-danger">{error}</p>}
          {success && <p className="text-success">{success}</p>}
        </div>
      </form>
    </div>
  );
}

export default CheckoutForm;
