import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/http/config/axios";
import { useNavigate } from "react-router-dom";
import lesbg from "../../images/girl_drive.png";
import inst_icon from "../../images/inst-icon.png";
import Ellipse from "../../images/user-placeholder.svg";
import "../style.css";
import Pagination from "react-bootstrap/Pagination";
import Loading from "../../components/Loading";
import { capitalize } from "../../utils/function";

function Lesson() {
  const [nextLesson, setNextLesson] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [totalHoursUsed, setTotalHoursUsed] = useState(0);
  const [overallSkillsProgress, setOverallSkillsProgress] = useState(0);
  const [assignedInstructor, setAssignedInstructor] = useState(null);
  const [lastPayment, setLastPayment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [hoursRemaining, setHoursRemaining] = useState(0);
  const navigate = useNavigate();
  const hasSubscription = localStorage.getItem("hasSubscription") === "true";

  useEffect(() => {
    const fetchStudentDashboard = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token found");
        }

        const dashboardResponse = await axiosInstance.get(
          "/api/students/dashboard",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (dashboardResponse.data.success) {
          const {
            lessons,
            assignedInstructor,
            totalHoursUsed,
            hoursRemaining,
            nextLesson,
            lastPayment,
            overallSkillsProgress,
          } = dashboardResponse.data.data;

          setLessons(lessons);
          setNextLesson(nextLesson);
          setTotalHoursUsed(totalHoursUsed);
          setAssignedInstructor(assignedInstructor);
          setHoursRemaining(hoursRemaining);
          setOverallSkillsProgress(overallSkillsProgress);
          setLastPayment(lastPayment);
        } else {
          const { message, data } = dashboardResponse.data;
          setError(message || "Aucune leçon trouvée");

          if (data && data.hoursRemaining !== undefined) {
            setHoursRemaining(data.hoursRemaining);
          }
        }
      } catch (err) {
        setError(
          "Une erreur est survenue lors de la récupération des leçons et du profil"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchStudentDashboard();
  }, []);

  const formatTime = (timeString) => {
    const [hour] = timeString.split(":");
    return `${parseInt(hour)}H`;
  };

  const getPaginationItems = (currentPage, totalPages, range = 3) => {
    const pages = [];
    const start = Math.max(1, currentPage - Math.floor(range / 2));
    const end = Math.min(totalPages, currentPage + Math.floor(range / 2));

    for (let i = start; i <= end; i++) {
      pages.push(i);
    }

    return pages;
  };

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const handleInstructorClick = (instructorId) => {
    navigate(`/instructorprofile/${instructorId}`);
  };

  if (!hasSubscription) {
    return (
      <section className="lesson h-100">
        <div className="row">
          <div className="col-lg-7 d-flex flex-column align-items-center justify-content-center">
            <div className="wraping dont-lseon">
              <h2>
                Pour continuer vos cours de conduite, veuillez acheter des
                heures supplémentaires.
              </h2>
              <button
                type="button"
                className="btn btn-secondary btn-main"
                onClick={() => navigate("/boutique")}
              >
                Commencez à apprendre aujourd'hui
              </button>
            </div>
          </div>
          <div className="col-lg-5">
            <img src={lesbg} alt="Leçons" />
          </div>
        </div>
      </section>
    );
  }

  const indexOfLastLesson = currentPage * itemsPerPage;
  const indexOfFirstLesson = indexOfLastLesson - itemsPerPage;
  const currentLessons = lessons.slice(indexOfFirstLesson, indexOfLastLesson);
  const totalPages = Math.ceil(lessons.length / itemsPerPage);

  return (
    <>
      <section className="next-lesson-data lesson h-100 subscribed_user">
        <div className="subscribed_user_view p-3">
          <div className="lesson-header-container p-0">
            <div className="left-side">
              <div className="wraping">
                <h2>
                  Leçons à <span>venir</span>
                </h2>
                {nextLesson ? (
                  <>
                    <p>
                      Votre prochain cours commencera à{" "}
                      <strong>
                        {formatTime(nextLesson.timeslot.startTime)}
                      </strong>
                    </p>
                    <p>
                      Enseignant:{" "}
                      <strong>{`${
                        nextLesson.timeslot.instructor?.firstName ||
                        "Enseignant inconnu"
                      } ${
                        nextLesson.timeslot.instructor?.lastName || ""
                      }`}</strong>
                    </p>
                  </>
                ) : (
                  <p>Aucune leçon à venir.</p>
                )}
              </div>
            </div>
            {nextLesson && (
              <div className="right-side d-flex justify-content-center">
                <div className="next-lesson-instructor-img-card">
                  <img
                    src={
                      assignedInstructor?.profilePicture
                        ? `/${assignedInstructor?.profilePicture}`
                        : Ellipse
                    }
                    alt=""
                    className="next-lesson-instructor-img"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      <section className="bottom-widgets">
        <div className="">
          <div className=" my-4 header-lesson-cards">
            <div className="">
              <div className="credits">
                <strong>Crédit disponible</strong>
                <h3>{hoursRemaining} heures</h3>
                <span>disponible</span>
              </div>
            </div>
            <div className="">
              <div className="credits appre">
                <strong>Apprentissage</strong>
                <h3>{overallSkillsProgress ? overallSkillsProgress : 0}%</h3>

                <p>
                  Suivez l'évolution de votre apprentissage dans votre livret en
                  ligne
                </p>
              </div>
            </div>
            <div className="">
              <div className="credits ense">
                {assignedInstructor ? (
                  <div className="d-flex align-items-center mt-3">
                    <img
                      src={
                        assignedInstructor?.profilePicture
                          ? `/${assignedInstructor?.profilePicture}`
                          : Ellipse
                      }
                      alt=""
                      className="next-lesson-instructor-img"
                    />
                    <h4
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleInstructorClick(assignedInstructor.id)
                      }
                    >{`${assignedInstructor.firstName || ""} ${
                      assignedInstructor.lastName || ""
                    }`}</h4>
                  </div>
                ) : (
                  <p>Aucun enseignant assigné.</p>
                )}
              </div>
            </div>
            <div className="">
              <div className="credits utilize">
                <strong>Crédit utilisé</strong>
                <h3>{totalHoursUsed} heures</h3>
                <span>crédit déjà utilisé</span>
              </div>
            </div>
            <div className="">
                <div className="credits ense">
                  <strong>Forfait abonné</strong>
                  <h3>{lastPayment?.subscription}</h3>
                  <div
                    className={`${lastPayment?.paymentStatus === "réussi" && "success"} ${
                      (lastPayment?.paymentStatus === "rembourse" || lastPayment?.paymentStatus === "annule") && "fail"
                    } ${lastPayment?.paymentStatus === "en attente" && "pending"} status`}
                  >
                    <span>Mode de paiement : Par Carte</span>
    
                    <div>
                    
                      {capitalize(lastPayment?.paymentType)}
                      <span  className="status-label">{capitalize(lastPayment?.paymentStatus)}</span>
                    </div>
                  </div>
                </div>
                
            </div>
          </div>

          <div className="mt-5">
            <h2 className="mb-4">
              <strong>Récapitulatif</strong>
            </h2>
          </div>
          <div className="row">
            <div className="summary-table table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Statut</th>
                    <th scope="col">Enseignants</th>
                    <th scope="col">Date</th>
                    <th scope="col">Horaires</th>
                    <th scope="col">Adresse</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentLessons.length > 0 ? (
                    currentLessons.map((lesson) => (
                      <tr key={lesson.id}>
                        <td>
                          <p
                            className={`lesson-status ${
                              lesson.status === "réalisé"
                                ? "bg-green"
                                : lesson.status === "en cours"
                                ? "bg-yellow"
                                : lesson.status === "annulé"
                                ? "bg-red"
                                : "bg-purple"
                            }`}
                          >
                            {lesson.status}
                          </p>
                        </td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleInstructorClick(assignedInstructor.id)
                          }
                        >
                          <img
                            src={inst_icon}
                            className="me-2"
                            // alt="enseignant"
                          />
                          {lesson.timeslot.instructor?.firstName}{" "}
                          {lesson.timeslot.instructor?.lastName}
                        </td>
                        <td>
                          {new Date(lesson.timeslot.date).toLocaleDateString()}
                        </td>
                        <td>
                          {lesson.timeslot.startTime} -{" "}
                          {lesson.timeslot.endTime}
                        </td>
                        <td className="text-start">
                          {lesson.location || "Aucune adresse fournie"}
                        </td>
                        <td>
                          <svg
                            width="20"
                            height="19"
                            viewBox="0 0 20 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="9.57617"
                              cy="9.5"
                              r="9.5"
                              fill="url(#paint0_linear_2327_6099)"
                            />
                            <path
                              d="M8.57598 5.5L7.87598 6.25L11.576 10L7.87598 13.75L8.57598 14.5L13.076 10L8.57598 5.5Z"
                              fill="white"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_2327_6099"
                                x1="1.04861"
                                y1="5.62245"
                                x2="18.395"
                                y2="7.15996"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stopColor="#C43CAB" />
                                <stop offset="1" stopColor="#F87E4B" />
                              </linearGradient>
                            </defs>
                          </svg>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">Aucune leçon trouvée.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <Pagination className="mb-0">
            <Pagination.First
              onClick={() => setCurrentPage(1)}
              disabled={currentPage === 1}
            />
            <Pagination.Prev
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            />

            {/* Generate page numbers dynamically */}
            {getPaginationItems(currentPage, totalPages).map((page) => (
              <Pagination.Item
                key={page}
                active={currentPage === page}
                onClick={() => setCurrentPage(page)}
              >
                {page}
              </Pagination.Item>
            ))}

            <Pagination.Next
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
            />
            <Pagination.Last
              onClick={() => setCurrentPage(totalPages)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </div>
      </section>
    </>
  );
}

export default Lesson;
