import React, { useState, useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import noti from "../images/noti.svg";
import setting from "../images/setting.svg";
import Sidebar from "./Sidebar";
import logout from "../images/logout.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "../utils/http/config/axios";
import {io} from "socket.io-client";

function Index() {
  const [navHeading, setNavHeading] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [notificationCount, setNotificationCount] = useState(() => {
    const savedCount = localStorage.getItem("notificationCount");
    return savedCount ? parseInt(savedCount, 10) : 0;
  });
  const [notifBadgeStyle, setNotifBadgeStyle] = useState(null)
  const backendUrlsocket = "wss://app.gmp-auto-ecole.com";
  const socket = io(backendUrlsocket, {
    path: "/socket.io",
    transports: ["websocket"], // Force WebSocket transport
    timeout: 10000, // 10 seconds timeout
  });
  

  const updateHeading = (newHeading) => {
    setNavHeading(newHeading);
  };

  const handleLogout = async () => {
    try {
      const userId = localStorage.getItem("userId");
          // Émettre un événement pour signaler manuellement la déconnexion
  if (userId) {
    socket.emit("manual-disconnect", userId);
  } 

      socket.emit("userLogout", userId);
      await axiosInstance.get("/api/students/logout");

      // Clear all data from local storage
      localStorage.clear();

      navigate("/");

      toast.success("Déconnexion réussie !", {
        position: toast.TOP_RIGHT,
        autoClose: 3000,
      });
    } catch (error) {
      toast.error(
        "Une erreur s'est produite lors de la déconnexion. Veuillez réessayer.",
        {
          position: toast.TOP_RIGHT,
          autoClose: 3000,
        }
      );
    }
  };

  let headerTitle = "";

  switch (location.pathname) {
    case "/notifications":
      headerTitle = "Notifications";
      break;
    case "/feedback-instructor":
      headerTitle = "Feedback Instructor";
      break;
    default:
      headerTitle = "";
  }

  useEffect(() => {
    const fetchNotificationCount = async () => {
      try {
        const response = await axiosInstance.get("/api/notifications/unread");
        const count = response.data.count;
        setNotificationCount(count);
        localStorage.setItem("notificationCount", count.toString());
      } catch (error) {
        toast.error("Error fetching notification count:", error);
      }
    };

    fetchNotificationCount();
  }, []);

  if (location.pathname === "/notifications") {
    const clearNotifications = async () => {
      try {
        await axiosInstance.post("/api/notifications/mark-as-read");
        setNotificationCount(0);
        localStorage.setItem("notificationCount", "0");
      } catch (error) {
        toast.error("Error marking notifications as read:", error);
      }
    };

    clearNotifications();
  }

  useEffect(() => {
    const style =
      Number(notificationCount) > 999
        ? "42px"
        : Number(notificationCount) > 99
        ? "34px"
        : Number(notificationCount) > 9
        ? "29px"
        : "25px";
    setNotifBadgeStyle(style);
  }, [notificationCount]);

  return (
    <>
      <div className="main-wrap">
        <div className="container-fluid side-head">
          <div className="container-wrap">
            <Sidebar updateHeading={updateHeading} />

            <div className="side-content">
              <div className="herder-fixed">
                <section className="header">
                  <div className="d-flex header-toggle">
                    {headerTitle ? (
                      <h3 className="mb-0">{headerTitle}</h3>
                    ) : (
                      navHeading && (
                        <h3 className="other-head mb-0">{navHeading}</h3>
                      )
                    )}
                  </div>
                  <div className="other-navigation">
                    <Link to="/notifications" className="notification-link">
                      <img src={noti} alt="Notifications" />
                      {notificationCount > 0 && (
                        <span
                          style={{
                            height: notifBadgeStyle,
                            width: notifBadgeStyle,
                          }}
                          className="notification-badge"
                        >
                          {notificationCount}
                        </span>
                      )}
                    </Link>
                    <Link to="/parameter">
                      <img src={setting} alt="Settings" />
                    </Link>
                    <img
                      src={logout}
                      alt="Logout"
                      onClick={handleLogout}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </section>
              </div>
              <Outlet />
              <h6 className="realization">
                © Copyright 2024 - LCR by GMP (GRAND MONITEUR DE PARIS) |
                Développé par
                <a target="blank" href="https://vnb-it.fr/">
                  <strong> VNB-IT</strong>
                </a>
              </h6>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Index;