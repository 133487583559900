const checkDevices = async () => {
    try {
        console.log('[CLIENT] Checking system camera/microphone status...');
        
        // Check if we're in a secure context
        if (!window.isSecureContext) {
            console.error('[CLIENT] Not in a secure context - camera may not work');
        }

        // List all devices before permission
        const initialDevices = await navigator.mediaDevices.enumerateDevices();
        console.log('[CLIENT] Devices before permission:', 
            initialDevices.map(d => ({
                kind: d.kind,
                label: d.label || 'unnamed device'
            }))
        );

        // Try audio only first
        try {
            const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
            console.log('[CLIENT] Audio permission granted');
            audioStream.getTracks().forEach(track => track.stop());
        } catch (err) {
            console.warn('[CLIENT] Audio permission failed:', err);
        }

        // Try video only next
        try {
            const videoStream = await navigator.mediaDevices.getUserMedia({ 
                audio: false, 
                video: {
                    width: { ideal: 640 },
                    height: { ideal: 480 }
                }
            });
            console.log('[CLIENT] Video permission granted');
            videoStream.getTracks().forEach(track => track.stop());
        } catch (err) {
            console.warn('[CLIENT] Video permission failed:', err);
        }

        // List devices after permission attempts
        const finalDevices = await navigator.mediaDevices.enumerateDevices();
        const deviceSummary = finalDevices.reduce((acc, device) => {
            acc[device.kind] = (acc[device.kind] || 0) + 1;
            return acc;
        }, {});
        
        console.log('[CLIENT] Final device count:', deviceSummary);
        return finalDevices;
    } catch (err) {
        console.error('[CLIENT] Device check failed:', err);
        return [];
    }
};

const getAvailableDevices = async (kind) => {
    try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const filteredDevices = devices.filter(device => device.kind === kind);
        
        console.log(`[CLIENT] ${kind} devices:`, 
            filteredDevices.map(d => ({
                label: d.label || 'unnamed device',
                id: d.deviceId
            }))
        );
        
        return filteredDevices;
    } catch (err) {
        console.error('[CLIENT] Error getting devices:', err);
        return [];
    }
};

const tryGetUserMedia = async (constraints) => {
    console.log('[CLIENT] Attempting getUserMedia with constraints:', constraints);
    
    try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        const tracks = stream.getTracks();
        console.log('[CLIENT] Successfully got stream with tracks:', 
            tracks.map(t => ({
                kind: t.kind,
                label: t.label,
                enabled: t.enabled,
                muted: t.muted
            }))
        );
        return stream;
    } catch (err) {
        console.error('[CLIENT] getUserMedia failed:', err);
        throw err;
    }
};

export const getLocalStream = async (video = true, audio = true) => {
    console.log('[CLIENT] getLocalStream starting... video:', video, 'audio:', audio);

    try {
        // Run detailed diagnostic check
        const devices = await checkDevices();
        
        // Try to get audio-only first if requested
        let audioStream;
        if (audio) {
            try {
                audioStream = await navigator.mediaDevices.getUserMedia({ 
                    audio: true, 
                    video: false 
                });
                console.log('[CLIENT] Successfully got audio stream');
            } catch (err) {
                console.warn('[CLIENT] Failed to get audio:', err);
            }
        }

        // Then try video if requested
        let videoStream;
        if (video) {
            try {
                videoStream = await navigator.mediaDevices.getUserMedia({ 
                    video: {
                        width: { ideal: 640 },
                        height: { ideal: 480 },
                        frameRate: { ideal: 15 }
                    },
                    audio: false
                });
                console.log('[CLIENT] Successfully got video stream');
            } catch (err) {
                console.warn('[CLIENT] Failed to get video:', err);
            }
        }

        // Combine streams if we got both
        if (videoStream && audioStream) {
            const tracks = [
                ...videoStream.getTracks(),
                ...audioStream.getTracks()
            ];
            return new MediaStream(tracks);
        }
        
        // Return whatever stream we got
        return videoStream || audioStream || null;

    } catch (err) {
        console.error('[CLIENT] Final error in getLocalStream:', err);
        throw new Error(`Failed to access media devices: ${err.message}`);
    }
};