import { useCallback, useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import current from "../images/current.svg";
import dashboardicon from "../images/dashboard-icon.png";
import searchfilters from "../images/search-filters.svg";
import "./style.css";
import Form from "react-bootstrap/Form";
import { AdvancedMarker, APIProvider, Map } from "@vis.gl/react-google-maps";
import { addDays, format } from "date-fns";
import { toast, ToastContainer } from "react-toastify";
import MarkerWithInfoWindow from "../components/MarkerWithInfoWindow";
import axiosInstance from "../utils/http/config/axios";
import TimeSlots from "./components/time-slots";
import { Link, useNavigate } from "react-router-dom";
import reservationbooked from "../images/reservation-booked.svg";
import P_Spinner from "../components/P_Spinner";

function Dashboard() {
  const navigate = useNavigate();
  const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;
  const [show, setShow] = useState(false);
  const [nearInstructors, setNearInstructors] = useState([]);
  const [nearInstructorsAvailability, setNearInstructorsAvailability] =
    useState([]);
  const [location, setLocation] = useState(null);
  const [locationDenied, setLocationDenied] = useState(false);
  const [toastShown, setToastShown] = useState(false);
  const handleClose = () => setShow(false);
  const [showFiltersModal, setShowFiltersModal] = useState(false);

  const handleFiltersModalClose = () => setShowFiltersModal(false);
  const handleFiltersModalShow = () => setShowFiltersModal(true);

  const [selectedCarType, setSelectedCarType] = useState("");
  const [selectedDistance, setSelectedDistance] = useState("");
  const [selectedAvailability, setSelectedAvailability] = useState("");
  const [cityName, setCityName] = useState("");

  const [userName, setUserName] = useState("");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedInstructor, setSelectedInstructor] = useState(null);
  const [loadingInstructorsAvailability, setLoadingInstructorsAvailability] =
    useState(false);
  const [loadingNearInstructors, setLoadingNearInstructors] = useState(false);
  const [reserving, setReserving] = useState(false);
  const [joinDate, setJoinDate] = useState("");
  const [activeKey, setActiveKey] = useState(null);

  const [showLocationModal, setShowLocationModal] = useState(false);

  const handleLocationModalClose = () => {
    setShowLocationModal(false);
  };

  const handleEnableLocation = () => {
    setShowLocationModal(false);
    window.location.reload();
  };

  const handleRedirect = () => {
    navigate("/subscribed-dashboard");
  };

  useEffect(() => {
    const storedDate = localStorage.getItem("createdAt");

    if (storedDate) {
      const [day, month, year] = storedDate.split("/");
      const dateObj = new Date(`${year}-${month}-${day}`);

      const formattedDate = new Intl.DateTimeFormat("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(dateObj);

      setJoinDate(formattedDate);
    } else {
      setJoinDate("No date available");
    }
  }, []);

  useEffect(() => {
    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");
    if (firstName && lastName) {
      setUserName(`${firstName} ${lastName}`);
    }
  }, []);

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  const handleCityNameChange = debounce((e) => {
    const value = e.target.value;
    setCityName(value);

    if (value === "") {
      setCityName("");
    }
  }, 300);

  // Get nearby instructors based on location
  useEffect(() => {
    if (location) {
      setLoadingNearInstructors(true);
      const getNearByInstructors = async () => {
        try {
          const response = await axiosInstance.get(`/api/instructors/near-by`, {
            params: {
              location: `${location.lat},${location.long}`,
              radius: 30000000,
              carType: selectedCarType,
              maxDistance: selectedDistance,
              availability: selectedAvailability || null,
              cityName: cityName || null,
            },
          });
          setNearInstructors(response.data.data);
        } catch (error) {
          toast.error(
            "Un problème est survenu lors du chargement des enseignants, veuillez réessayer plus tard."
          );
        } finally {
          setLoadingNearInstructors(false);
        }
      };
      getNearByInstructors();
    }
  }, [
    location,
    selectedCarType,
    selectedDistance,
    selectedAvailability,
    cityName,
  ]);

  // Get instructor availability
  useEffect(() => {
    if (nearInstructors && nearInstructors.length) {
      setLoadingInstructorsAvailability(true);
      const getNearByInstructorsAvailability = async () => {
        try {
          const response = await axiosInstance.get(
            `/api/instructors/availability`,
            {
              params: {
                ids: nearInstructors
                  .map((instructor) => instructor.instructorId)
                  .join(","),
                startDate: format(currentDate, "yyyy-MM-dd"),
                endDate: format(addDays(currentDate, 7), "yyyy-MM-dd"),
              },
            }
          );
          setNearInstructorsAvailability(response.data.data);
        } catch (error) {
          toast.error(
            "Un problème est survenu lors du chargement des disponibilités."
          );
        } finally {
          setLoadingInstructorsAvailability(false);
        }
      };
      getNearByInstructorsAvailability();
    }
  }, [nearInstructors, currentDate]);

  // Get student location using geolocation API
  useEffect(() => {
    let mounted = true;

    const checkGeolocation = () => {
      if (!navigator.geolocation) {
        if (mounted) {
          setLoadingNearInstructors(false);
          setLoadingInstructorsAvailability(false);
          setLocationDenied(true);
          setShowLocationModal(true);
        }
        return;
      }

      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (mounted) {
            setLocation({
              lat: position.coords.latitude,
              long: position.coords.longitude,
            });
            setLocationDenied(false);
            setToastShown(false);
          }
        },
        (err) => {
          if (mounted) {
            setLoadingNearInstructors(false);
            setLoadingInstructorsAvailability(false);
            setLocationDenied(true);
            setShowLocationModal(true);
          }
        }
      );
    };

    checkGeolocation();

    return () => {
      mounted = false;
    };
  }, []);

  const backToList = () => {
    setSelectedInstructor(null);
  };

  const handleMarkerClick = (instructor) => {
    setSelectedInstructor(instructor);
  };

  const handleCurrentLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
        },
        (err) => {
          setLoadingNearInstructors(false);
          setLoadingInstructorsAvailability(false);
          toast.error(
            "Veuillez autoriser l'accès à votre localisation dans votre navigateur ou les paramètres du site pour utiliser la carte et trouver des moniteurs."
          );
        }
      );
    }
  };

  const handleWindowClose = useCallback(() => {}, []);

  const reserveSlots = async (selected) => {
    if (selected && selected.length) {
      setReserving(true);
      try {
        const response = await axiosInstance.post(`/api/students/book`, {
          availabilityIds: selected.map((item) => item.id),
          lessonDetails: { duration: selected.length },
        });

        // Check if success is false and display an error message accordingly
        if (response.data.success === false) {
          toast.error(
            response.data.message ||
              "Une erreur est survenue. Veuillez réessayer."
          );
        } else {
          // Success case
          toast.success("Réservation effectuée avec succès !");
          setShow(true); // Show modal only after success
        }
      } catch (error) {
        const errorMsg =
          error.response?.data?.error ||
          "Une erreur est survenue. Veuillez réessayer plus tard.";
        toast.error(errorMsg);
      } finally {
        setReserving(false);
      }
    }
  };

  const handleAccordionToggle = (key) => {
    setActiveKey(key === activeKey ? null : key);
  };

  return (
    <div className="dashboard">
      <ToastContainer limit={1} />
      <div className="container-fluid">
        <div className=" mt-lg-5">
          <div className="row dashboard-wrap">
            <div className="col-lg-7">
              <div className="content-wrap">
                <h2>
                  Bienvenue! <br></br>
                  <span>{userName}</span>
                </h2>
                <div className="joining-date-student my-3">
                  <span>
                    <strong>Date d'adhésion: </strong>
                    <p className="my-0">{joinDate}</p>
                  </span>
                </div>
                <p>Explorez nos cours d'école de conduite</p>
              </div>
            </div>
            <div className="col-lg-5 car-img-card">
              <img src={dashboardicon} alt="Dashboard icon" />
            </div>
          </div>
        </div>
        <div className="heures">
          <div className="content">
            <h3>Commencez à apprendre aujourd'hui</h3>
            <Link to="/boutique">
              <button type="button" className="btn btn-light">
                Voir notre offre
              </button>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="filters">
            <div className="mb-3 inp-fil">
              <div className="search-icon">
                <img
                  src={current}
                  alt="location"
                  onClick={handleCurrentLocationClick}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Rechercher une ville..."
                onChange={handleCityNameChange}
              />
              <div className="current-loc">
                <img src={searchfilters} alt="Search filters" />
              </div>
            </div>
            <div className="filters-cions" onClick={handleFiltersModalShow}>
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="24"
                  cy="24"
                  r="24"
                  fill="url(#paint0_linear_1710_5178)"
                />
                <path
                  d="M26.0001 24V31.88C26.0401 32.18 25.9401 32.5 25.7101 32.71C25.6176 32.8027 25.5077 32.8762 25.3868 32.9264C25.2658 32.9766 25.1361 33.0024 25.0051 33.0024C24.8742 33.0024 24.7445 32.9766 24.6235 32.9264C24.5025 32.8762 24.3926 32.8027 24.3001 32.71L22.2901 30.7C22.1811 30.5933 22.0982 30.4629 22.0479 30.319C21.9976 30.175 21.9813 30.0213 22.0001 29.87V24H21.9701L16.2101 16.62C16.0477 16.4115 15.9745 16.1473 16.0063 15.8849C16.0382 15.6226 16.1726 15.3835 16.3801 15.22C16.5701 15.08 16.7801 15 17.0001 15H31.0001C31.2201 15 31.4301 15.08 31.6201 15.22C31.8277 15.3835 31.9621 15.6226 31.9939 15.8849C32.0258 16.1473 31.9525 16.4115 31.7901 16.62L26.0301 24H26.0001Z"
                  fill="white"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1710_5178"
                    x1="2.45669"
                    y1="14.2041"
                    x2="46.2793"
                    y2="18.0883"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#C43CAB" />
                    <stop offset="1" stopColor="#F87E4B" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
          {/* Location Access Modal */}
          <Modal
            show={showLocationModal}
            onHide={handleLocationModalClose}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Accès à la localisation requis</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="text-center mb-4">
                <p>
                  Pour trouver les moniteurs près de chez vous, nous avons
                  besoin d'accéder à votre localisation. Veuillez autoriser
                  l'accès à la localisation dans les paramètres de votre
                  navigateur.
                </p>
                <p className="mt-3">
                  <strong>Comment activer la localisation :</strong>
                </p>
                <ol className="text-start">
                  <li>
                    Cliquez sur l'icône de cadenas/site dans la barre d'adresse
                  </li>
                  <li>Trouvez les paramètres de localisation</li>
                  <li>Sélectionnez "Autoriser"</li>
                  <li>Rafraîchissez la page</li>
                </ol>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleLocationModalClose}>
                Fermer
              </Button>
              <Button variant="primary" onClick={handleEnableLocation}>
                Rafraîchir la page
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={showFiltersModal} onHide={handleFiltersModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Filtres</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* Distance from Location */}
              <div className="mb-3">
                <label>Distance de votre emplacement</label>
                <Form.Select
                  aria-label="Sélectionnez la distance"
                  value={selectedDistance}
                  onChange={(e) => setSelectedDistance(e.target.value)}
                >
                  <option value="">Sélectionnez la distance</option>
                  <option value="5">Moins de 5km</option>
                  <option value="10">Moins de 10km</option>
                  <option value="20">Moins de 20km</option>
                </Form.Select>
              </div>

              {/* Time Slot Availability */}
              <div className="mb-3">
                <label>Disponibilité des plages horaires</label>
                <Form.Select
                  aria-label="Sélectionnez un créneau horaire"
                  value={selectedAvailability}
                  onChange={(e) => setSelectedAvailability(e.target.value)}
                >
                  <option value="">Sélectionnez un créneau horaire</option>
                  <option value="morning">Matin</option>
                  <option value="noon">Midi</option>
                  <option value="afternoon">Après-midi</option>
                  <option value="evening">Soir</option>
                </Form.Select>
              </div>

              {/* Car Type */}
              <div className="mb-3">
                <label>Type de voiture</label>
                <Form.Select
                  aria-label="Sélectionnez le type de voiture"
                  value={selectedCarType}
                  onChange={(e) => setSelectedCarType(e.target.value)}
                >
                  <option value="">Sélectionnez le type de voiture</option>
                  <option value="automatique">Automatique</option>
                  <option value="manuel">Manuel</option>
                  <option value="moto">Moto</option>
                </Form.Select>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  setSelectedCarType("");
                  setSelectedDistance("");
                  setSelectedAvailability("");
                }}
              >
                Réinitialiser les filtres
              </Button>
              <Button
                variant="primary"
                className="btn-gradient"
                onClick={handleFiltersModalClose}
              >
                Appliquer
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="col-md-12 col-lg-7 stat-cont">
            <div className="stations">
              <Accordion activeKey={activeKey} onSelect={handleAccordionToggle}>
                {!loadingNearInstructors && !loadingInstructorsAvailability ? (
                  nearInstructors && nearInstructors.length ? (
                    nearInstructors.map((instructor) => {
                      // Ensure availability is sorted by time within each day
                      const instructorAvailability =
                        nearInstructorsAvailability[instructor.instructorId];

                      const sortedSlotsByDay = instructorAvailability
                        ? Object.keys(instructorAvailability).reduce(
                            (acc, date) => {
                              const sortedSlots = instructorAvailability[
                                date
                              ].sort((a, b) => a.from.localeCompare(b.from));
                              acc[date] = sortedSlots;
                              return acc;
                            },
                            {}
                          )
                        : {};

                      return (
                        <Accordion.Item
                          eventKey={instructor.instructorId}
                          key={instructor.instructorId}
                          hidden={
                            selectedInstructor &&
                            selectedInstructor.instructorId !==
                              instructor.instructorId
                          }
                        >
                          <Accordion.Header>
                            {instructor?.location?.address}
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="distance">
                              <p>
                                {instructor?.location?.address?.split(",")[0]}{" "}
                                <br />
                                {instructor?.location?.address
                                  ?.split(",")[1]
                                  ?.trim()}
                              </p>

                              <strong>
                                {instructor?.location?.distance < 10000
                                  ? `${Math.floor(
                                      instructor?.location?.distance
                                    )}m`
                                  : `${(
                                      instructor?.location?.distance / 1000
                                    ).toFixed(1)} km`}
                              </strong>
                            </div>

                            {!loadingInstructorsAvailability ? (
                              <TimeSlots
                                instructor={instructor}
                                instructorAvailability={sortedSlotsByDay}
                                reserveSlots={(selectedTimeSlots) =>
                                  reserveSlots(selectedTimeSlots)
                                }
                                reserving={reserving}
                              />
                            ) : (
                              <div className="d-flex justify-content-center align-items-center h-100 gap-2">
                                <P_Spinner />
                                <span>Chargement des disponibilités...</span>
                              </div>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })
                  ) : null
                ) : (
                  <div className="d-flex justify-content-center align-items-center h-100 gap-2">
                    <P_Spinner />
                    <span>Chargement des disponibilités...</span>
                  </div>
                )}

                {/* Modal to show reservation success */}
                <Modal show={show} onHide={handleClose} className="reser-book">
                  <Modal.Header closeButton className="border-0"></Modal.Header>
                  <Modal.Body>
                    <div className="transation-successful p-2">
                      <h2>Toutes nos félicitations</h2>
                      <div className="my-4">
                        <img src={reservationbooked} alt="reservation booked" />
                      </div>
                      <p>Vos créneaux ont été réservés avec succès</p>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="d-flex justify-content-center">
                    <Button
                      variant="secondary"
                      className="btn-main text-white"
                      onClick={handleRedirect}
                    >
                      Accueil
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Accordion>
              {selectedInstructor && (
                <div className="mt-5 d-flex justify-content-center">
                  <button
                    className="btn btn-outline-main"
                    onClick={() => backToList()}
                  >
                    Retour
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-12 col-lg-5 ">
            <div className="my-4 map-mobile">
              {location ? (
                <APIProvider apiKey={googleMapsKey}>
                  <Map
                    mapId={process.env.REACT_APP_MAP_ID}
                    style={{
                      width: "100%",
                      height: "100vh",
                      borderRadius: "1.5rem",
                      overflow: "hidden",
                      border: "1px solid #8d8b8ba8",
                    }}
                    defaultCenter={{ lat: location?.lat, lng: location?.long }}
                    defaultZoom={10}
                    gestureHandling={"greedy"}
                    disableDefaultUI={true}
                  />

                  <AdvancedMarker
                    position={{ lat: location.lat, lng: location.long }}
                    title={"You're current position"}
                  >
                    <div
                      style={{
                        width: 60,
                        height: 60,
                        position: "absolute",
                        top: 0,
                        backgroundColor: "#f87e4b52",
                        borderRadius: "50%",
                        transform: "translate(-50%, -50%)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#C43CAB",
                          width: 16,
                          height: 16,
                          borderRadius: "50%",
                          border: "2px solid white",
                        }}
                      ></div>
                    </div>
                  </AdvancedMarker>

                  {nearInstructors?.map((instructor) => {
                    return (
                      <MarkerWithInfoWindow
                        position={{
                          lat: instructor.location.lat,
                          lng: instructor.location.long,
                        }}
                        handleMarkerClick={() => handleMarkerClick(instructor)}
                        selectedItem={selectedInstructor}
                        key={instructor.instructorId}
                        id={instructor.instructorId}
                        handleWindowClose={handleWindowClose}
                      />
                    );
                  })}
                </APIProvider>
              ) : locationDenied ? null : (
                <div className="d-flex align-items-center gap-2">
                  <P_Spinner />
                  <span>Chargement de la carte...</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
