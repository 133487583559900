import React, { useState } from "react";
import "./style.css";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import sent from "../images/sent.svg";
import mage_attachment from "../images/mage_attachment.svg";
import axiosInstance from "../utils/http/config/axios";
import { Dropdown } from "react-bootstrap";

function MessageInput({ socket, userId, receiver  }) {
  console.log(socket);
  const [inputValue, setInputValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const addMessage = async (newMessage) => {
    try {
      const response = await axiosInstance.post(
        "/api/messages/send",
        newMessage
      ); // Replace with your actual API endpoint
    } catch (error) {
      console.error("Error posting message:", error);
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axiosInstance.post("/api/messages/upload", formData);
      return response.data.fileUrl;
    } catch (error) {
      console.error("Error uploading file:", error);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(inputValue);
    console.log(receiver);
    let fileUrl = "";
    if (selectedFile) {
      fileUrl = await handleFileUpload(); // Téléchargement du fichier
    }
    if (inputValue.trim() || fileUrl) {
      const newMessage = {
        content: inputValue,
        receiverId: receiver,
        senderId: parseInt(userId),
        fileUrl,
      };
      // Send the message via REST API
      addMessage(newMessage);

      // Send the message via WebSocket
      socket.emit("sendMessage", newMessage);
      console.log(socket);
      setInputValue("");
      setSelectedFile(null); // Réinitialiser le fichier après l'envoi
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setShowDropdown(false); 
  };

  const handleAttachmentClick = () => {
    setShowDropdown(!showDropdown); 
  };

  // Fonction pour supprimer le fichier sélectionné
  const handleRemoveFile = () => {
    setSelectedFile(null);
    };
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   sendMessage();
  // };
  const sendMessage = () => {
    if (inputValue.trim()) {
      const newMessage = {
        content: inputValue,
        receiverId: receiver,
        senderId: parseInt(userId),
        fileUrl: "",
      };
      // Send the message via REST API
      addMessage(newMessage);

      // Send the message via WebSocket
      socket.emit("sendMessage", newMessage);

      setInputValue("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <div className="message-input">
      <div className="text_input">
        {/* Affichage de la boîte avec le fichier sélectionné */}
        {selectedFile && (
          <div className="file-box">
            <img src={mage_attachment} alt="attachment-icon" className="file-icon" />
            <span>{selectedFile.name}</span>
            <button onClick={handleRemoveFile} className="remove-file-btn">x</button>
          </div>
        )}
        <Form.Control
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyPress}
          placeholder="Type a message..."
        />
        <div className="attchmnts">
           <Link to="#" onClick={handleAttachmentClick}> {/* Écouter le clic pour afficher le dropdown */}
            <img src={mage_attachment} alt="Attachment" />
          </Link>

          {showDropdown && (
            <Dropdown show={showDropdown} drop="up"  align="start" style={{ position: 'absolute', bottom: '38%', right: '15%' }}>
              <Dropdown.Menu>
                <Dropdown.Item as="label">
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    accept=".pdf"
                  />
                  Document
                </Dropdown.Item>
                <Dropdown.Item as="label">
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    accept=".jpg, .jpeg, .png"
                  />
                  Photos
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          <Link to="" onClick={handleSubmit}>
            <img src={sent} alt="send" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MessageInput;