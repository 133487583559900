import { useState, useEffect } from "react";
import Ellipse from "../images/user-placeholder.svg";
import axiosInstance from "../utils/http/config/axios";
import { Link, useNavigate } from "react-router-dom";
import dashboardicon from "../images/dashboard-icon.png";
import "./style.css";
import uiw_enter from "../images/uiw_enter.png";
import clarity_clock_line from "../images/clarity_clock-line.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { capitalize } from "../utils/function";

function Subscribed_user_dashboard() {
  const [userName, setUserName] = useState("");
  const [nextLesson, setNextLesson] = useState(null);
  const [hoursRemaining, setHoursRemaining] = useState(0);
  const [assignedInstructor, setAssignedInstructor] = useState(null);
  const [lastPayment, setLastPayment] = useState(null);
  const [totalHoursUsed, setTotalHoursUsed] = useState(0);
  const [joinDate, setJoinDate] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const storedDate = localStorage.getItem("createdAt");

    if (storedDate) {
      const [day, month, year] = storedDate.split("/");
      const dateObj = new Date(`${year}-${month}-${day}`);

      // Format the date in French using Intl.DateTimeFormat
      const formattedDate = new Intl.DateTimeFormat("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(dateObj);

      setJoinDate(formattedDate);
    } else {
      setJoinDate("No date available");
    }
  }, []);

  useEffect(() => {
    // Récupérer les informations utilisateur de localStorage
    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");

    if (firstName && lastName) {
      setUserName(`${firstName} ${lastName}`);
    }

    // Fonction pour récupérer les données de l'étudiant
    const fetchStudentData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token non trouvé");
        }

        // Récupérer les données du tableau de bord pour les leçons
        const dashboardResponse = await axiosInstance.get(
          "/api/students/dashboard",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const {
          nextLesson: upcomingLesson = null,
          assignedInstructor,
          totalHoursUsed,
          hoursRemaining,
          lastPayment,
        } = dashboardResponse.data.data || {};

        setNextLesson(upcomingLesson);
        setAssignedInstructor(assignedInstructor);
        setHoursRemaining(hoursRemaining);
        setTotalHoursUsed(totalHoursUsed);
        setLastPayment(lastPayment);
      } catch (err) {
        toast.error(
          "Erreur lors de la récupération des données des leçons ou du profil."
        );
      }
    };

    fetchStudentData();
  }, []);

  const handleInstructorClick = (instructorId) => {
    navigate(`/instructorprofile/${instructorId}`);
  };

  const status = "En attente";

  return (
    <section className="subcribed">
      <ToastContainer />
      <div className="container-fluid">
        <div className="mt-lg-5">
          <div className="row dashboard-wrap">
            <div className="col-lg-8">
              <div className="content-wrap">
                <h2>
                  Bienvenue! <br></br> <span>{userName}</span>
                </h2>
                <div className="joining-date-student my-3">
                  <span>
                    <strong>Date d'adhésion: </strong>
                    {joinDate}
                  </span>
                </div>
                <p>Commencez à apprendre aujourd'hui</p>
              </div>
            </div>
            <div className="col-lg-4 car-img-card">
              <img src={dashboardicon} alt="Icône du tableau de bord" />
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-8">
            <div className="total-hrs">
              <div className="driving-sessions">
                <div>
                  <Link to="/dashboard">
                    <button
                      type="button"
                      class="btn btn-secondary btn-main mb-3"
                    >
                      Nouvelle réservation
                    </button>
                  </Link>
                  <div>
                    <span>Vos séances de conduite</span>
                    {/* <hr></hr> */}
                    <h5 className="mt-3 my-3">Heures de conduite</h5>
                  </div>
                </div>

                <div>
                  <p className="assign-inst">Enseignants consigne</p>
                  {assignedInstructor ? (
                    <div className="d-flex inst-img">
                      <img
                        src={
                          assignedInstructor?.profilePicture
                            ? `/${assignedInstructor?.profilePicture}`
                            : Ellipse
                        }
                        alt=""
                        className="assigned-instructor-img"
                      />
                      <strong
                        className="clickable"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleInstructorClick(assignedInstructor.id)
                        }
                      >
                        {`${
                          assignedInstructor?.firstName || "Enseignant inconnu"
                        } ${assignedInstructor?.lastName || ""}`}
                      </strong>
                    </div>
                  ) : (
                    <p className="mb-0">Aucun Enseignants assigné.</p>
                  )}
                  <div className="hrs mt-3">
                    <h2>{hoursRemaining} Heures</h2>
                    <p>restantes</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="credits ense">
              <strong>Forfait abonné</strong>
              <h3>{lastPayment?.subscription}</h3>
              <div
                className={`${lastPayment?.paymentStatus === "réussi" && "success"} ${
                  (lastPayment?.paymentStatus === "rembourse" || lastPayment?.paymentStatus === "annule") && "fail"
                } ${lastPayment?.paymentStatus === "en attente" && "pending"} status`}
              >
                <span>Mode de paiement : Par Carte</span>

                <div>
                
                  {capitalize(lastPayment?.paymentType)}
                  <span  className="status-label">{capitalize(lastPayment?.paymentStatus)}</span>
                </div>
              </div>
            </div>
            {/* <div className='chat-progress'>
                            <div className='d-flex justify-content-between'>
                                <h3>Votre progression</h3>
                                <button type="button" className="btn btn-dark">Voir le rapport</button>
                            </div>
                            <Progress_chart />
                        </div> */}
          </div>
        </div>

        <div className="row mt-4 my-5">
          <div className="col-lg-8">
            {nextLesson && (
              <div className="comign-lesson">
                <h4>Leçon à venir</h4>
                <div className="current">
                  <img src={uiw_enter} alt="Icône de leçon" />
                  <p>
                    Leçon <span>{nextLesson.id}</span>
                  </p>
                  <div className="inst">
                    <img
                      src={
                        assignedInstructor?.profilePicture
                          ? `/${assignedInstructor?.profilePicture}`
                          : Ellipse
                      }
                      alt=""
                      className="assigned-instructor-img"
                    />
                    <div className="ps-2">
                      <strong
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleInstructorClick(assignedInstructor.id)
                        }
                      >{`${nextLesson.timeslot.instructor?.firstName} ${nextLesson.timeslot.instructor?.lastName}`}</strong>
                      <br />
                      <span>Enseignants</span>
                    </div>
                  </div>
                  <div className="clock">
                    <img src={clarity_clock_line} alt="Icône Horloge" />
                    <strong>
                      {parseInt(nextLesson.timeslot.startTime.split(":")[0])}H
                    </strong>
                  </div>
                  <div className="duration">
                    <p>
                      Durée:{" "}
                      <span>{nextLesson.duration.toFixed(0)} Heures</span>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="col-lg-4 utilize-card">
            <div className="credits utilize ">
              <strong>Crédit utilisé</strong>
              <h3>{totalHoursUsed} heures</h3>
              <span>crédit déjà utilisé</span>
            </div>
          </div>

          {/* <div className='yesterday-consume-hrs'>
                    <h3>Heures de conduite consommées hier</h3>
                    <div className='consume_hrs'>
                        <strong>{hoursConsumedYesterday.toFixed(2)} Heures</strong>
                    </div>
                </div> */}
        </div>
      </div>
    </section>
  );
}

export default Subscribed_user_dashboard;
