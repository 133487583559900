import React, { useState, useRef } from 'react';
import { BsRecordCircle } from 'react-icons/bs';
import { MdCallEnd, MdZoomOutMap } from 'react-icons/md';
import { SiAudiomack } from "react-icons/si";
import { AiFillAudio, AiFillMessage, AiOutlineAudioMuted } from 'react-icons/ai';
import { IoCall } from 'react-icons/io5';
import PropTypes from 'prop-types';
import './AudioCall.css';

export const IconButton = React.memo(({ icon, label, bgColor, onClick, ...props }) => {
  const [showDescription, setShowDescription] = useState(false);
  const buttonRef = useRef(null);

  return (
    <div 
      className='iconButtonContainer'
      style={{
        transform: 'translateZ(0)',
        willChange: 'transform',
        backfaceVisibility: 'hidden'
      }}
    >
      <button
        ref={buttonRef}
        className='iconButton'
        style={{ 
          backgroundColor: bgColor,
          transform: 'translateZ(0)',
          willChange: 'transform',
          backfaceVisibility: 'hidden'
        }}
        onClick={onClick}
        onMouseEnter={() => setShowDescription(true)}
        onMouseLeave={() => setShowDescription(false)}
        {...props}
      >
        {icon}
      </button>
      {showDescription && (
        <div 
          className='iconDescription'
          style={{
            transform: 'translateZ(0)',
            willChange: 'transform',
            backfaceVisibility: 'hidden',
            position: 'absolute',
            top: '-40px',
            left: '50%',
            transform: 'translateX(-50%)',
            whiteSpace: 'nowrap',
            backgroundColor: 'rgba(0,0,0,0.8)',
            color: 'white',
            padding: '4px 8px',
            borderRadius: '4px',
            fontSize: '12px',
            pointerEvents: 'none',
            zIndex: 1000
          }}
        >
          {label}
        </div>
      )}
    </div>
  );
});

IconButton.displayName = 'IconButton';

IconButton.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  onClick: PropTypes.func,
};

function AudioCall({
  setPopupText,
  setShowPopup,
  callText,
  setCallText,
  setCallStatus,
  callStatus,
  time,
  setTime,
  localStream,
  remoteStream,
  endCall
}) {
  const [isUserMicMuted, setIsUserMicMuted] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e) => {
    if (callStatus === 'inSmallView') {
      setIsDragging(true);
      setOffset({
        x: e.clientX - position.x,
        y: e.clientY - position.y
      });
    }
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    setPosition({
      x: e.clientX - offset.x,
      y: e.clientY - offset.y
    });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div
      className={`fullViewCallMode ${callStatus === 'inSmallView' ? 'inSmallView' : ''}`}
      style={{
        transform: callStatus === 'inSmallView' ? `translate(${position.x}px, ${position.y}px)` : 'none',
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <header>
        <div className="headerOptions">
          <IconButton icon={<BsRecordCircle/>} label={'Enregistrer l\'appel'}/>
          {callStatus === 'voiceCall' && (
            <IconButton
              onClick={() => {
                console.log('[CLIENT] Switch to inSmallView for audio call');
                setCallStatus('inSmallView');
              }}
              icon={<MdZoomOutMap/>}
              label={'Quitter le mode plein écran'}
            />
          )}
          {callStatus === 'inSmallView' && (
            <IconButton
              onClick={() => {
                console.log('[CLIENT] Switch to voiceCall (back to full screen)');
                setCallStatus('voiceCall');
              }}
              icon={<MdZoomOutMap/>}
              label={'Revenir sur le mode plein écran'}
            />
          )}
        </div>
      </header>

      <main className="audioChatMain">
        <div className="callState">{callText}</div>
        <div className="callUsers">
          <div className="speakingUser">
            <div className="audioContainers">
              {/* local audio */}
              <div className="localAudio">
                <audio
                  ref={(audio) => {
                    if (audio && localStream) {
                      console.log('[CLIENT] Setting local audio srcObject');
                      audio.srcObject = localStream;
                      audio.play().catch(e => console.error('Error playing local audio:', e));
                    }
                  }}
                  muted
                />
                <span>Vous</span>
              </div>
              {/* remote audio */}
              <div className="remoteAudio">
                <audio
                  ref={(audio) => {
                    if (audio && remoteStream) {
                      console.log('[CLIENT] Setting remote audio srcObject');
                      audio.srcObject = remoteStream;
                      audio.play().catch(e => console.error('Error playing remote audio:', e));
                    }
                  }}
                  autoPlay
                />
                <span>Peer</span>
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer className='audioChatFooter'>
        { isUserMicMuted ? (
          <IconButton
            icon={<AiOutlineAudioMuted/>}
            label={'Activer le micro'}
            onClick={() => {
              console.log('[CLIENT] Unmuting mic');
              setIsUserMicMuted(false);
            }}
          />
        ) : (
          <IconButton
            icon={<AiFillAudio/>}
            label={'Désactiver le micro'}
            onClick={() => {
              console.log('[CLIENT] Muting mic');
              setIsUserMicMuted(true);
            }}
          />
        )}
        <IconButton
          icon={<IoCall/>}
          label={'Passer en appel vocal'}
          bgColor={'#c43cab'}
          onClick={() => {
            console.log('[CLIENT] Request to switch to audio call from audio call?');
            setPopupText('Peer demande à passer en appel vocal');
            setShowPopup(true);
          }}
        />
        <IconButton
          icon={<AiFillMessage/>}
          label={'Envoyer un message'}
          onClick={() => {
            console.log('[CLIENT] Switching to inMessageMode');
            setCallStatus('inMessageMode');
          }}
        />
        <IconButton
          icon={<MdCallEnd/>}
          label={'Terminer l\'appel'}
          bgColor={'#E72C79'}
          onClick={() => {
            console.log('[CLIENT] Terminating call...');
            endCall();
          }}
        />
      </footer>
    </div>
  );
}

AudioCall.propTypes = {
  setPopupText: PropTypes.func.isRequired,
  setShowPopup: PropTypes.func.isRequired,
  callText: PropTypes.string.isRequired,
  setCallText: PropTypes.func.isRequired,
  setCallStatus: PropTypes.func.isRequired,
  callStatus: PropTypes.string.isRequired,
  time: PropTypes.object.isRequired,
  setTime: PropTypes.func.isRequired,
  localStream: PropTypes.object,
  remoteStream: PropTypes.object,
  endCall: PropTypes.func.isRequired,
};

export default AudioCall;
