import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import checktick from "../images/check-tick.svg";
import axiosInstance from "../utils/http/config/axios";
import "./style.css";
import Loading from "../components/Loading";

function Boutique() {
  const navigate = useNavigate();
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get("/api/students/subscriptions");
        if (Array.isArray(response.data)) {
          setSubscriptions(
            response.data.map((sub) => ({
              ...sub,
              descriptions: (sub.descriptions || []).sort(
                (a, b) => a.id - b.id
              ),
            }))
          );
        } else if (response.data && Array.isArray(response.data.data)) {
          setSubscriptions(
            response.data.data.map((sub) => ({
              ...sub,
              descriptions: (sub.descriptions || []).sort(
                (a, b) => a.id - b.id
              ),
            }))
          );
        } else {
          setError(
            "Oups, on dirait qu'il y a un souci avec les informations. Veuillez réessayer plus tard."
          );
        }
      } catch (error) {
        setError(
          "Oups, une erreur est survenue. Nous n'avons pas pu récupérer vos abonnements. Essayez à nouveau dans un moment."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptions();
  }, []);

  const handleBuy = (planDetails) => {
    navigate("/cart", {
      state: { planDetails, descriptions: planDetails.descriptions },
    });
  };

  const sortSubscriptionsByPrice = (subscriptions) =>
    subscriptions.sort((a, b) => a.price - b.price);

  const sortedSubscriptions = sortSubscriptionsByPrice(subscriptions);

  const classiqueSubscriptions = sortedSubscriptions
    .filter((sub) => sub.type === "classique")
    .map((sub) => ({
      ...sub,
      popular: sub.is_popular,
    }));

  const accelereesSubscriptions = sortedSubscriptions
    .filter((sub) => sub.type === "accelerees")
    .map((sub) => ({
      ...sub,
      popular: sub.is_popular,
    }));

  const renderSubscriptionRows = (subscriptions) => {
    const rows = [];
    for (let i = 0; i < subscriptions.length; i += 3) {
      const rowItems = subscriptions.slice(i, i + 3);
      rows.push(
        <div key={i} className="cards-row-wrapper">
          {rowItems.map((subscription) => (
            <SubscriptionCard
              key={subscription.id}
              subscription={subscription}
              handleBuy={handleBuy}
            />
          ))}
        </div>
      );
    }
    return rows;
  };

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <section className="shop">
      <div className="container">
        <div className="row">
          <Tabs
            defaultActiveKey="classique"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="classique" title="Formules Classiques">
              <div className="shop-cards-container">
                {renderSubscriptionRows(classiqueSubscriptions)}
              </div>
            </Tab>
            {/*
            <Tab eventKey="accelerees" title="Formules Accélérées">
              <div className="tab-cover">
                <h2>Formules Accélérées</h2>
                <p>
                  Nos formules intensives sont idéales pour ceux qui souhaitent
                  obtenir leur permis de conduire rapidement tout en bénéficiant
                  d'une formation complète et approfondie.
                </p>
              </div>
              <div className="shop-cards-container">
                {renderSubscriptionRows(accelereesSubscriptions)}
              </div>
            </Tab>
            */}
          </Tabs>
        </div>
      </div>
    </section>
  );
}

const SubscriptionCard = ({ subscription, handleBuy }) => (
  <div
    className={`plan-first ${subscription.popular ? "populr" : ""} ${
      subscription.is_measure ? "measure" : ""
    }`}
  >
    {subscription.popular && (
      <div className="popular">
        <span>Le plus populaire</span>
      </div>
    )}
    {subscription.is_measure && (
      <div className="popular">
        <span>Sur-Mesure</span>
      </div>
    )}
    <h2>{parseInt(subscription.price)} €</h2>
    <p>{subscription.name}</p>
    <ul>
      {subscription.descriptions?.map((desc, index) => (
        <li key={index}>
          <span>
            <img src={checktick} alt="tick" />
          </span>
          {desc}
        </li>
      ))}
    </ul>
    {subscription.is_multiple_payment && (
      <div className="payment-section">
        <span>€</span>
        <span>paiement en plusieurs fois</span>
      </div>
    )}
    <div className="text-center">
      <button
        type="button"
        className="btn btn-secondary btn-main acheter"
        onClick={() =>
          handleBuy({
            id: subscription.id,
            name: subscription.name,
            price: subscription.price,
            descriptions: subscription.descriptions,
            hoursIncluded: subscription.hoursIncluded,
            is_multiple_payment: subscription.is_multiple_payment || false,
          })
        }
      >
        Acheter
      </button>
    </div>
  </div>
);

export default Boutique;
